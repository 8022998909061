.main-article {
	position: relative;
	overflow: hidden;
	@include rem(padding, $medium-padding 0 0);
	@include rem(margin, 0);

	.text-module {
		margin: 2.5rem auto;
	}

	&__navigation {
		display: none;
		/*display:none;
		// width: calc(100% - 28px);
		left:0;
		right:0;
		margin:auto;
		position:absolute;
		z-index:index($elements, article-navigation);
		@include rem(top, $large-margin);
		@include breakpoint(desktop-big) {
			@include rem(top, 40px);
			display:block;
			z-index: 0;
		}

		.container--full-width {
			max-width: none;
		}
		
	    @include breakpoint(tablet){
	    	@include rem(max-width, 1228px);
	    }
	    @include breakpoint(desktop){
	    	@include rem(max-width, 1328px);
		}*/
		@include breakpoint(desktop-big) {
			display: block;
		
			&--container {

				.main-article__navigation {
					&--left {
						position: absolute;
						top: 20px;
						left: -39px;
						z-index: 1;
						text-align: right;
						direction: rtl;
					}
					&--right {
						position: absolute;
						top: 20px;
						right: -39px;
						z-index: 1;
					}
				}

			}
			&--full-container {

				.main-article__navigation {
					&--left {
						position: absolute;
						top: 20px;
						left: -115px;
						z-index: 1;
						text-align: right;
						direction: rtl;
					}
					&--right {
						position: absolute;
						top: 20px;
						right: -89px;
						z-index: 1;
					}
				}

			}
			&--container,
			&--full-container {
				.main-article__navigation {
					&--left,
					&--right {
						a.go-back,
						a.go-forward {
							word-wrap: normal;
						}
					}
				}
			}
		}
	}
	&__header {
		
		&__content {
			border-bottom: solid $border-thin $light-grey;
			@include breakpoint(desktop) {
				@include rem(padding, 0 0 $medium-padding);
			}
		}
		@include breakpoint(tablet) {
			@include rem(margin-bottom, $medium-margin);
		}
	}
	&__intro,
	&__title {
		@include fontSize(mediumTitle);
	}
	&__title {
		@include rem(margin, 0 0 $small-margin);
	}
	&__modules{
		@include rem(padding, 0);
	}
	&__footer{
		position: relative;
		@include clearfix();
		@include rem(margin, $large-margin 0);

		nav {
			display: block;
			position: static;
			width: calc(100% - 28px);
			margin:auto;
			@include breakpoint(desktop-big) {
				display:none;
			}
		}
	}
	&__sidebar {
		@extend .container;
		margin:auto;
		@include breakpoint(desktop) {
			width:100%;
		}
	}

	// Grid modifier
	.eight--columns {
	    @extend .grid__cell;
	    @include breakpoint(desktop) {
	        @extend .unit-8-12--desktop;
		}
		.eight--columns {
			@include breakpoint(tablet) {
				@extend .unit-11-12--tablet;
				display: block;
				margin: 0 auto;
			}
		}
		.two--columns {
			display: none!important;
		}
	}
	.three--columns {
	    @extend .grid__cell;
	    @include breakpoint(desktop) {
	        @extend .unit-3-12--desktop;
	    }
	}

	.text-module + .blockquote-module {
		@include rem(margin-top, 0);
		@include rem(margin-bottom, 33px);
	}

}
