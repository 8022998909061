.order-taxi {
    display: none;

    .form {
        @include rem(margin-top, $medium-margin);

        .six--columns {
            @extend .grid__cell;
            @include breakpoint(desktop) {
                @extend .unit-6-12--desktop;
            }
        }
        &__input-wrapper {
            @include rem(margin-bottom, $medium-margin);
        }

        &__submit-input {
            @include rem(margin-top, $medium-margin);
        }
    }
}