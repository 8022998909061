.module--video-module {
  .video-module {
    position: relative;
    margin: 0 auto 20px auto;
    cursor: pointer;
    overflow: hidden;

    &__content {
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;

      h3 {
        @include rem(margin-top, $medium-margin);
      }

      &:hover {
        color: $white;
      }
    }

    &__play {
      &:before {
        @extend .icon-play-2--default:before;
        font-size: 4rem;

        @include breakpoint(tablet) {
          font-size: 7rem;
        }
      }
    }

    &__header {
      display: none;
      @include breakpoint(tablet) {
        display: block;
      }
    }

    &__container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      z-index: 2;
    }

    &__iframe {
      position: relative;
      margin: 0;
      z-index: 1;
      width: 100%;
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.open {
      .video-module__container {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .6s .8s;
        transition: all .6s .8s;
      }

      .video-module__iframe {
        opacity: 1;
        -webkit-transition: all .6s .8s;
        transition: all .6s .8s;
      }
    }
  }
}
