// =============================================================================
// Griddle functions
// =============================================================================

// Find the greatest common factor of two integers

@function gcf($a, $b) {
    @if $b == 0 { @return $a; }
    @else { @return gcf($b, $a % $b); }
}

// Check if a list contains a value

@function contains($list, $value) {
    @if type-of($list) == list { @return not not index($list, $value); }
    @else { @return $list == $value; }
}

// Fluid grid units

// USAGE: provide a space-separated list of integers, each of which represents
// the number of parts that make up a grid component. Optionally provide a
// modifier suffix that can be used to adjust grids in different contexts (e.g.
// viewport dimensions).

@mixin griddle-build($units, $modifier: '') {

    /* Proportional units
       ========================================================================== */

    /*
     * Specify the proportional width of an object.
     * Primarily for, but not limited to, use with `.grid__cell` components.
     * Intentional redundancy build into each set of unit classes.
     */

    @each $n in $units {
        // Avoid creating rules like `.unit-12-12 {}`
        $x: $n - 1;

        @for $i from 1 through $x {
            // Initialize variables
            $i-r: 0;
            $n-r: 0;

            // Find the greatest common factor
            $g: gcf($i, $n);

            @if $g > 1 {
                // Reduced value of $i
                $i-r: $i/$g;
                // Reduced value of $n
                $n-r: $n/$g;
            }

            // Check if the reduced value of $n was also supplied in the list
            // of units to be built
            $canreduce: contains($units, $n-r);

            // Create units based on fractions
            .unit-#{$i}-#{$n}#{$modifier} {
                // If this unit can be reduced then extend the previous rule
                @if $canreduce {
                    @extend .unit-#{$i-r}-#{$n-r}#{$modifier};
                }
                // Otherwise create a new % width
                @else {
                    width: percentage($i / $n);
                }
            }
        }
    }
}

