.sidebar-flaps {
	
	// // Grid modifier
	.three--columns {
	    @include breakpoint(tablet) {
	        @extend .unit-6-12--tablet;
	    }
	    @include breakpoint(desktop) {
	        width:100%
	    }

	}

}