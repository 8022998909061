/*!
 * jQuery Cookiebar Plugin
 * https://github.com/carlwoodhouse/jquery.cookieBar
 *
 * Copyright 2012, Carl Woodhouse
 * Disclaimer: if you still get fined for not complying with the eu cookielaw, it's not our fault.
 */
 
.cookie-message {
  display: none;
  position:fixed;
  top:0;left:0;right:0;
  padding: 3rem 4rem;
  background:#000;
  color: #fff;
  border-bottom:1px solid #ccc;
  box-shadow:0px 0px 3px #ccc;
  z-index: 99;
  font-smooth: auto;
  -webkit-font-smoothing: auto;
}
.cookie-message .button {
  position:absolute;
  top:10px; *top:-2px; right:85px;
  padding-bottom: 10px;
  cursor:pointer;
}
.cookie-message p, .cookie-message a {
  color:#fff;
  margin:0.6em 0;
}
.cookie-message a {
  padding-bottom: 3px;
  border-bottom: 1px solid #fff;
}
.cookie-message .my-close-button {
  position: absolute;
  top: 10px;
  right: 40px;
  border-bottom: 0;
}
.cookie-message .button--close:after {
	display:inline-block;
	transform: rotate(-90deg);
}
.blue {
  visibility: hidden;
}
.blue p, .blue a { color:#fff; }