.image-module {

	@include rem(margin, $x-large-margin 0);

	&__image {
		&--portrait {
			margin: 0 auto;
		}
	}

	&__caption {
		@include rem(margin-top, .5px);
		display: block;
		text-align:right;
	}

}