.teasers-section {

	&--shop {
		.owl-carousel {
			.item {
				width: 100%;

				@include breakpoint(desktop-big) {
					&:hover {
						.teaser__image {
							@include opacity(0);
			
							&--focused {
								@include opacity(1);
							}
						}			
					}
				}
			}

			.teaser__image {
				display: none;
				width:auto;
				height:100%;
				@include opacity(0);

				&--focused {
					@include transition(opacity .1s ease);
					@include opacity(1);
					width:100%;
					height:auto;
					display:block;

					@include breakpoint(desktop-big) {
						@include opacity(0);
					}
				}

				@include breakpoint(desktop-big) {
					display: block;
					position: absolute;
					right:0;
					top:0;
					z-index: -1;
					@include opacity(1);
				}
			}
		}

		.flap {
			@include breakpoint(desktop) {
				@include rem(margin-bottom, 10px);
			}

			h4 {
				@include rem(font-size, 20px);
				@include rem(margin-bottom, 10px);
			}
		}
	}

	// Grid modifier
	.three--columns {
	    @include breakpoint(phablet) {
	        @extend .unit-6-12--phablet;
	    }
	    @include breakpoint(tablet) {
	        @extend .unit-6-12--tablet;
	    }
	    @include breakpoint(desktop) {
	        @extend .unit-3-12--desktop;
	    }
	}

}