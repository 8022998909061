.cookie-message {
    z-index: 9999;
    .my-close-button {
        right: 20px;
        &:before {
            @extend .icon-close-2--default:before;
            display:inline-block;
            @include rem(font-size, 25px);
            @include rem(margin-left, $tiny-margin);
        }

        @include breakpoint(desktop-big) {
            right: 40px;
        }
    }

    .button {
        position: initial;
        top: 25px;
        float: right;
        width: 100%;

        @include breakpoint(tablet) {
            position: absolute;
            float: none;
            width: auto;
        }
        
        @include breakpoint(tablet) {
            right: 65px;
        }
        @include breakpoint(desktop) {
            top: 10px;
            right: 85px;
        }
    }

    .my-close-button {
        top: 10px;
        right: 15px;
        
        @include breakpoint(tablet) {
            top: 25px;
            right: 20px;
        }
        @include breakpoint(desktop) {
            top: 10px;
            right: 40px;
        }
    }
}