/**
 * @component
 * @name Pagination
 */

//@import "imports/utils.less";

/**
 * @class
 * @name .fs-pagination-element
 * @type element
 * @description Target elmement
 */

/**
 * @class
 * @name .fs-pagination
 * @type element
 * @description Base widget class
 */

/**
 * @class
 * @name .fs-pagination.fs-pagination-mobile
 * @type modifier
 * @description Indicates mobile display
 */

.fs-pagination {

	/**
	 * @class
	 * @name .fs-pagination
	 * @type element
	 * @description Pages container
	 */

	&-pages {
	}

	/**
	 * @class
	 * @name .fs-pagination-page
	 * @type element
	 * @description Page element
	 */

	/**
	 * @class
	 * @name .fs-pagination-ellipsis
	 * @type element
	 * @description Ellipsis element
	 */

	&-page,
	&-ellipsis {
		width: 1px;
		height: 1px;

		position: absolute;

		border: 0;
		clip: rect(0 0 0 0);
		display: inline-block;
		margin: -1px;
		overflow: hidden;
		padding: 0;
	}

	/**
	 * @class
	 * @name .fs-pagination-page.fs-pagination-active
	 * @type modifier
	 * @description Indicates active page
	 */

	/**
	 * @class
	 * @name .fs-pagination-page.fs-pagination-first
	 * @type modifier
	 * @description Indicates first page
	 */

	/**
	 * @class
	 * @name .fs-pagination-page.fs-pagination-last
	 * @type modifier
	 * @description Indicates last page
	 */

	/**
	 * @class
	 * @name .fs-pagination-page.fs-pagination-visible
	 * @type modifier
	 * @description Indicates visible page
	 */

	&-active,
	&-first,
	&-last,
	&-visible {
		width: auto;
		height: auto;

		position: static;

		clip: none;
		margin: 0;
		overflow: visible;
	}

	/**
	 * @class
	 * @name .fs-pagination-control
	 * @type element
	 * @description Pagination button
	 */

	/**
	 * @class
	 * @name .fs-pagination-control.fs-pagination-control_previous
	 * @type modifier
	 * @description Indicates previous control
	 */

	/**
	 * @class
	 * @name .fs-pagination-control.fs-pagination-control_next
	 * @type modifier
	 * @description Indicates next control
	 */

	&-control {
		//.fs_button;

		&_previous {
		}

		&_next {
		}
	}

	/**
	 * @class
	 * @name .fs-pagination-position
	 * @type element
	 * @description Mobile position indicator
	 */

	/**
	 * @class
	 * @name .fs-pagination-select
	 * @type element
	 * @description Mobile page dropdown
	 */

	/**
	 * @class
	 * @name .fs-pagination-current
	 * @type element
	 * @description Current page mobile display
	 */

	/**
	 * @class
	 * @name .fs-pagination-total
	 * @type element
	 * @description Total pages mobile display
	 */

	&-position,
	&-select {
		display: none;
	}

	&-mobile &-pages {
		width: 1px;
		height: 1px;

		position: absolute;

		border: 0;
		clip: rect(0 0 0 0);
		display: inline-block;
		margin: -1px;
		overflow: hidden;
		padding: 0;
	}

	&-mobile &-position {
		position: relative;

		display: block;
	}

	&-mobile &-select {
		height: 100%;
		width: 100%;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		display: block;
		margin: auto;
		opacity: 0;
	}

	&-mobile &-current {
	}

	&-mobile &-total {
	}

	//USM
	text-align:center;
	border-top: 1px solid $light-grey;
	border-bottom: 1px solid $light-grey;
	@include rem(margin, $medium-margin 0);
	@include rem(padding, $medium-padding 0);
}

.fs-pagination-control {
	position: absolute;
	border:0;
	background: transparent;

	&_previous {
		left:0;
	}
	&_next {
		right:0;
	}

}

