.shipping-details {
    background-color: $breadcrumbs;
    @include fontSize(paragraph);
    line-height: 1.3;
    @include rem(margin, 15px 0 25px 0);
    @include rem(padding, 25px);

    @include breakpoint(tablet) {
        @include rem(margin, 10px 0 25px 0);
        @include rem(padding, 16px 20px);
    }

    &__address {
        font-style: normal;
    }
    &__wrapper {
        position: relative;
        @include rem(padding-bottom, 10px);

        @include breakpoint(tablet) {
            @include rem(padding-bottom, 20px);
        }

        .six--columns {
            width: 50%;
        }

        a {
            &:hover {
                color: $light-grey;
            }
        }
    }
    &__delivery {
        @include rem(padding-top, 14px);

        .six--columns {
            width: 50%;
        }
    }
}