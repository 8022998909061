.map {
  @include breakpoint(desktop) {
    @include rem(margin-bottom, 30px);
  }

  div:empty {
    display: none;

    @include breakpoint(desktop-big) {
      display: inline-block;
    }
  }

  .container {
    @include rem(padding-top, 15px);
  }

  #map {
    height: 650px !important;

    div:empty {
      display: block;
    }

    @include breakpoint(tablet) {
      height: 500px !important;
    }
  }

  .three--columns {
    @include breakpoint(tablet) {
      @extend .unit-3-12--tablet;
    }
  }

  .gm-style {

    h3 {
      @include rem(margin, 0 0 $small-margin);
    }

    .gm-small-container {
      @include rem(bottom, 55px !important);
      max-width: 300px !important;
      right: auto !important;

      /*.gm-style-iw {
          max-width: 260px !important;
      }*/

      #iw-container {
        width: 100%;
      }
    }

    &-iw {
      left: 0px !important;

      .gm-style-iw {
        background-color: $white;
        box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
        border: 1px solid rgba(72, 181, 233, 0.6);
        border-radius: 2px 2px 10px 10px;
        font-size: 16px;

        + div {
          display: none;
        }
      }
    }

    #iw-container {
      width: 375px;
    }

    #iw-container .iw-title {
      font-family: 'Open Sans Condensed', sans-serif;
      font-size: 22px;
      font-weight: 400;
      padding: 17px 0 0;
      background-color: $white;
      color: $black;
      margin: 0;
      border-radius: 0;
    }

    #iw-container .iw-content {
      background-color: $white;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      padding: 8px 0 20px;
      overflow-y: auto;
      overflow-x: hidden;

      p {
        @include rem(margin, 4px 0 0);
      }
    }

    .iw-content img {
      //float: right;
      //margin: 0 5px 5px 10px;
    }

    .iw-subTitle {
      font-size: 16px;
      font-weight: 700;
      padding: 5px 0;
    }

    .gm-style {
      &-iw-c {
        border-radius: 0;
        box-shadow: none;
        @include rem(padding, 5px 20px);

        &:before {
          content: none !important;
        }

        button {
          display: none !important;
        }
      }

      &-iw-d {
        @include rem(padding-right, 10px);
      }
    }
  }

  &__icons {
    .six--columns {
      width: auto;

      @include breakpoint(tablet) {
        width: 50%;
      }
    }
  }

  &__filters {
    display: none;

    @include breakpoint(tablet) {
      display: inline;
      display: initial;
      width: 50%;
    }

    .five--columns {
      @include breakpoint(tablet) {
        @extend .unit-8-12--tablet;
      }

      @include breakpoint(desktop) {
        @extend .unit-8-12--desktop;
      }

      @include breakpoint(desktop-big) {
        @extend .unit-5-12--desktop-big;
      }
    }

    .filter {
      @include list-reset;
      float: right;

      &__helper {
        @include rem(margin, 0 $small-margin 0 0);
        float: left;
      }

      &__item {
        width: auto;
        float: left;

        .fs-dropdown-selected {
          color: $orange;
          border-bottom: 1px solid $orange;
          @include rem(margin, 0);

          &:before {
            color: $orange;
          }
        }

        .fs-dropdown-item {
          color: $orange;
        }
      }
    }
  }

  &__dropdown {
    display: inline;

    .one--column {
      display: none;

      @include breakpoint(tablet) {
        display: inline-block;
      }
    }
  }

  &__search {
    &--mobile {
      position: absolute;
      top: 4px;
      right: 0;
      height: 40px;
      width: 40px;
      @include breakpoint(tablet) {
        display: none;
      }
    }
  }

  &.section--bordered-top {
    .container {
      @include rem(padding-top, 0);
      border-top: none;

      @include breakpoint(tablet) {
        @include rem(padding-top, 15px);
        border-top: 1px solid $light-grey;
      }
    }
  }

  &.section--bordered-bottom {
    .container {
      @include rem(padding-bottom, $medium-padding);
    }
  }

  i {
    display: inline-block;
    float: left;

    &.showroom:before,
    &.dealer:before {
      @extend .icon-map-pin--default;
      @include rem(margin-right, 0);
      display: inline-block;

      @include breakpoint(tablet) {
        @include rem(font-size, 35px);
      }

      @include breakpoint(desktop) {
        @include rem(margin-right, $tiny-margin);
      }
    }

    &.showroom:before {
      color: $green;
    }

    &.dealer:before {
      color: $orange;
    }

    ~ p {
      @include rem(margin, 5px 0);
      display: inline;

      @include breakpoint(tablet) {
        display: block;
      }
    }
  }

  .form {
    &__container {
      @include rem(margin-bottom, 25px);

      @include breakpoint(tablet) {
        white-space: nowrap;
        margin-bottom: 0;
      }
    }

    &__input-wrapper {
      @include rem(margin-top, 0);
      position: relative;
      display: block;

      @include breakpoint(tablet) {
        @include rem(margin-top, 9px);
        display: table-cell;
        width: 100%;
        @include rem(padding-right, 14px);
        @include rem(padding-top, 1.5rem);
      }

      span:after {
        @extend .icon-search--default:before;
        @include rem(font-size, 15px !important);
        position: absolute;
        top: 11px;
        right: 0;
        color: $orange;

        @include breakpoint(tablet) {
          display: none;
        }
      }
    }

    &__text-input {
      @include breakpoint(tablet) {
        padding: 0 0 1.3rem 0;
        @include rem(height, 41px);
      }
    }
  }

  ~ section {
    &.first {
      @include rem(padding-top, $small-padding);
    }

    &.last {
      @include rem(padding-bottom, $small-padding);
    }
  }

  span.label {
    @include rem(margin, $medium-margin 0);
    display: block;
    text-align: center;

    font: {
      size: 18px;
      weight: 500;
    }
  }

  #map-search {
    display: none;

    @include breakpoint(tablet) {
      display: table-cell;
    }

    span {
      display: none;

      @include breakpoint(desktop-big) {
        display: inline-block;
      }
    }

    &:after {
      padding-left: 0;

      @include breakpoint(desktop-big) {
        @include rem(padding-left, 15px);
      }
    }
  }
}

#dealers-list {
  display: flex;
  flex-wrap: wrap;
}

.section-map {
  &--showroom {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 1.5rem;
    @include rem(padding, 0);

    a {
      color: $white;
    }

    .showroom--negative-margin {
      margin: 0 -28px;
      @include rem(padding, $small-padding 20px);

      @include breakpoint(tablet) {
        @include rem(padding, 40px 20px 30px);
      }
      @media screen and (min-width: 1158px) {
        @include rem(padding, 40px 0 30px);
        margin: 0 calc(((100vw - 113rem) / 2) * -1);
      }
      @include breakpoint(desktop-big-content) {
      }


      background-color: $green;
      color: $white;
    }
  }

  &--dealer,
  &--showroom {

    .four--columns {
      @include rem(margin, 0 0 0 20px);

      @include breakpoint(tablet) {
        margin: 0;
      }
    }

    a {
      @include rem(margin, $small-margin $medium-margin $small-margin 0);
      display: block;

      @include breakpoint(tablet) {
        @include rem(margin, $small-margin 0);
      }
    }

    .header {
      @include rem(font-size, 18px);
      line-height: 1.2;
      font-weight: 500;
      @include rem(margin-bottom, 10px);
      @include rem(margin-top, $tiny-margin);

      @include breakpoint(tablet) {
        @include rem(font-size, 28px);
        margin-bottom: 0;
        @include rem(margin-top, 0);
      }
    }

    .header + .address--mobile + .sub-header {
      @include rem(margin-top, 3px)
    }

    .sub-header {
      @include rem(margin, 0);
      @include rem(font-size, 16px);

      @include breakpoint(tablet) {
        @include rem(font-size, 26px);
      }
    }

    .copy:first-of-type {
      @include rem(margin-top, $small-margin);
    }

    .address {
      //display: none;

      @include breakpoint(tablet) {
        display: block;
      }

      &--mobile {
        display: block;

        @include breakpoint(tablet) {
          display: none;
        }
      }

      p {
        @include rem(margin-bottom, 0);
      }
    }

    .opening-times {
      div:last-of-type > p {
        text-align: right;
      }
    }

    h3 {
      @include rem(margin, 0);
    }

    i {
      @include rem(margin, $tiny-margin 0 $tiny-margin 0);
      display: inline-block;
      float: left;

      &.showroom:before,
      &.dealer:before {
        @extend .icon-map-pin--default;
        display: inline-block;
        @include rem(margin-right, $tiny-margin);

        @include breakpoint(tablet) {
          @include rem(font-size, 35px);
        }
      }

      &.showroom:before {
        color: $dark-green;
      }

      &.dealer:before {
        color: $orange;
      }

      ~ p {
        @include rem(margin, 5px 0);
      }

      @include breakpoint(tablet) {
        @include rem(margin, $tiny-margin 0);
      }
    }

    .opening-hours,
    .additional-info {
      display: none;

      @include breakpoint(tablet) {
        display: inline;
        display: initial;
      }
    }

    .address-details {

      @include breakpoint(tablet) {
        display: inline;
        display: initial;
      }

      .sub-header {
        display: none;

        @include breakpoint(tablet) {
          display: block;
          display: initial;
        }
      }

      .js-linkify-address {
        @include rem(margin, 10px 0 0 0);

        @include breakpoint(tablet) {
          margin: 0;
        }

        a {
          @include rem(margin, 0 0 10px 0);

          @include breakpoint(tablet) {
            @include rem(margin, 10px 0);
          }
        }
      }
    }

    .additional-info {
      @include breakpoint(tablet) {
        display: block;
        width: 100%;
      }
    }
  }
  &--dealer {
    flex: 0 0 100%;
    max-width: 100%;
    @include breakpoint(mobile-big){
      flex: 0 0 50%;
      max-width: 50%;
    }@include breakpoint(desktop){
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    padding: 1.5rem 1.5rem;
    .section-map--container{
      @include rem(padding, 20px);
      box-shadow: 0 1px 4px 1px rgba(0,0,0,0.2);
    }
    .header{
        span {
            font-size: 0.85em;
        }
    }
    .address{
      margin-top: 20px;
    }
    .address-details{
      display: block;
      margin-top: 20px;
      a{
        margin: 0;
      }
    }
    .dealer{
      float: right;
    }
  }
}
