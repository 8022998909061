.collection-pagination {
    @include rem(margin, 10px auto 40px!important);

    @include breakpoint(tablet) {
        @include rem(margin, 40px auto!important);
    }

    @include breakpoint(desktop-big) {
        @include rem(margin, 60px auto!important);
    }

    .text-module {
        margin: 0;

        @include breakpoint(tablet) {
            @include rem(margin, 0 150px);
        }

        @media screen and (min-width: 1600px) {
            margin: 0;
        }

        .seven--columns {
            @include breakpoint(tablet) {
                width: 100%;
            }

            @include breakpoint(desktop) {
                width: 58.33333%;
            }
        }

        p:last-child {
            margin: 0;
        }
    }

    &__navigation {
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }

        &--left,
        &--right {
            @include breakpoint(tablet) {
                @include rem(max-width, 100px);
                position: absolute;
                top: 0;
                z-index: 1;
            }
        }

        
        &--left {
            @include breakpoint(tablet) {
                direction: rtl;
                left: 0;
                text-align: right;
            }

            @media screen and (min-width: 1600px) {
                @include rem(left, -160px);
            }
        }

        &--right {
            @include breakpoint(tablet) {
                right: 0;
            }

            @media screen and (min-width: 1600px) {
                @include rem(right, -160px);
            }
        }
    }

    &__dropdown {
        @include breakpoint(tablet) {
            display: none;
        }
    }

    .fs-dropdown {
        &-selected {
            @include rem(margin, 0 0 35px);
        }
    }
}