.owl-carousel {
    z-index: index($elements, carousel);

    .primary-carousel__container,
    .secondary-carousel__container {
        @include rem(margin-top, 55px);

        .three--columns {
            display: none;
        }

        .six--columns:first-of-type {

            @include breakpoint(tablet) {
                height: auto;
            }
        }

        @include breakpoint(tablet) {
            @include rem(margin-top, 0);
            @include rem(padding, 8rem 8rem 12rem);
            min-height: 48rem;

            .three--columns {
                display: block;
            }
        }

        @include breakpoint(desktop) {
            //@include rem(padding, 0 12rem 17rem);
        }

        @include breakpoint(desktop-big) {
            //@include rem(padding, 0 17rem 28rem);
        }

        .three--columns {
            @include breakpoint(tablet) {
                @extend .unit-1-12--tablet;
            }
        }

        .six--columns {
            @include breakpoint(tablet) {
                @extend .unit-11-12--tablet;
            }
        }
        // Center carousel
        &.center {
            .six--columns:first-of-type {
                width: 100%;
            }

            @include breakpoint(tablet) {
                @include rem(padding, 8rem);
            }

            @include breakpoint(desktop) {
                @include rem(padding, $mega-padding 12rem);
            }

            @include breakpoint(desktop-big) {
                @include rem(padding, 7rem 17rem);
            }

            @media (min-width: 2560px) {
                @include rem(padding, 14rem 17rem);
            }

            &.primary-carousel__container,
            &.secondary-carousel__container {

                @include breakpoint(tablet) {
                    min-height: 48rem;
                }
            }

            .primary-carousel__content,
            .secondary-carousel__content {
                @include breakpoint(phablet) {
                @include rem(padding, $large-padding $large-padding 215px $large-padding);
                }
                position: relative;
                text-align: center;

                @include breakpoint(tablet) {
                    @include rem(padding, 0 $large-padding);
                }

                @include breakpoint(desktop) {
                    @include rem(padding, 0 10rem);
                }

                @include breakpoint(desktop-big) {
                    @include rem(padding, $small-padding 25rem);
                }

                p {
                    color: $black;
                    padding: 0;
                }
            }

            .secondary-carousel__content {
                color: $black;

                @include breakpoint(tablet) {
                    color: $white;
                }

                p {
                    color: $black;

                    @include breakpoint(tablet) {
                        color: $white;
                    }
                }
            }

            .primary-carousel__image,
            .secondary-carousel__image {
                @include breakpoint(tablet) {
                    position: relative;
                }
            }
        }
        // Left carousel
        &.left {
            @include breakpoint(tablet) {
                @include rem(padding, $large-padding 0);
            }

            @include breakpoint(desktop) {
                @include rem(padding, $mega-padding 0);
            }

            @include breakpoint(desktop-big) {
                @include rem(padding, 11rem 0);
            }

            .six--columns {
                @include breakpoint(tablet) {
                    @extend .unit-6-12--tablet;
                }
            }

            &.primary-carousel__container,
            &.secondary-carousel__container {

                @media screen and (min-width: 768px) and (max-width: 1320px) {
                    .six--columns {
                        width: 41.67%;
                        margin-left: 8.33%;
                    }
                }

                @include breakpoint(tablet) {
                    min-height: 48rem;
                }
            }

            .primary-carousel__content,
            .secondary-carousel__content {

                position: relative;
                text-align: center;
                @include breakpoint(phablet) {
                    @include rem(padding, $large-padding $large-padding 215px $large-padding);
                }

                @include breakpoint(tablet) {
                    @include rem(padding, $large-padding 0);
                    position: relative;
                    text-align: left;
                }

                @include breakpoint(desktop) {
                    @include rem(padding, $small-padding 0);
                }

                @include breakpoint(desktop-big) {
                    @include rem(padding, 0);
                }

                p {
                    color: $black;
                    padding: 0;
                }
            }

            .secondary-carousel__content {
                color: $black;

                @include breakpoint(tablet) {
                    color: $white;
                }

                p {
                    color: $black;

                    @include breakpoint(tablet) {
                        color: $white;
                    }
                }
            }

            .primary-carousel__image,
            .secondary-carousel__image {
                @include breakpoint(tablet) {
                    position: absolute;
                }
            }
        }
        // Right carousel
        &.right {
            @include breakpoint(tablet) {
                @include rem(padding, $large-padding 8rem);
            }

            @include breakpoint(desktop) {
                @include rem(padding, $mega-padding 12rem);
            }

            @include breakpoint(desktop-big) {
                @include rem(padding, 14rem 17rem);
            }

            .six--columns {
                @include breakpoint(tablet) {
                    @extend .unit-6-12--tablet;
                }
            }

            .primary-carousel__content,
            .secondary-carousel__content {

                margin-top: 55px;
                //position: relative;
                text-align: center;
                @include breakpoint(phablet) {
                    @include rem(padding, $large-padding $large-padding 215px $large-padding);
                }

                @include breakpoint(tablet) {
                    @include rem(padding, $large-padding 0);
                    position: relative;
                    margin-top: 0;
                    text-align: left;
                }

                @include breakpoint(desktop) {
                    @include rem(padding, $small-padding 0);
                    position: relative;
                }

                @include breakpoint(desktop-big) {
                    @include rem(padding, 0);
                }

                p {
                    color: $black;
                    padding: 0;
                }
            }

            .primary-carousel__image,
            .secondary-carousel__image {
                position: absolute;
                bottom: 0;

                @include breakpoint(tablet) {
                    position: relative;
                }
            }

            .secondary-carousel__content {
                color: $black;

                @include breakpoint(tablet) {
                    color: $white;
                }

                p {
                    color: $black;

                    @include breakpoint(tablet) {
                        color: $white;
                    }
                }
            }
        }
    }

    .primary-carousel,
    .secondary-carousel {
        &__image {
                width: calc(100% - 28px);
                margin: 0 auto;

            @include breakpoint(phablet) {
                position: absolute;
                left: 14px;
                bottom: 0;
            }
            @include breakpoint(tablet) {
                position: relative;
                margin: auto;
                width: 100%;
            }

            img {
                height: 100%;
                max-width: 100%;
                object-fit: contain;
                margin: 0 auto;
                @include breakpoint(desktop) {
                    width: 100%;
                    visibility: hidden;
                }
            }
        }

        &__content {
            @include rem(padding, 45px);
            position: relative;
            width: 100%;
            //height:100%;
            top: 0;
            left: 0;
            text-align: center;

            @include breakpoint(tablet) {
                //position: absolute;
                @include rem(padding, 0);
            }

            p {
                padding: 0;

                @include breakpoint(tablet) {
                    @include rem(padding, 0 5rem);
                }

                @include breakpoint(desktop) {
                    @include rem(padding, 0 10rem);
                }

                @include breakpoint(desktop-big) {
                    @include rem(padding, 0);
                }

                &.module__copy {
                    @include rem(font-size, 20px);
                }

                &.module__standout {
                    @include rem(font-size, 16px);
                }
            }

            @include rem(padding, $large-padding);

            @include breakpoint(tablet) {
                @include rem(padding, $large-padding);
            }

            @include breakpoint(desktop) {
                @include rem(padding, $large-padding 10rem);
            }

            @include breakpoint(desktop-big) {
                @include rem(padding, $large-padding 25rem);
            }

            .button {
                @include rem(padding, $small-padding 7.5px);

                @media (min-width: 375px) {
                    @include rem(padding, $small-padding 15px)
                }

                @include breakpoint(tablet) {
                    @include rem(padding, $small-padding $medium-padding);
                }
            }
        }
    }

    .secondary-carousel {
        .main-carousel__image {
            margin-bottom: 0;
        }

        .main-carousel__caption {
            color: $white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            @include opacity(0);

            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                color: $white;
                width: 100%;
                height: 100%;
                @include rem(font-size, 40px);
                @include rem(height, 40px);
                @extend .icon-search--default:before;
            }
        }

        &:hover {
            @include breakpoint(tablet) {
                .main-carousel__overlay {
                    @include transition(opacity, 0.3s);
                    @include opacity(0.5);
                }

                .main-carousel__caption {
                    @include transition(opacity, 0.4s);
                    @include opacity(1);
                }
            }
        }

        &__image {
            @include rem(margin-bottom, 0);

            @include breakpoint(tablet) {
                @include rem(margin-bottom, 7px);
            }
        }

        &__caption {
            color: $black;
            @include rem(font-size, 15px);
        }

        &__overlay {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $black;
            position: absolute;
            @include opacity(0);
        }
    }

    .primary-carousel__content {
        color: $black;
        @include breakpoint(phablet) {
            @include rem(padding, $large-padding $large-padding 215px $large-padding);
        }

        @include breakpoint(tablet) {
            padding: 0;
        }

        h3 {
            color: $black;
        }

        p {
            color: $black;
        }
    }

    .secondary-carousel__content {
        color: $black;

        @include breakpoint(tablet) {
            color: $white;
        }

        h3 {
            color: $black;

            @include breakpoint(tablet) {
                color: $white;
            }
        }

        p {
            color: $black;

            @include breakpoint(tablet) {
                color: $white;
            }
        }

        .button {
            @include breakpoint(tablet) {
                color: $white;
                border-color: $white;

                &:hover {
                    background-color: $white;
                    color: $orange;
                }
            }
        }
    }
    // Owl
    .owl-nav {
        width: 100%;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        @include rem(height, 50px);

        @include breakpoint(tablet) {
            @include rem(height, 75px);
            display: block;
        }

        &.primary {
            .owl-next, .owl-prev {
                display: none;
                position: absolute;
                pointer-events: all;

                @include breakpoint(desktop) {
                    display: block;
                }

                &:before {
                    color: $orange;
                    @include fontSize(carouselArrows);
                }

                &:hover:before {
                    @include fontSize(carouselArrows);
                }

                &.disabled:before {
                    color: transparent;
                    cursor: default;
                }
            }
        }

        &.secondary {
            .owl-next, .owl-prev {
                &:before {
                    color: $orange;
                    @include fontSize(carouselArrows);

                    @include breakpoint(tablet) {
                        color: $white;
                    }
                }

                &:hover:before {
                    color: $white;
                    @include fontSize(carouselArrows);
                }

                &.disabled:before {
                    color: transparent;
                    cursor: default;
                }
            }
        }
    }

    .owl-next, .owl-prev {
        position: absolute;
        pointer-events: all;

        &:before {
            color: $orange;
            @include fontSize(carouselArrows);
        }

        &:hover:before {
            @include fontSize(carouselArrows);
        }

        &.disabled:before {
            color: transparent;
            cursor: default;
        }
    }

    .owl-prev {
        left: 1.5%;

        &:before {
            color: $orange;
            @extend .icon-link-left--default:before;
        }

        &:hover:before {
            @extend .icon-link-left--default:before;
        }
    }

    .owl-next {
        right: 2.5%;

        &:before {
            color: $orange;
            @extend .icon-link-right--default:before;
        }

        &:hover:before {
            @extend .icon-link-right--hover:before;
        }
    }

    .owl-dots {
        @include rem(margin, 0 14px);
        @include rem(padding-top, 15px);
        width: calc(100% - 28px);
        position: initial;
        bottom: -57px;
        pointer-events: all;
        text-align: center;
        border-bottom: 1px solid $light-grey;

        @include breakpoint(tablet) {
            @include rem(margin, 0);
            @include rem(padding-top, 0);
            position: absolute;
            width: 100%;
            bottom: 15px;
            border-bottom: none;
        }

        &.primary, &.secondary {
            .owl-dot:before {
                font-size: 1.5rem;

                @include breakpoint(tablet) {
                    font-size: 1.6rem;
                }
            }
        }

        &.primary {
            .owl-dot:before {
                color: $orange;
            }
        }

        &.secondary {
            .owl-dot:before {
                color: $orange;

                @include breakpoint(tablet) {
                    color: $white;
                }
            }
        }
    }

    .owl-dot {
        @include rem(margin-right, $small-margin);
        @include rem(margin-bottom, 15px);
        display: inline-block;

        @include breakpoint(tablet) {
            @include rem(margin-bottom, 0);
        }

        &:before {
            @extend .icon-radio--default:before;
            font-size: 2rem;
        }

        &.active:before {
            @extend .icon-radio--hover:before;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .primary-carousel__image,
    .secondary-carousel__image {
        visibility: visible;
        display: block;

        @include breakpoint(tablet) {
            visibility: hidden;
            display: none;
        }
    }

    &.carousel {
        &.carousel--news {
            background-color: $orange;

            .secondary-carousel {
                &__container {
                    margin: 0;
                    min-height: 0;

                    &.left {
                        @include breakpoint(tablet) {
                            height: auto;

                            .six--columns {
                                @extend .unit-8-12--tablet;
                            }
                        }

                        @include breakpoint(desktop) {
                            @include rem(padding, 40px 0);

                            .six--columns {
                                @extend .unit-6-12--desktop;
                            }
                        }
                    }

                    .secondary-carousel__content {
                        @include rem(padding, 40px);

                        @include breakpoint(tablet) {
                            padding: 0;
                        }

                        p {
                            color: $white;
                        }

                        h3 {
                            color: $white;
                        }

                        .button {
                            color: $white;
                            border-color: $white;

                            &:hover {
                                background-color: $white;
                                color: $orange;
                            }
                        }
                    }
                }
            }

            .owl-nav {
                &.secondary {
                    .owl-next,
                    .owl-prev {
                        &.disabled:before,
                        &.disabled:hover:before {
                            color: transparent;
                        }

                        &:before {
                            color: $white;

                            @include breakpoint(desktop) {
                                @include rem(font-size, 45px);
                            }
                        }

                        &:hover:before {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    &.featured-collection-carousel {
        .owl {
            &-dots {
                bottom: 0;
                @include rem(margin-top, 0);
                position: relative;

                @include breakpoint(tablet) {
                    @include rem(margin-top, 20px);
                }
            }

            &-dot {
                @include rem(margin-right, 20px);

                &:not(.active):before {
                    content: '\e9b9';
                    color: $light-grey;
                    @include opacity(0.5);
                }
            }
        }
    }
}

.carousel.owl-carousel {

    .owl-nav.primary {
        .owl-prev,
        .owl-next {
            display: block;
        }
    }

    .owl-nav.secondary {
        .owl-prev:hover:before,
        .owl-next:hover:before {
            color: $orange;

            @include breakpoint(tablet) {
                color: $white;
            }
        }

        .owl-prev.disabled:before,
        .owl-next.disabled:before {
            color: transparent;
            cursor: default;
        }
        
    }
    

    .owl-prev:before,
    .owl-prev:hover:before {
        display: block;
        color: #e76525;
        @extend .icon-arrow-down--default:before;
        @include transform(rotate(90deg));
    }

    .owl-next:before,
    .owl-next:hover:before {
        display: block;
        color: #e76525;
        @extend .icon-arrow-down--default:before;
        @include transform(rotate(-90deg));
    }

    @include breakpoint(tablet) {

        %mobile-icon {
            color: #e76525;
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .owl-prev {
            &:before {
                content: "\e90c";
                @extend %mobile-icon;
            }
            &:hover:before {
                content: "\e90e";
                @extend %mobile-icon;
            }
            &:before,
            &:hover:before {
                @include transform(rotate(0deg));
            }
        }

        .owl-next {
            &:before {
                content: "\e901";
                @extend %mobile-icon;
            }
            &:hover:before {
                content: "\e903";
                @extend %mobile-icon;
            }
            &:before,
            &:hover:before {
                @include transform(rotate(0deg));
            }
        }
    }
}
