.cart-tandcs {
    &__checkout {

        @include breakpoint(tablet) {
            left: 50%;
        }

        button {             
            transition: all .25s ease;

            @include breakpoint(desktop) {
                width: auto;
            }

            &:disabled,
            &[disabled] {
                background-color: $breadcrumbs;
                border: 1px solid $breadcrumbs;
                color: $mid-grey;
            }
        }

        #paypal-button {
            display: inline-block;
            @include rem(margin, 1rem .5rem 0 0);
            position: relative;
            width: 100%;
    
            @include breakpoint(desktop) {
                float: left;
                width: auto;
            }
    
            .paypal-button {
                display: inline;  
            }

            .zoid-outlet {
                z-index: 1;
            }
        }

        .paypal-button {            
            &--disabled {
                pointer-events: none;
                &:before {
                    background-color: rgba($breadcrumbs, .7);
                    border-radius: 4px;
                    content: '';
                    display: block;
                    @include rem(height, 45px);
                    position: absolute;
                    width: 100%;
                    z-index: 2;
                }
            }
        }

        .module__links {
           position: relative;
           width: 100%;

            @include breakpoint(tablet) {
                display: block;
                text-align: left;
                //width: calc(100% - 45px);
            }

            @include breakpoint(desktop) {
                display: inline-block;
                width: auto;
            }
            
            & > .button {
                @include breakpoint(tablet) {
                    margin-right: 0;
                }
            }

            &--right {
                @include breakpoint(tablet) {
                    float: right;
                }
            }
        }
    }
    &__navigation {

        @include breakpoint(tablet) {
            right: 50%;
        }

        a.button {

            &:last-of-type {

                @include breakpoint(tablet) {
                    float: left;
                }
            }
        }
    }
    &__checkout,
    &__navigation {

        @include breakpoint(tablet) {
            float: left;
            position: relative;
        }

        .module__links {
            .button {
                width: 100%;

                @include breakpoint(desktop) {
                    width: auto;
                }
            }
        }
    }

    &__paypal-btn {
        position: relative;
        width: calc(100% - 45px);

        @include breakpoint(tablet) {
            width: 100%;
        }

        @include breakpoint(desktop) {
            float: left;
            width: auto;
        }

        button {
            @include rem(margin-left, 45px);

            @include breakpoint(tablet) {
                @include rem(margin, 10px 5px 0 0);
            }
        }

        &:before {
            background-image: url(../../usm-img/shop/paypal-icon.svg);
            background-repeat: no-repeat;
            content: '';
            @include rem(height, 35px);
            @include rem(left, 0);
            position: absolute;
            @include rem(top, 18px);
            @include rem(width, 35px);

             @include breakpoint(tablet) {
                @include rem(height, 30px);
                @include rem(left, -40px);
                @include rem(top, 19px);
                @include rem(width, 30px);
            }
        }
    }

    &__info-copy {
        @include rem(margin, 30px 0 35px 35px);
        position: relative;
        text-align: left;

         @include breakpoint(tablet) {
            display: block;
            @include rem(margin, 25px 0 0 0);
            @include rem(max-width, 245px);
        }

        &:before {
            @include rem(font-size, 20px);
            @include rem(left, -35px);
            position: absolute;
            @include rem(top, 2px);
        }
    }

    &__req-message {
        display: block;
        @include fontSize(paragraph);
        @include rem(margin, 0 0 13px 0);
        text-align: left;

            @include breakpoint(tablet)  {
            @include rem(margin, 12px 0 8px);
            text-align: right;
        }
    }

    &__form {
        @include fontSize(paragraph);
        @include rem(margin-bottom, $medium-margin);

		@include breakpoint(tablet) {
			@include rem(margin-bottom, $large-margin);
		}		

        .form__input-wrapper {
            margin-bottom: 0;
        }

        .checkbox {
            text-align: left;
            @include rem(margin-bottom, 15px);

             @include breakpoint(tablet)  {
                @include rem(margin-bottom, 5px);
                text-align: right;
            }

            .parsley-errors-list {
                @include rem(margin, 5px 20px 0 11px);

                 @include breakpoint(tablet) {
                    @include rem(margin, 0 20px 0 0);
                }
            }

            &--background {     
                @include rem(padding, 17px 0);

                @include breakpoint(tablet)  {
                    @include rem(padding, 20px 0);
                }
            }

            label {
                color: $black;
                @include rem(margin-right, 20px!important);
                @include rem(margin-left, 20px);

                &:before,
                &:after {
                    @include rem(left, -9px!important);
                }
            } 
            
            a {
                color: $black;
                text-decoration: underline;
            }
        }
    }
}

.module__cart-tandcs {
    .divider-module {
        @include rem(margin, 0 0 17px 0);

        .container {
            width: 100%;
        }
    }
}