.flap {
	@include rem(margin-bottom, $medium-margin);

	@include breakpoint(desktop-big) {
		position: relative;
	}

	

	/*&--original {
		@include breakpoint(desktop-big) {
			transform-style: preserve-3d;
			@include rem(height, 450px);
			@include transition(all .3s ease);
			@include rem(margin-bottom, $medium-margin);
	
			.flap__front-panel {
				top: 0;
				left: 0;
				width:100%;
				height:100%;
				position: absolute;
				backface-visibility: hidden;
				transform-style: preserve-3d;
				transform: rotateY(0deg);
				z-index:index($elements, flap-panel);
				@include transition(all .3s ease-out);
	
			} 
			
			&:hover {
	
				.flap__front-panel {
					transform: rotateX(5deg);
	
					-webkit-box-shadow: 0 4px 4px rgba(151, 151, 151, 1),
										0 8px 8px rgba(151, 151, 151, 1),
										0 16px 16px rgba(151, 151, 151, 1), 
										0 32px 32px rgba(0, 0, 0, .15), 
										0 64px 64px rgba(0, 0, 0, .15);
	
					-moz-box-shadow:    0 4px 4px rgba(151, 151, 151, 1),
										0 8px 8px rgba(151, 151, 151, 1),
										0 16px 16px rgba(151, 151, 151, 1), 
										0 32px 32px rgba(0, 0, 0, .15), 
										0 64px 64px rgba(0, 0, 0, .15);
										
						box-shadow: 	0 4px 4px rgba(151, 151, 151, 1),
										0 8px 8px rgba(151, 151, 151, 1),
										0 16px 16px rgba(151, 151, 151, 1), 
										0 32px 32px rgba(0, 0, 0, .15), 
										0 64px 64px rgba(0, 0, 0, .15);
	
				}
	
			}
		}
	}*/
	

}


