.delivery-details {
    @include rem(margin, 0 auto 30px auto);

    @include breakpoint(tablet) {
        @include rem(margin, 40px auto 60px auto);
    }

    .module {
        &__form {
            &--subtitle {
                @include rem(margin-bottom, 5px!important);

                @include breakpoint(tablet) {
                    display: inline-block;
                    @include rem(margin-bottom, 0!important);
                }
            }
        }
        &__header {
            @include rem(margin, 25px 0 20px 0);

            @include breakpoint(tablet) {
                @include rem(margin, 0 0 15px 0);
            }
        }

        &__ctas {
            overflow: hidden;

            @include breakpoint(tablet) {
                @include rem(margin, 10px 0 20px 0);
            }

            @include breakpoint(desktop) {
                @include rem(margin, 0 0 20px 0);
            }
            
            .button {
                @include rem(padding, 10px 20px);
                -webkit-border-radius:0; 
                border-radius:0;
                white-space: normal;                
            }

            .grid__cell {
                &:first-of-type {

                    @include breakpoint(tablet) {
                        float: right;
                    }
                }

                &:last-of-type {
                    @include breakpoint(tablet) {
                        @include rem(margin-top, 15px);
                    }

                    @include breakpoint(desktop) {
                        @include rem(margin-top, 0);
                    }
                }
            }
        }

        &__text-links {
            display: inline-block;
            
            &:not(:last-of-type) {
                @include rem(margin, 10px 30px 10px 0);
            }

            &:hover {
                color: $light-grey;
            }
        }
    }

    .form {
        @include breakpoint(tablet) {
            @include rem(margin-right, 10px);
        }

        @include breakpoint(desktop) {
            margin-right: 0;
        }
        
        &__row {
            @include rem(margin, 15px 0 0);

            &--contact {
                @include rem(margin, 15px 0 30px);

                @include breakpoint(tablet) {
                    @include rem(margin, 15px 0 28px);
                }
            }
        }
        &__input-wrapper {
            &--background {
                @include rem(margin, 0 0 15px 0);
                @include rem(padding, 25px 20px);

                @include breakpoint(tablet) {
                    @include rem(margin, 0 0 10px 0);
                    @include rem(padding, 14px 15px);
                }

                @include breakpoint(desktop) {
                    @include rem(margin, 0 0 25px 0);
                }

                label {
                    color: $black;
                    @include rem(font-size, 16px);
                    margin-right: 0!important;
                    @include rem(padding, 0 0 0 24px!important);

                    @include breakpoint(tablet) {
                        @include rem(padding, 0 0 0 32px!important);
                    }
                }

                .checkbox {
                    @include rem(margin, 0 0 22px 0);

                    @include breakpoint(tablet) {
                        @include rem(margin, 0 0 10px 0);
                    }

                    &:last-of-type {
                        @include rem(margin, 0);
                    }
                }
            }
        }
    }

    .radioButton {
        margin: 0;

        &__item {
            &--horizontal {
                margin-bottom: 0;
            }
        }
    }
}