.teaser {
  overflow: hidden;
  position: relative;
  border: $border-thin solid $light-grey;
  height: auto;

  &__image,
  &__overlay {
    display: none;
  }

  &__image {
    width: auto;
    height: 100%;
    @include opacity(0);

    &--focused {
      @include transition(opacity .1s ease);
      @include opacity(1);
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__overlay {
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient($black-gradient-start, $black-gradient-end);
    position: absolute;
    @include rem(height, 30%);
    @include opacity(0.7);
    @include transition(all 0s);
  }

  &__title {
    margin-top: 0;
  }

  &__content {
    color: $black;
    z-index: index($elements, teaser-content);
    position: static;
    @include rem(padding, 0 $medium-padding $medium-padding);
  }

  @include breakpoint(desktop) {
    .button {
      @include rem(padding, $tiny-padding $medium-padding);
      margin-bottom: 0;
    }
  }

  @include breakpoint(desktop-big) {

    border: none;
    @include rem(height, 450px !important); // Limit js height

    &__image,
    &__overlay {
      display: block;
    }

    &__image {
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
      @include opacity(1);

      &--focused {
        @include opacity(0);
      }
    }

    &__content {
      position: absolute;
      color: $white;

      p {
        @include opacity(0);
      }

      .button {
        @include opacity(0);
      }
    }

    .button {
      @include rem(padding, $small-padding $large-padding);
    }

    &:hover & {
      &__overlay {
        display: none;
      }

      &__image {
        @include opacity(0);

        &--focused {
          @include opacity(1);
        }
      }

      &__content {
        bottom: 0 !important;
        background: transparent;
        color: $black;
        @include transition(bottom .4s ease);

        p {
          @include transition(opacity .4s ease);
          @include opacity(1);
        }

        .button {
          @include transition(opacity .2s ease);
          @include opacity(1);
        }

      }

    }
  }


  &.teaser-no-text-reveal {
    .js-teaser__content, .teaser__overlay {
      display: none;
    }

    .teaser__image--focused {
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
		width: auto;
		height: 100%;
    }
  }
}

