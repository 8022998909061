
* {
    @include box-sizing();
}

*:focus {
    outline: none;
}

html {
    height: 100%;   
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    @include rem-baseline;
    overflow-x: hidden;
}

html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: always;
}

body {
    @include fontSize(baseSize);
}

::-moz-selection {}

::selection {}

a::-moz-selection {}

a::selection {}

::-webkit-input-placeholder {}

:-moz-placeholder {}

/*::-webkit-scrollbar,
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb { 
    display: none; 
    width: 0 !important
}  */

[type=search] {
    /* resets webkit styling on search inputs */
    -webkit-appearance: none;
}

/* Style the webkit validation message for forms */
::-webkit-validation-bubble-message {
    @include rem(padding, 40px);
}

a,
a:visited {
  word-wrap: break-word;
}

