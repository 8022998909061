.mobile-toggles {

	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	&__burger, &__info, &__search, &__language, &__cart {
		//display:inline-block;
		//position: absolute;
		//margin:auto;
		//top:0;
		//bottom:0;
		//@include rem(width, 55px);
		//text-align: center;
		//height: 3.55rem;
		//
		//@media screen and (max-width: 360px) {
		//	@include rem(width, 50px);
		//}

		display: inline-flex;
		align-items: center;
		border-left: 1px solid $mid-grey;
		padding: 0 1.4rem;
		height: 100%;

		@include breakpoint(desktop){
			display:none;
		}
		&:before {
			@include rem(font-size, 30px);
			color: $black;

			@media screen and (max-width: 360px) {
				@include rem(font-size, 25px);
			}
		}
		&.is-on {
			z-index: 0;
			&:not(.mobile-toggles__search):before {
				@include rem(font-size, 30px);
			}
			.mobile-toggles__hover-line	{
				/*@include rem(height, 60px);
				@include transition(height .3s ease-out);
				background-color: $megamenu;
				bottom: -1.3rem;
				border-left: $border-thin solid $light-grey;
				z-index: -1;

				@include breakpoint(tablet) {
					@include rem(height, 90px);
					bottom: -2.8rem;
				}*/
			}
		}
	}

	&__burger{
		//@include rem(right, $gutter);
		//position: absolute;
		//right: 0;
		order: 4;
		&:before {
			@extend .icon-menu--default:before;
			line-height: 1.3;

			@media screen and (max-width: 360px) {
				line-height: 1.4;
			}
		}
		&.is-on {
			.mobile-toggles__hover-line {
				border-right: 0;
			}

			&:before {
				@extend .icon-menu--hover:before;
				line-height: 1.3;
				@include rem(font-size, 40px);
			}

			@include breakpoint(desktop){
				// Overriding inline js
				display:none!important;
			}
		}
	}

	&__cart {
		//@include rem(right, 55px);
		//@include rem(width, 70px);
		//
		//@media screen and (max-width: 360px) {
		//	@include rem(right, 50px);
		//	@include rem(width, 65px);
		//}

		order: 3;
		&__quantity {
			color: $black;
			@include rem(font-size, 19px);
			@include rem(margin-left, 3px);
			vertical-align: super;
		}

		&:before {
			@extend .icon-basket--default:before;
			line-height: 1.3;

			@media screen and (max-width: 360px) {
				line-height: 1.4;
			}
		}

		& + .mobile-toggles__search {
			@include rem(right, 125px);

			@media screen and (max-width: 360px) {
				@include rem(right, 115px);
			}

			& + .mobile-toggles__language {
				@include rem(right, 127px);
	
				@media screen and (max-width: 360px) {
					@include rem(right, 116px);
				}
			}
		}
	}

	&__search{
		order: 2;
		@include rem(right, 55px);
		display: none;
		@include breakpoint(desktop) {

		}
		@media screen and (max-width: 360px) {
			@include rem(right, 50px);
		}

		&:before {
			@extend .icon-search--default:before;
			position: absolute;
			top: 5px;
			left: 15px;

			@media screen and (max-width: 360px) {
				left: 13px;
			}
		}
		&.is-on {
			&:before {
				@extend .icon-search--default:before;
				color: $mid-grey;
			}
		}
		&.is-on {
			@include breakpoint(desktop){
				// Overriding inline js
				display:none!important;
			}
		}
	}

	&__language{
		order: 1;
		//@include rem(right, 110px);
		//
		//@media screen and (max-width: 360px) {
		//	@include rem(right, 100px);
		//}

		span {
			border-right: none!important;
		}
		img {
			@include rem(margin, 5px auto);
			@include rem(width, 40px);

			@media screen and (max-width: 360px) {
				@include rem(width, 35px);
			}
		}
		&.is-on {
			@include breakpoint(desktop){
				// Overriding inline js
				display:none!important;
			}
		}
	}

	&__info{
		@include rem(right, 60px);
		&:before {
			@extend .icon-info--default:before;
		}
		&.is-on {
			&:before {
				@extend .icon-info--hover:before;
			}
		}
	}

	&__hover-line {
		/*@extend  .main-navigation__hover-line;
		@include rem(bottom, -31px);
		bottom: -3.1rem;
		height: 6rem;
		background-color: transparent;
		bottom: -1.2rem;
		border-left: 1px solid $light-grey;
		z-index: -1;*/

		/*@include breakpoint(tablet) {
			@include rem(height, 90px);
			bottom: -2.7rem;
		}*/
	}
}
