.button {
	display: inline-block;
	text-align:center;
	color:$orange;
	border:solid $border-thin $orange;
	@include rem(margin, $small-margin $tiny-margin 0 0);
	@include rem(padding, $small-padding $large-padding);

	@include breakpoint(tablet) {
		
	}
	&:hover {
		background: $orange;
		color:$white;
	}

	&--centered {
		margin: $small-margin auto;
		display:table;
	}

	&--default {
		color: $white;
		background: $orange;
	}

	&--white {
		color:$white;
		border-color:$white;
		&:hover {
			background: $white;
			color:$orange;
		}
	}

	&--orange {
		color: $white;
		background: $orange;
		&:hover {
			background: $orange;
			color: $white;
		}
	}

	&--clear {
		@include breakpoint(desktop){
			display:table;
		}
	}

	&--medium {
		width: 100%;

		@include breakpoint(tablet){
			width: 240px;
		}
	}

	&--full-width {
		width: 100%;
	}

	&__download {
		width: 100%;
		@include rem(padding, $small-padding 3.9rem);
		
		&:after {
			@include rem(padding-left, 15px);
			@include rem(font-size, 24px);
			@extend .icon-download--default:before;
			vertical-align: middle;
		}

		&:hover:after {
			color: $white;
		}

		@include breakpoint(tablet) {
			width: auto;
		}

		@include breakpoint(desktop) {
			text-align: left;
		}
	}

	&--search {
		@include rem(margin, 5px 0);
		@include rem(padding, 11px 17px);
		width: 100%;

		&:after {
			@include rem(padding-left, 15px);
			@include rem(font-size, 24px);
			@extend .icon-search--default:before:before;
			vertical-align: middle;
			display: inline;
		}
	}

	&[disabled="disabled"] {
		background-color: #f6f7f9;
    	border: 1px solid #f6f7f9;
    	color: #b2bcc1;
		pointer-events: none;
	}
}