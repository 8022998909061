// Grid modifier
.site-footer {
	.three--columns {
		@include breakpoint(tablet) {
			@extend .unit-3-12--tablet;
		}
	}
}

.site-footer {
	color: $white;
	background: $footer-bg;
	position: relative;
	@include rem(padding, 220px 0 0);

	@include breakpoint(tablet) {
		@include rem(padding, $large-padding 0 $tiny-padding);
	}

	.share-bar__list {
		display: flex;
		align-items: end;
		justify-content: center;
		@include breakpoint(tablet) {
			justify-content: flex-start;
		}
	}

	&__inner,
	&__site-info {
		margin: 0 auto;
		@include breakpoint(desktop-big) {
			width: 100%;
		}
	}

	&__panel {
		text-align: center;
		@include rem(margin, $medium-margin 0 0 0);

		&__title {
			margin-bottom: 0;
			cursor: pointer;
			@include breakpoint(tablet) {
				cursor: default;
				@include rem(margin, 0 0 $medium-margin);
			}
		}
		&__content {
			display: none;
			justify-content: center;
			&--mob {
				display: block;
				@include breakpoint(tablet) {
					display: none;
				}
			}
		}
		&__list {
			@extend .list-reset;
			@include rem(margin, $medium-margin 0 0);
		}
		&__item {
			@include rem(margin, $small-margin 0);
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__link {
			color: $off-white;
		}

		&--contact {
			a {
				color: $white;
				display: block;
				@include rem(margin, 0 0 0);

				&:hover {
					color: $orange;

					> span {
						&:before {
							@include rem(font-size, 25px);
						}
					}

					.icon-phone--default {
						&:before {
							@include rem(font-size, 25px);
							@extend .icon-phone-2--hover;
						}
					}

					.icon-mail--default {
						&:before {
							@include rem(font-size, 25px);
							@extend .icon-mail-2--hover;
						}
					}

					.icon-chat--default {
						&:before {
							@include rem(font-size, 25px);
							@extend .icon-chat-2--hover;
						}
					}

					.icon-location--default {
						&:before {
							@include rem(font-size, 25px);
							@extend .icon-location-2--hover;
						}
					}
				}
			}

			.site-footer__panel__list {
				@include rem(margin, $medium-margin 0 0);
				a {
					@include fontSize(footerContactText);
					position: relative;
					@include rem(padding-left, 35px);
					@include rem(margin, $small-margin $small-margin 0 0);

					> span {
						display: inline-block;
						position: absolute;
						left: 0;
						@include rem(top, 3px);
						&:before {
							color: $white;
							@include rem(font-size, 25px);
						}
					}
				}
			}
		}

		@include breakpoint(tablet) {
			text-align: left;
			margin: 0;
			&__content {
				display: block;
			}
		}
	}

	.share-bar {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		width: 85%;
		@include rem(top, -50px);

		&__item {
			@include rem(margin, 0 5px);
			@include breakpoint(tablet) {
				@include rem(margin, 0 2px);
			}
			@include breakpoint(desktop-big) {
				@include rem(margin-right, 8px);
			}
		}
		&__link {
			&:before {
				color: $white;
				@include fontSize(footerSocial);
			}

			&.facebook {
				&:hover {
					&:before {
						@include fontSize(footerSocial);
						@extend .icon-facebook-2--hover:before;
					}
				}
			}
			&.instagram {
				&:hover {
					&:before {
						@include fontSize(footerSocial);
						@extend .icon-instagram-2--hover:before;
					}
				}
			}
			&.pinterest {
				&:hover {
					&:before {
						@include fontSize(footerSocial);
						@extend .icon-pinterest-2--hover:before;
					}
				}
			}
			&.youtube {
				&:hover {
					&:before {
						@include fontSize(footerSocial);
						@extend .icon-youtube-2--hover:before;
					}
				}
			}
			&.twitter {
				&:hover {
					&:before {
						@include fontSize(footerSocial);
						@extend .icon-twitter-2--hover:before;
					}
				}
			}
			&.linkedin {
				&:hover {
					&:before {
						@include fontSize(footerSocial);
						@extend .icon-linkedin-2--hover:before;
					}
				}
			}
			&.line {
				svg {
					width: 35px;
					height: auto;
					@include breakpoint(tablet) {
						width: 25px;
					}
					@include breakpoint(desktop) {
						width: 35px;
					}
				}
			}
		}

		@include breakpoint(tablet) {
			position: static;
			width: 100%;
			@include rem(margin, 0 0 35px);
		}
	}

	&__copyright {
		text-align: center;
		color: $copyright;
		@include rem(margin, $large-margin 0 $medium-margin);
		@include rem(font-size, 14px);

		@include breakpoint(tablet) {
			text-align: left;
		}
	}

	&__configurator {
		position: absolute;
		@include rem(top, -206px);
		width: 100%;

		form {
			.eight--columns {
				width: calc(100% - 103px);

				@include breakpoint(tablet) {
					width: 66.66667%;
				}
			}
			.four--columns {
				float: right;
				@include rem(width, 100px);

				@include breakpoint(tablet) {
					float: none;
					width: 33.33333%;
				}
			}
		}

		@include breakpoint(tablet) {
			position: relative;
			@include rem(top, 0);
			width: auto;
		}

		&-title {
			@include rem(font-size, 19px);

			@include breakpoint(tablet) {
				@include rem(font-size, 18px);
				@include rem(margin-top, 35px);
			}
		}

		.form__text-input {
			@include placeholder {
				color: $white;
			}

			@include breakpoint(tablet) {
				@include rem(font-size, 16px);
			}
		}

		.form__submit-input {
			margin: 0;
		}

		.form__text-input,
		.form__submit-input {
			@include rem(font-size, 16px);
			border-color: $white;
			color: $white;
			@include rem(height, 45px);
		}

		&-title,
		.form__text-input {
			color: $white;
		}

		.parsley-errors-list {
			min-width: 300px;

			@include breakpoint(tablet) {
				min-width: 200px;
			}

			@include breakpoint(desktop) {
				min-width: 328px;
			}

			li {
				color: $white;

				a {
					display: inline-block;
					font-weight: bold;
					text-decoration: underline;
				}
			}
		}
	}
}
