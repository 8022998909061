.module__cart {

    a {
        &:hover,
        &:active {
            color: $light-grey;
        }
    }

    .three--columns {
        @include breakpoint(tablet) {
            @extend .unit-3-12--tablet;
        }
    }

    .last {
        text-align: left;
    }

    .grid {
        overflow: hidden;
    }

    .divider-module .container {
        width: 100%;
    }

    img {
        @include responsiveImage();
    }

    .divider-module {
        clear: both;
    }
}

.cart {
    &-headers {
        display: none;
        
        @include breakpoint(tablet) {
            display: block;
        }

        p {
            @include fontSize(smallTitle);
            font-weight: normal;
        }
    }

    &-product {
        @include rem(margin, 25px 0);

        @include breakpoint(tablet) {
            @include rem(margin, 48px 0);
        }
    }

    &__price {
        display: none;
        @include rem(margin-bottom, 0);
        @include fontSize(subtotalPrice);

        @include breakpoint(tablet) {
            display: block;
            line-height: 1;
            @include rem(margin-bottom, 3px);
        }

        &--mobile {
            display: inline-block;
            @include rem(margin, 25px 0 14px 0);

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    &__tax {
        display: none;
        @include fontSize(productVAT);

        @include breakpoint(tablet) {
            display: block;
            @include rem(margin, 0 0 8px 0);
        }

        &--mobile {
            display: inline-block;
            @include fontSize(productVAT);
            @include rem(margin, 0 0 0 4px);

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    &__title {
        @include rem(margin-bottom, 0);
        @include fontSize(productTitle);

        &--main {
            display: inline-block;
            @include rem(margin, 0 0 12px);
    
            @include breakpoint(tablet) {
                display: block;
                @include rem(margin-bottom, 0);
            }
        }
    }

    &__copy {
        display: none;
        @include rem(margin, 0 0 5px);
        width: 100%;

        &:last-child {
            @include rem(margin, 0 0 15px);
        }

        @include breakpoint(tablet) {
            display: block;
            width: calc(100% - 2rem);
        }
    }

    &__config {
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }

        &--mobile {
            display: block;
            @include rem(margin-bottom, 7px);

            span {
                color: $orange;
                font-weight: bold;
            }

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    &__config-link {
        display: none;

        &:not(:last-of-type) {
            @include rem(margin-bottom, 10px);
            @include rem(margin-right, 12px);
        }

        @include breakpoint(tablet) {
            display: inline-block;
        }

        &--mobile {
            display: block;
            @include rem(font-size, 18px);

            @include rem(margin-bottom, 9px);

            &:nth-of-type(2) {
                @include rem(margin-bottom, 5px);
            }

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    &__update {
        background-color: $orange;
        color: $white;
        display: block;
        @include rem(margin, 10px 0 0 0);
        @include rem(max-width, 120px);
        @include rem(padding, 11px 30px);
        text-align: right;
        vertical-align: text-top;

        &:hover,
        &:active {
            color: $white;
        }

        @media screen and (min-width: 280px) {
            display: inline;
            @include rem(margin, 10px 0 0 15px);
        }

        @include breakpoint(tablet) {
            background-color: transparent;
            color: $orange;
            display: block;
            @include rem(margin, 12px 0 0 0);
            max-width: none;
            padding: 0;

            &:hover,
            &:active {
                color: $light-grey;
            }
        }
    }

    &__quantity {
        width: 40px;
        height: 40px;
        float: left;
        font-weight: bold;
        border: 1px solid $light-grey;
        border-radius: 0;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        @include breakpoint(tablet) {
            float: none;
            width: 35px;
            height: 35px;
        }
        @include breakpoint(desktop) {
            width: 40px;
            height: 40px;
        }

        &--plus,
        &--minus {
            display: inline-block;
            width: 40px;
            height: 40px;
            background-color: $white;
            border: 1px solid $light-grey;

            &:before {
                display:inline-block;
                vertical-align: middle;
                @include rem(margin, 11px);
            }
            @include breakpoint(tablet) {
                width: 35px;
                height: 35px;
                vertical-align: inherit;

                &:before {
                    @include rem(margin, $small-margin);
                }
            }
            @include breakpoint(desktop) {
                width: 40px;
                height: 40px;
            }
        }

        &--plus {
            border-left: 0;
            &:before {
                @extend .icon-arrow-down--default:before;
                transform: rotate(180deg);
            }
        }

        &--minus {
            border-right: 0;
            float: left;

            @include breakpoint(tablet) {
                float: none;
            }

            &:before {
                @extend .icon-arrow-down--default:before;
            }
        }

        &--disabled {
            cursor: default;
            
            &:before {
                color: $mid-grey;
            }                
        }

        &--default {
            @include fontSize(smallTitle);
            border: none;
        }

        &--label {
            display: block;
            font-weight: bold;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    &__remove {
        display: inline-block;
        @include rem(margin-top, 19px);

        &--inactive {
            color: $light-grey;
            cursor: default;
            @include rem(margin-bottom, 15px);
        }

        &-message {
            display: inline-block;
            @include fontSize(cartRemoveMessage);
            line-height: 1.4;
            @include rem(margin-bottom, 30px);
            vertical-align: top;
            width: 75%;

            @include breakpoint(tablet) {
                display: block;
                @include rem(margin-bottom, 5px);
                width: 100%;
            }
        }

        &-warning-icon {
            display: inline-block;
            @include rem(margin, 0 15px 30px 0);
            vertical-align: text-top;

            @include breakpoint(tablet) {
                display: block;
                @include rem(margin, 0);
            }
        }

        &-yes {
            @include breakpoint(tablet) {
                &:after {
                    content: '|';
                    color: $box-shadow;
                    @include rem(font-size, 14px);
                    @include rem(margin, 0 10px);
                    vertical-align: top;
                }
            }                
        }

        &-yes,
        &-no {
            border: 1px solid $orange;
            display: inline-block;
            @include rem(padding, 10px 40px);
            text-align: center;
            width: 46%;

            &:hover,
            &:active {
                background-color: $orange;
                color: $white;
            }

            @include breakpoint(tablet) {
                border: none;
                padding: 0;
                text-align: left;
                width: auto;

                &:hover,
                &:active {
                    background-color: transparent;
                    color: $light-grey;
                }
            }
        }

        &-no {
            float: right;

            @include breakpoint(tablet) {
                float: none;
            }
        }

        &-confirmation {
            display: block;

            &--inactive {
                display: none;
            }
        }

        @include breakpoint(tablet) {
            @include rem(margin-top, 0);
        }
    }

    &__dimensions {
        display: block;
        @include fontSize(paragraph);
        @include rem(margin-bottom, 0);

        @include breakpoint(tablet) {
            display: inline-block;
        }
    }

    &__weight {
        display: block;
        @include fontSize(paragraph);
        margin-bottom: 0;

        @include breakpoint(tablet) {
            display: inline-block;
        }
    }

    &__colour {
        @include fontSize(paragraph);
        @include rem(margin-bottom, 8px);
    }

    &__total {

        @include rem(margin-bottom, 18px);

        p {
            @include fontSize(paragraph);
            @include rem(margin-bottom, 0);
        }

        &:first-child {
            @include rem(margin-top, 20px);

            @include breakpoint(tablet) {
                @include rem(margin-top, 0);
            }
        }

        &--last .four--columns,
        .four--columns {
            width: 50%;

            @include breakpoint(tablet) {
                width: 33.33333%;
            }
        } 

        &--last .four--columns,
        &--last .six--columns:last-of-type,
        .four--columns,
        .six--columns:last-of-type {
            p {
                @include rem(font-size, 18px);

                @include breakpoint(desktop) {
                    @include rem(margin, 0 0 0 15px);
                    @include fontSize(baseSize);
                }

                &.module__price--total {
                    @include rem(font-size, 22px);
                    @include rem(margin-bottom, 0);
                    line-height: 1;
    
                    @include breakpoint(tablet) {
                        @include rem(font-size, 24px);
                    }
                }
            }
        }

        &--last .six--columns,
        .six--columns {
            width: 50%;
        }

        &--last .eight--columns,
        .eight--columns {
            width: 50%;

            @include breakpoint(tablet) {
                width: 66.66667%;
            }
        }

        &--last .eight--columns {
            .module__copy {
                @include rem(margin-right, 12px);

                @include breakpoint(desktop) {
                    @include rem(margin-right, $tiny-padding);
                }
            }
        }

        &--last .six--columns,
        .eight--columns,
        .six--columns {
            @include rem(padding, 0 3px 0 7px);
            vertical-align: top;

            .module__copy {
                @include rem(margin-right, 9px);
                @include rem(margin-bottom, 0);

                @include breakpoint(tablet) {
                    @include rem(margin-right, 12px);
                }

                @include breakpoint(desktop) {
                    @include rem(margin-right, 2px);
                }
            }
        }

        &--mobile {
            float: right;
            text-align: right;

            p {
                &:first-of-type {
                    margin: 0;
                }
            }
            @include breakpoint(tablet) {
                display: none;
            }
        }
        
    }

    &__image {
        margin: 0 auto;

        @include breakpoint(tablet) {
            margin: 0;
            @include rem(padding, 0 30px 0 0);
        }
    }

    &__product-image {
        @include breakpoint(tablet) {
            float: left;
            margin-left: -66.66666%;
        }
    }

    &__product-details {
        @include breakpoint(tablet) {
            float: left;
            margin-left: 33.33333%; 
        }
    } 

    &__product-quantity {
        @include breakpoint(tablet) {
            text-align: right;
            @include rem(padding, 0 37px 0 7px);
        } 
        @media screen and (min-width: 768px) and (max-width: 865px) {
            @include rem(padding, 0 9px 0 7px);
        }
        @media screen and (min-width: 866px) and (max-width: 1000px) {
            @include rem(padding, 0 30px 0 7px);
        }
    }
}