.section--showroom {
  position: relative;

  @include rem(margin-bottom, 0);

  //Grid modifier
  .five--columns {
    @include breakpoint(tablet) {
      @extend .unit-6-12--tablet;
    }
    @include breakpoint(desktop) {
      @extend .unit-5-12--desktop;
    }
  }

  background: $dark-grey;
  color: $white;

  @include rem(padding, 0 0 0);
  @include breakpoint(tablet) {
    @include rem(padding, 70px 0);
  }
  @include breakpoint(desktop) {
    @include rem(padding, $mega-padding 0);
  }
  @include breakpoint(desktop-big) {
    @include rem(padding, 100px 0);
  }

  .section__content {
    @include rem(padding-bottom, 0);

    @include breakpoint(tablet) {
      @include rem(padding-bottom, 150px);
    }
  }

  .section__title {
    margin-top: 0;
    margin-bottom: 1rem;
    @include breakpoint(tablets) {
      margin-bottom: initial;
    }
  }

  .section__image {
    display: none;
  }

  &-picture-el {
    overflow: hidden;
    min-height: 500px;

    .section__content {
      padding: 0;
      @include breakpoint(tablet) {
        padding: 0;
      }
    }

    .section__text {
      position: relative;
      z-index: 2;
    }

    .image__divider {
      @include breakpoint(tablet) {
        width: 100%;
        margin-left: auto;
        height: 100%;
      }
    }

    .section__gradient {
      display: none;
      @include breakpoint(tablet) {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
        bottom: 0;
        background: rgb(92, 102, 111);
        background: linear-gradient(98deg, rgba(92, 102, 111, 1) 36%, rgba(92, 102, 111, 0) 76%);
      }
    }

    .section__image {
      display: block;
      @include breakpoint(tablet) {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    .image__image {
      width: 100%;
      height: auto;
      @include breakpoint(tablet) {
        height: 100%;
        object-fit: cover;
      }
    }

    .showroom__panel__content {
      display: block;
    }

    &.has-bg-fullwidth {
      .showroom__panel {
        background: $orange;
        margin: 0 -28px;
        padding: 3.5rem 28px;

        @include breakpoint(tablet) {
          margin: -7rem 0;
          padding: 7rem 30px;
        }
        @include breakpoint(desktop) {
          margin: -8rem 0;
          padding: 8rem 30px;
        }
        @include breakpoint(desktop-big) {
          margin: -10rem 0;
          padding: 10rem 40px;
        }
      }
    }

    &.has-bg-center {
      background-color: #FFFFFF;

      .grid {
        position: relative;
        @include breakpoint(tablet) {
          margin: -7rem 0;
          padding: 7rem 0;
        }
        @include breakpoint(desktop) {
          margin: -8rem 0;
          padding: 8rem 0;
        }
        @include breakpoint(desktop-big) {
          margin: -10rem 0;
          padding: 10rem 0;
        }

      }

      .section__image {
        margin: 0 -7px;
        @include breakpoint(tablet) {
          margin: 0;
        }
      }

      .five--columns {
        position: relative;
        z-index: 1;
      }

      .showroom__panel {
        background: $orange;
        margin: 0 -28px;
        padding: 3.5rem 28px;

        @include breakpoint(tablet) {
          margin: -7rem 0;
          padding: 7rem 30px;
          margin-left: -7px;
        }
        @include breakpoint(desktop) {
          margin: -8rem 0;
          padding: 8rem 30px;
          margin-left: -7px;
        }
        @include breakpoint(desktop-big) {
          margin: -10rem 0;
          padding: 10rem 40px;
          margin-left: -7px;
        }
      }
    }

    &.is-ratio-based {
      @include rem(padding, 0 0 0);
      @include breakpoint(tablet) {
        @include rem(padding, 0 0);
        min-height: inherit;
        .section__content {
          position: relative;
        }
        .section__text {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
        .section__image {
          position: relative;
        }
      }
      @include breakpoint(desktop) {
        @include rem(padding, 0 0);
      }
      @include breakpoint(desktop-big) {
        @include rem(padding, 0 0);
      }

      &.no-text {
        min-height: inherit;
      }
    }
  }

  &.text-black {
    .section__content {
      color: $black;
    }

    .showroom-list__link{
      color: $black;
      span{
        &:before{
          color: $black;
        }
      }
    }
  }
}

.showroom-list {
  @extend .grid;
  @extend .list-reset;
  @include rem(margin-top, $medium-margin);
  @include rem(margin-bottom, $medium-margin);

  &__item {
    @include fontSize(showroomList);
    @extend .six--columns;
    @include rem(margin-bottom, 5px);
  }

  &__link {
    color: $white;
    position: relative;
    display: inline-block;
    width: auto;
    @include rem(padding-right, 30px);

    span {
      position: absolute;
      transform: rotate(-90deg);
      right: 0;
      @include rem(top, 2px);

      &:before {
        @include rem(font-size, 20px);
        @extend .icon-arrow-down-2--default:before;
      }
    }

    &:hover {
      > span:before {
        color: $orange;
      }
    }
  }
}

.showroom__panel {
  text-align: center;

  &:first-of-type {
    margin-bottom: 3.5rem;
  }

  &:last-of-type {
    margin-top: 3.5rem;
  }

  &__header {
    @include rem(padding, 0);

    @include breakpoint(tablet) {
      @include rem(padding, 0 $x-large-margin);
    }
  }

  &__content {
    display: none;

    p {
      @include fontSize(smallTitle);
    }
  }

  .button {
    width: 100%;
  }

  @include breakpoint(tablet) {
    text-align: left;
    &:first-of-type {
      margin-bottom: initial;
    }
    &:last-of-type {
      margin-top: 0;
    }
    &__header {
      @include rem(padding, 0);
    }
    &__content {
      display: block;
    }
    .button {
      width: auto;
    }
  }
}
