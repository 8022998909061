.hero {
  text-align: center;
  padding: 3% 5%;
  @include transition(height .4s ease);
  @include rem(height, 360px);

  img {
    display: none;
  }

  .hero__content {
    text-align: center;
  }

  .section__image img {
    display: block;
    visibility: hidden;
  }

  &--smaller {
    margin: 0 auto;
    width: 100%;

    @include breakpoint(desktop-big) {
      width: calc(100% - 30rem);
    }
  }

  &__title {
    @include rem(margin, 0 0 $default-margin);
    line-height: 1.2;
  }

  @include breakpoint(tablet) {
    @include rem(height, 500px);
  }
  @include breakpoint(desktop) {
    @include rem(height, 560px);
  }
  @include breakpoint(desktop-big) {
    padding: 2%;
    @include rem(height, 750px);
  }

  &--404 {
    .hero {
      &__content {
        text-align: left;

        p.lead {
          width: 100%;
          @include breakpoint(tablet) {
            width: calc(100% - 10rem);
          }
          @include breakpoint(desktop) {
            width: calc(100% - 6rem);
          }
        }

        @include breakpoint(tablet) {
          @include rem(padding, $large-padding $medium-padding);
        }
        @include breakpoint(desktop) {
          @include rem(padding, $x-large-padding $large-padding);
        }
      }

      &__text {
        width: 100%;
        @include breakpoint(tablet) {
          width: 500px;
        }
      }
    }

    ~ .section--sign-up {
      margin-top: 0;
    }
  }

  &--daughter {
    @include rem(height, 250px);

    @include breakpoint(tablet) {
      @include rem(height, 300px);
    }
    @include breakpoint(desktop) {
      @include rem(height, 400px);
    }
    @include breakpoint(desktop-big) {
      padding: 2%;
      @include rem(height, 600px);
    }
  }

  &--shop {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  &--split {
    display: none;
    padding: 0;

    @include breakpoint(tablet) {
      display: block;
    }

    .hero__title {
      @include fontSize(sectionTitle);

      &--bold {
        font-weight: bold;
      }

      span {
        font-weight: bold;
      }
    }

    .hero__content {
      @include rem(padding, 75px 0);

      &--left,
      &--right {
        @include rem(padding, 0);
        display: block;

        @include breakpoint(desktop) {
          @include rem(padding, 0 20px);
        }
      }

      &--left {
        text-align: right;

        p {
          float: right;
        }
      }

      p {
        width: 100%;

        @include breakpoint(tablet) {
          width: 380px;
        }
      }

      .hero__title__arrow {
        &:before,
        &:after {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .hero__content--left {
      color: $black;

      .hero__title__arrow:before {
        @extend .icon-arrow-down--default:before;
        @include transform(rotate(90deg));
        color: $black;
      }
    }

    .hero__content--right {
      color: $white;

      .hero__title__arrow:after {
        @extend .icon-arrow-down--default:before;
        @include transform(rotate(-90deg));
        color: $white;
      }
    }

    &--shop {
      background-position: 50% 0%;
      overflow: hidden;

      @include breakpoint(tablet) {
        @include rem(height, 690px);
      }

      @include breakpoint(desktop) {
        @include rem(height, 730px);
      }

      @include breakpoint(desktop-big) {
        background-position: 50% 50%;
      }

      .hero__content {

        &-wrapper {
          @include rem(max-width, 430px);
          width: 100%;
          overflow: hidden;
          position: relative;
          text-align: center;
        }

        &--left,
        &--right {

          h1 {
            @include breakpoint(tablet) {
              @include rem(font-size, 28px);
            }
            @include breakpoint(desktop) {
              @include rem(font-size, 42px);
            }
          }

          h1,
          p {
            color: $white;
            width: 100%;
          }

          p {
            @include rem(max-width, 350px);
            float: none;
            margin-left: auto;
            margin-right: auto;

            @include breakpoint(tablet) {
              @include rem(font-size, 16px);
            }
          }
        }

        &--left {
          .hero__content-wrapper {
            @include breakpoint(tablet) {
              float: right;
              @include rem(padding-left, 10px);
            }
          }
        }

        &--right {
          .hero__content-wrapper {
            @include breakpoint(tablet) {
              float: left;
              @include rem(padding-right, 10px);
            }
          }
        }
      }
    }
  }

  &--primary-top {
    padding: 0;

    .hero__content {
      padding: 2%;

      &--black {
        color: #000;
      }

      &--white {
        color: #fff;
      }
    }

    @include rem(height, 250px);

    @include breakpoint(tablet) {
      @include rem(height, 300px);
    }
    @include breakpoint(desktop) {
      @include rem(height, 400px);
    }
    @include breakpoint(desktop-big) {
      @include rem(height, 600px);
    }
  }

  &--primary-bottom {
    padding: 0;

    .hero__content {
      padding: 2%;

      &--black {
        color: #000;
      }

      &--white {
        color: #fff;
      }
    }

    @include rem(height, 250px);

    @include breakpoint(tablet) {
      @include rem(height, 300px);
    }
    @include breakpoint(desktop) {
      @include rem(height, 400px);
    }
    @include breakpoint(desktop-big) {
      @include rem(height, 600px);
    }
  }

  &--primary-top-overlay {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .grid {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &.left {
      .grid {
        justify-content: flex-start;
      }
    }

    &.right {
      .grid {
        justify-content: flex-end;
      }
    }

    &.top {
      .grid {
        align-items: flex-start;
      }
    }

    &.bottom {
      .grid {
        align-items: flex-end;
      }
    }

    .hero__content {
      text-align: center;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 40px;
      box-sizing: border-box;

      &--black {
        color: #000;
      }

      &--white {
        color: #fff;
      }
    }
  }

  &--primary-bottom--overlay {
    position: relative;
    padding: 0;
    overflow: hidden;

    .hero__container {
      position: absolute;
      width: 100%;

      @include breakpoint(tablet) {
        bottom: 6%;
      }
    }

    .hero__content {
      padding: 3% 5%;
      @include breakpoint(desktop-big) {
        padding: 2%;
      }

      &--black {
        color: #000;
      }

      &--white {
        color: #fff;
      }
    }

    @include breakpoint(tablet) {
      @include rem(height, 500px);
    }
    @include breakpoint(desktop) {
      @include rem(height, 560px);
    }
    @include breakpoint(desktop-big) {
      padding: 2%;
      @include rem(height, 750px);
    }
  }

  &--primary-left,
  &--primary-right {
    height: auto;
    padding: 3% 14px 3% 14px;

    @include breakpoint(tablet) {
      padding: 3% 5%;
    }

    @media screen and (max-width: 767px) {
      background-image: unset !important;
    }

    .section__image {
      img {
        display: block;
        visibility: visible;
        width: 100%;
        height: auto !important;
        max-height: 30rem;

        @include breakpoint(tablet) {
          display: none;
        }
      }
    }

    .hero__content {
      &--white {
        color: #000;
        @include breakpoint(tablet) {
          color: #fff;
        }
      }
    }
  }

  &--primary-left {
    overflow: hidden;

    .hero__content {
      padding: 2%;

      &--left {
        @include rem(padding, 35px 35px 35px 0);
        @include breakpoint(tablet) {
          @include rem(padding, 70px);
        }
        @include breakpoint(desktop) {
          @include rem(padding, $x-large-padding $large-padding);
        }
        @include breakpoint(desktop-big) {
          @include rem(padding, $x-large-padding $mega-padding);
        }
      }

      &--black {
        color: #000;
      }
    }

    @include breakpoint(desktop) {
      @include rem(height, 500px);
    }
    @include breakpoint(desktop-big) {
      @include rem(height, 560px);
    }
  }

  &--primary-right {
    .six--columns:first-of-type {
      //display: none;
      @include breakpoint(tablet) {
        display: inline-block;
        margin-left: 50%;
      }
    }

    .hero__content {
      padding: 2%;

      &--right {
        @include rem(padding, 35px 35px 35px 0);
        @include breakpoint(tablet) {
          @include rem(padding, 70px);
        }
        @include breakpoint(desktop) {
          @include rem(padding, $x-large-padding $large-padding);
        }
        @include breakpoint(desktop-big) {
          @include rem(padding, $x-large-padding $mega-padding);
        }
      }

      &--black {
        color: #000;
      }
    }

    @include breakpoint(desktop) {
      @include rem(height, 500px);
    }
    @include breakpoint(desktop-big) {
      @include rem(height, 560px);
    }
  }

  &--copy-left,
  &--copy-right {
    @include rem(padding, 0);
    height: auto;
    background-image: none;
    overflow-x: auto;

    .hero__container {
      background-color: $green;
      color: $white;

      @include rem(padding, 26px 22px);
      @include breakpoint(tablets) {
        @include rem(padding, 70px);
      }
      @include breakpoint(desktop) {
        @include rem(padding, 25px 35px $large-padding);
      }
      @media(min-width: 2560px) {
        @include rem(padding, $x-large-padding $mega-padding);
      }
    }

    p.lead {
      display: none;
      @include breakpoint(tablet) {
        display: block;
      }
    }

    .section__image {
      img {
        visibility: visible;
        width: 100%;

        @include breakpoint(tablet) {
          visibility: hidden;
        }
      }
    }

    @include breakpoint(tablet) {
      @include rem(padding, 3% 5%);
      overflow: hidden;
    }
    @include breakpoint(desktop) {
      @include rem(height, 560px);
    }
    @include breakpoint(desktop-big) {
      @include rem(padding, 2%);
    }
  }

  &--carousel {
    padding: 0;

    img {
      display: inline;
    }

    .glide {
      height: 100%;
    }

    .glide__track {
      height: 100%;
    }

    .glide__bullets {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-wrap: wrap;
    }

    .glide__bullet {
      border: none;
      background: none;
      margin: 0;
      width: 15px;
      box-sizing: border-box;
      height: 15px;
      border-radius: 50%;
      line-height: 0;
      padding: 0;
      box-shadow: inset 0 0 0 2px #fff;
      transition: box-shadow 0.2s ease-in;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      &.glide__bullet--active {
        background-color: #ffffff;
      }

      &:hover {
        box-shadow: inset 0 0 0 4px #fff;
      }
    }

    .glide__arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background: none;
      margin: 0;
      padding: 0;

      &--left {
        left: 28px;

        &:before {
          content: "\e90c";
          position: absolute;
          left: 0;
          color: white;
          font-family: icomoon !important;
          speak: none;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          transform: translateY(-50%);
          top: 50%;
          display: none;
          font-size: 3rem;
          text-shadow: 0 0 11px rgba(0, 0, 0, 0.1);
          @include breakpoint(desktop-big-content) {
            display: block;
            font-size: 6rem;
          }
        }

        &:hover {
          &:before {
            content: "\e90e";
          }
        }
      }

      &--right {
        right: 28px;

        &:before {
          content: "\e901";
          position: absolute;
          right: 0;
          color: #ffffff;
          font-family: icomoon !important;
          speak: none;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          display: none;
          font-size: 3rem;
          transform: translateY(-50%);
          top: 50%;
          text-shadow: 0 0 11px rgba(0, 0, 0, 0.1);
          @include breakpoint(desktop-big-content) {
            display: block;
            font-size: 6rem;
          }
        }

        &:hover {
          &:before {
            content: "\e903";
          }
        }
      }
    }

    .glide__slides {

      height: 100%;
    }

    .glide__slide {
      position: relative;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      .container {
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 32px auto;
        @include breakpoint(desktop-big-content) {
          max-width: calc(100% - 240px);
        }
      }

      .bg-video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .grid {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }

      .showroom__panel {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 40px;
        box-sizing: border-box;

        &.centered {
          text-align: center;
        }

        .section__header {
          color: #ffffff;
          text-shadow: 0 0 11px rgba(0, 0, 0, 0.2);

          &.black {
            color: #000000;
            text-shadow: none;
          }

        }
      }

      .section__title {
        margin-top: 0;
        line-height: 1.2;
        margin-bottom: 24px;
        @include breakpoint(desktop-big-content) {
          font-size: 6rem;
        }
      }

      &.left {
        .container {
          justify-content: flex-start;
        }
      }

      &.center {
        .container {
          justify-content: center;
        }
      }

      &.right {
        .container {
          justify-content: flex-end;
        }
      }

      &.top {
        .container {
          align-items: flex-start;
        }
      }

      &.bottom {
        .container {
          align-items: flex-end;
        }
      }
    }

    .glide__link {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      cursor: pointer;
    }
  }

  &--video {
    position: relative;
    width: 100%;
    padding: 0 0 56.25%;
    height: 0;

    .hero-video-element {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .hero-video-sound {
      position: absolute;
      bottom: 40px;
      right: 40px;
      z-index: 10;
      border: none;
      background: none;

      &.is-on {
        .sound-icon {
          &.sound-on {
            display: block;
          }

          &.sound-off {
            display: none;
          }
        }
      }
    }

    .sound-icon {
      &.sound-on {
        display: none;
      }

      &.sound-off {
        display: block;
      }

      img {
        width: 40px;
        height: 40px;
        display: block;
      }
    }

  }

}
