.module__cart-details {
    .container {
        @include rem(padding, 20px 0);
    }
    .cart {
        &__details {
            @include rem(padding-bottom, $medium-padding);

            &:last-of-type {
                @include rem(padding-bottom, 0);
            }
        }
        
        &__disclaimer-copy {
            display: none;
            @include fontSize(paragraph);

            @include breakpoint(tablet) {
                display: block!important;
            }
        }
    }

    .module {
        &__content .container {
            @include rem(padding, 0);
            border: 0;
        }
         &__header {
            border-bottom: 1px solid $orange;
            @include rem(padding, 0 0 20px 0);
            @include rem(margin, 0 0 20px);
             @include breakpoint(tablet) {
                border-bottom: none;
                @include rem(margin, 0 0 5px);
                padding: 0;
            }
             p {
                &:first-child {
                    display: none;
                     @include breakpoint(tablet) {
                        display: block;
                    }
                }
                &:last-child {
                    display: block;
                     @include breakpoint(tablet) {
                        display: none;
                    }
                }
                &:after {
                    @extend .icon-arrow-down--default:before;
                    display:inline-block;
                    @include rem(font-size, 22px);
                    position: absolute;
                    right: 0;
                     @include breakpoint(tablet) {
                        content: '';
                    }
                }
            }
             &.is-on {
                
                p:after {
                    transform: rotate(180deg);
                }
            }
            &:hover {
                cursor: pointer;
                 @include breakpoint(tablet) {
                    cursor: default;
                }
            }
        }
    }
}