.loading {
    display: none;

    .spinner-wrapper {
        position: fixed;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        top: 0;
        left: 0;
        background: rgba(255,255,255,0.80);
        z-index: 300;
    
        .spinner-image {
            position: absolute;
            left: 30%;
            top: 40%;

            @include breakpoint(tablet) {
                left: 40%;
            }

            @include breakpoint(desktop) {
                left: 45%;
            }
        }
    
        .spinner {
            position: absolute;
            display: block;
            margin: 0px;
            width: 1px;
            height: 1px;
            top: 40%;
            left: 45%;
        }
    }
}