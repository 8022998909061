.search-module {
    white-space: nowrap;
    &__button {
       width: 100%;
       display: block;
       @include breakpoint(tablet) {
           width: auto;
           display: table-cell;
       }
    }
    &__item {
        display: block;
        width: 100%;

        .form__text-input {
            @include rem(padding, 18px 0 10px 0);
        }

        @include breakpoint(tablet) {
            display: table-cell;
            padding-right: 2rem;
            @include rem(margin-right, 160px);
        }
    }
}