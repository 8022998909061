.module {
    &__banner {
        &--empty-cart {
            @include rem(margin-top, 20px);
            @include rem(margin-bottom, $tiny-margin);

            @include breakpoint(tablet) {
                @include rem(margin-top, 40px);
            }
        }

        &--empty-cart-copy {
            @include rem(margin, $medium-large-margin 0);

            .module__copy,
            .module__copy--bold {
                color: $error-red;
            }

            .module__copy {
                &--bold {
                    @include rem(margin-bottom, 2px);
                    @include rem(font-size, 20px);
                    font-weight: 500;
                }
            }
			@include rem(margin, $medium-large-margin 0);
			
			.module__copy,
			.module__copy--bold {
				color: $error-red;
			}

			&__copy {
				&--bold {
					@include rem(margin-bottom, 2px);
					@include rem(font-size, 20px);
					font-weight: 500;
				}
			}
        }

        &--empty-cart-ctas {
            @include rem(margin-bottom, $medium-margin);

            @include breakpoint(desktop) {
                @include rem(margin-bottom, 90px);
            }

            ~ .divider-module {
                @include rem(padding-bottom, $medium-padding);
            }
        }

        &--order-not-processed {
            .module__header {
                .error-icon, h2 {
                    display: block;

                    @include breakpoint(desktop) {
                        display: inline-block;
                    }
                }

                .error-icon {
                    @include rem(margin-right, 25px);
                    float: left;

                    @include breakpoint(desktop) {
                        @include rem(margin-right, 55px);
                        float: none;
                    }
                }

                h2 {
                    vertical-align: super;
                }
            }
            &-copy {
                @include rem(padding, 15px 0 $tiny-padding);

                @include breakpoint(desktop) {
                    @include rem(padding, 35px 0 25px);
                }

                a {
                    color: $black;
                    text-decoration: underline;
                }

                p {
                    @include rem(margin-bottom, $medium-margin);
                }

                & ~ .divider-module {
                    @include rem(padding-bottom, 0);

                    @include breakpoint(desktop) {
                        @include rem(padding-bottom, 80px);
                    }
                }
            }

            &-cta {
                @include rem(padding-bottom, 0);

                @include breakpoint(desktop) {
                    @include rem(margin-bottom, $large-margin);
                }
            }
        }
    }
	
	&__cart-error {
		@include rem(margin, $medium-large-margin 0 $medium-margin);

		@include breakpoint(tablet) {
			@include rem(margin, 50px 0);
		}
		
		.error-message {
			@include rem(padding, 23px 35px);
			border: 1px solid $error-red;
            color: $error-red;
            
            .error-icon,
            .error-list,
            p {
                display: block;

                @include breakpoint(tablet) {
                    display: table-cell;
                    vertical-align: middle;
                }
            }

			.error-icon {
                @include rem(margin-bottom, 15px);

                @include breakpoint(tablet) {
                    @include rem(margin-bottom, 0);
                    @include rem(padding-right, 33px);
                }
            }
            
            svg {
                vertical-align: middle;
            }
			
            p,
            .error-list {
				@include rem(margin-bottom, 0);
				
				@include breakpoint(tablet) {
                    @include rem(padding-right, 0);
                    width: 100%;
				}
			}

			a {
				color: $error-red;
                font-weight: bold;
            }
            
            .error-link {
                @include rem(margin-left, 15px);
                text-decoration: underline;
            }

			&.align-center {
				width: 100%;
				text-align: left;

				@include breakpoint(tablet) {
                    @include rem(padding-right, 100px);
					text-align: center!important;
				}
            }
            
            &--list {
                .error-icon {
                    text-align: center;
                }

                .error-list {
                    &__content {
                        @include rem(margin-top, 10px);
                        @include rem(paddin-left, 18px);
                    }
                }
            }
		}
	}
}