.module__shop {
    @include rem(margin-top, 52px);
    @include rem(margin-bottom, 32px);

    @include breakpoint(tablet) {
        @include rem(margin-bottom, $small-margin);
    }
    .shop {
        &__header {
            display: none;

            @include breakpoint(tablet) {
                display: block;
            }
            &--mobile {
                display: block;

                @include breakpoint(tablet) {
                    display: none;
                }
            }
        }
        &__image {
            @include rem(padding-right, 24px);
        }
        &__image-viewer-wrapper {
            position: relative;
            overflow: hidden;
            
            .hidden {
                display: none;
            }
        }
        &__image-viewer {
            @include rem(padding-right, 24px);
            width: 100%;
            height: 470px;
            @include rem(margin-bottom, 40px);

            @include breakpoint(tablet) {
                height: 575px;
                @include rem(margin-bottom, 0);
            }
        }

        &__title {
            @include rem(margin-bottom, $small-margin);
            @include fontSize(shopHeading);
        }

        &__price {
            @include rem(margin-bottom, $small-margin);
            @include fontSize(shopPrice);

            @include breakpoint(tablet) {
                @include rem(margin-bottom, 16px);
            }
        }
        
        &__tax {
            @include fontSize(shopVAT);
        }

        &__text {
            display: none;
            @include rem(margin-bottom, $small-margin);

            @include breakpoint(tablet) {
                display: block;
                @include rem(margin-bottom, 7px);
            }
        }

        &__colour {
            @include rem(margin, 20px 0 10px 0);
        }

        &__config-link {
            //display: none;
            display: block;
            @include rem(font-size, 20px);
            @include rem(margin-top, 25px);
            text-align: center;

            @include breakpoint(tablet) {
                display: block;
                @include rem(font-size, 18px);
                @include rem(margin-top, 15px);
                text-align: left;

                &:hover,
                &:active {
                    color: $light-grey;
                }
            }

            /*&--mobile {
                display: block;

                @include breakpoint(tablet) {
                    display: none;
                }
            }*/
        }

        &--description {
            p {
                font-weight: bold;
                @include rem(margin-bottom, 10px);

                @include breakpoint(tablet) {
                    @include rem(margin-bottom, 5px);
                }

                span {
                    font-weight: 400;
                }
            }
        }
    }

    iframe {
        border: none;
    }

    .quantity-buttons {
        @include rem(width, 145px);
        float: left;

        @include breakpoint(tablet) {
            display: block;
            @include rem(margin-bottom, 15px);
        }
    }
    .cart__quantity {
        display: block;
        width: 40px;
        height: 46px;
        font-weight: bold;
        float: left;
        border: 1px solid $light-grey;
        text-align: center;

        @include breakpoint(tablet) {
            @include rem(height, 40px);
        }

        &--plus,
        &--minus {
            display: inline-block;
            width: 40px;
            height: 46px;
            background-color: $white;
            border: 1px solid $light-grey;

            @include breakpoint(tablet) {
                @include rem(height, 40px);
            }

            &:before {
                display:inline-block;
                vertical-align: middle;
                @include rem(margin, 14px 12px);

                @include breakpoint(tablet) {
                    @include rem(margin, $small-margin);
                }
            }
        }

        &--plus {
            border-left: 0;
            &:before {
                @extend .icon-arrow-down--default:before;
                transform: rotate(180deg);
            }
        }

        &--minus {
            float: left;
            border-right: 0;
            &:before {
                @extend .icon-arrow-down--default:before;
            }
        }

        &--disabled {
            cursor: default;
            
            &:before {
                color: $mid-grey;
            }                
        }

        &--default {
            @include fontSize(smallTitle);
            border: none;
        }
    }

    .button--full-width {
        @include rem(margin, 0);
        @include rem(padding, 10px 15px);
        width: calc(100% - 145px);

        @include breakpoint(tablet) {
            @include rem(margin, 10px 5px 0 0);
            @include rem(max-width, 310px);
            width: 100%;
        }
    }

    .dot-navigation {
        @include rem(margin, 28px 0);
        display: block;
        width: 100%;
        text-align: center;

        @include breakpoint(desktop) {
            display: flex;
            margin: 0 auto;
            width: calc(100% - 12px);
        }
    }

    .owl-navigation-outer {
        @include rem(padding, $large-padding);
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint(tablet) {
            position: relative;
            transform: none;
        }
    }

    .owl-dots {
        width: calc(100% - 4rem);
    }

    .owl-dot {
        @include rem(margin-right, 8px);
        @include rem(margin-bottom, 0);

        @include breakpoint(tablet) {
            @include rem(margin-right, 2px);
            @include rem(margin-bottom, 15px);
            width: 100px;
            height: 100px;
            overflow: hidden;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        img {
            display: none;

            @include breakpoint(tablet) {
                display: block;
            }
        }

        &:before {
            @include rem(font-size, 10px); 

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    .owl-carousel .owl-next,
    .owl-carousel .owl-prev {
        position: absolute;
        top: 40%;
        transform: translateY(-40%);

        @include breakpoint(tablet) {
            top: 50%;
            transform: translateY(-50%);
        }

        &:before {
            font-size: 2.4rem;
        }
    }

    .owl-carousel .owl-next {
        right: -4px;
        @include breakpoint(tablet) {
            right: 2.5%;
        }
    }

    .owl-carousel .owl-prev {
        left: -4px; 
        @include breakpoint(tablet) {
            left: 1.5%;
        }
    }

    .owl-carousel figure {
        width: calc(100% - 60px);
        height: 200px;
        margin: auto;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        @include breakpoint(tablet) {
            width: 100%;
            height: 400px;
        }
    }

    .ui-tabs-nav {
        @extend .list-reset;
    }
}