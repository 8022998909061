@import "base/griddle/griddle";

@include breakpoint(phablet) {
    @include griddle-build( 2 3 4 6 12, "--phablet");
}

@include breakpoint(tablet) {
    @include griddle-build( 1 2 3 4 6 12, "--tablet");
}
@include breakpoint(desktop) {
    @include griddle-build( 2 3 4 5 6 12, "--desktop"); 
}
@include breakpoint(desktop-big) {
    @include griddle-build( 2 3 4 6 12, "--desktop-big"); 
}

.one--column {
    @extend .grid__cell;

    @include breakpoint(tablet) {
        @extend .unit-1-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-1-12--desktop;
    }
}

.two--columns {
    @extend .grid__cell;

    @include breakpoint(tablet) {
        @extend .unit-2-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-2-12--desktop;
    }
}

.three--columns {
    @extend .grid__cell;
    
    @include breakpoint(tablet) {
        @extend .unit-6-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-3-12--desktop;
    }
}

.four--columns {
    @extend .grid__cell;

    @include breakpoint(tablet) {
        @extend .unit-4-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-4-12--desktop;
    }
}

.five--columns {
    @extend .grid__cell;
    
    @include breakpoint(tablet) {
        @extend .unit-5-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-5-12--desktop;
    }
}

.six--columns {
    @extend .grid__cell;
    
    @include breakpoint(tablet) {
        @extend .unit-6-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-6-12--desktop;
    }
}
.seven--columns {
    @extend .grid__cell;
    
    @include breakpoint(tablet) {
        @extend .unit-7-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-7-12--desktop;
    }
}
.eight--columns {
    @extend .grid__cell;
    
    @include breakpoint(tablet) {
        @extend .unit-8-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-8-12--desktop;
    }
}
.nine--columns {
    @extend .grid__cell;
    
    @include breakpoint(tablet) {
        @extend .unit-9-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-9-12--desktop;
    }
}
.ten--columns {
    @extend .grid__cell;
    
    @include breakpoint(tablet) {
        @extend .unit-10-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-10-12--desktop;
    }
}
.eleven--columns {
    @extend .grid__cell;
    
    @include breakpoint(tablet) {
        @extend .unit-11-12--tablet;
    }
    @include breakpoint(desktop) {
        @extend .unit-11-12--desktop;
    }
}
.twelve--columns {
    @extend .grid__cell;
}

    