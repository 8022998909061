.module__gallery {
    .gallery {
        @include rem(margin-bottom, 45px);
    }
    
   &~ .content-tags,
   &~ .module--share {
       .container {
           width: 100%;
       }
        
       .twelve--columns{
            @extend .grid__cell;
            @include breakpoint(desktop) {
                @extend .unit-8-12--desktop;
            }
        }

       .content-tags__list {
            @include rem(margin-top, 0);
        }
    }
}