
// REM with px fallback
// See: https://github.com/pierreburel/sass-rem

$rem-baseline: 10px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

// list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
@function rem-separator($list) {
    @if function-exists("list-separator") == true {
        @return list-separator($list);
    }

    $test-list: ();

    @each $item in $list {
        $test-list: append($test-list, $item, space);
    }

    @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
    font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values) {
    $result: ();
    $separator: rem-separator($values);

    @each $value in $values {
        @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
            $result: append($result, $value / 1rem * $rem-baseline, $separator);
        }
        @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
            $result: append($result, $value / ($rem-baseline / 1rem), $separator);
        }
        @else if type-of($value) == "list" {
            $result: append($result, rem-convert($to, $value), $separator);
        }
        @else {
            $result: append($result, $value, $separator);
        }
    }

    @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values) {
    @if $rem-px-only {
        @return rem-convert(px, $values);
    }
    @else {
        @return rem-convert(rem, $values);
    }
}

@mixin rem($properties, $values) {
    @if type-of($properties) == "map" {
        @each $property in map-keys($properties) {
            @include rem($property, map-get($properties, $property));
        }
    }
    @else {
        @each $property in $properties {
            @if $rem-fallback or $rem-px-only {
                #{$property}: rem-convert(px, $values);
            }

            @if not $rem-px-only {
                #{$property}: rem-convert(rem, $values);
            }
        }
    }
}

/*** Usage

It can be used with most CSS properties

h1 {
    @include rem(margin, 12px 0);
}

*/

/* REM Responsive fonts with px fallback with

    px settings disabled
*/

@mixin fontSize($element) {
    $elFontSizes: map-get($fontSizes, $element);

    @if $elFontSizes != null {
        //font-size: 1px * map-get($elFontSizes, mobile);
        font-size: 0.1rem * map-get($elFontSizes, mobile);

        @include breakpoint(tablet) {
            //font-size: 1px * map-get($elFontSizes, tablet);
            font-size: 0.1rem * map-get($elFontSizes, tablet);
        }
        @include breakpoint(desktop) {
            //font-size: 1px * map-get($elFontSizes, desktop);
            font-size: 0.1rem * map-get($elFontSizes, desktop);
        }
    }
    @else {
        @warn 'There is no item "#{$element}" in the fontSize list';
    }
}


/*** Usage

See section 9 of css/sass/base/_settings.scss for font sizes
and apply to element:

h1 {
    @include fontSize(headerTitle);
}

*/

//============================================================
// Media queries
// 
// Possible values for $point:
// mobile-big -> phablet -> tablet -> desktop -> desktop-big
// 
// mobile-only -> mobile-big-only -> phablet-only -> tablet-only -> desktop-only
// 
// mobile-landscape
//
//============================================================
@mixin breakpoint($point) {
    // Standard: mobile first approach, increasing viewport width.
    @if $point == mobile-big {
        @media screen and (min-width: $threshold-mobileBig * 1px) {
            @content;
        }
    }
    @else if $point == phablet {
        @media screen and (min-width: $threshold-phablet * 1px) {
            @content;
        }
    }
    @else if $point == tablet {
        @media screen and (min-width: $threshold-tablet * 1px) {
            @content;
        }
    }
    @else if $point == desktop {
        @media screen and (min-width: $threshold-desktop * 1px) {
            @content;
        }
    }
    @else if $point == desktop-header-break {
        @media screen and (min-width: $threshold-desktop-header-flip * 1px) {
            @content;
        }
    }

    @else if $point == desktop-big-content {
        @media screen and (min-width: $threshold-desktop-content * 1px) {
            @content;
        }
    }
    @else if $point == desktop-big {
        @media screen and (min-width: $threshold-desktopBig * 1px) {
            @content;
        }
    }
    @else if $point == mobile-only {
        @media screen and (max-width: $threshold-mobileBig - 1 * 1px) {
            @content;
        }
    }
    @else if $point == mobile-big-only {
        @media screen and (min-width: $threshold-mobileBig * 1px) and (min-width: $threshold-tablet * 1px) {
            @content;
        }
    }
    @else if $point == phablet-only {
        @media screen and (min-width: $threshold-phablet * 1px) and (min-width: $threshold-phablet -1 * 1px) {
            @content;
        }
    }
    @else if $point == tablet-only {
        @media screen and (min-width: $threshold-phablet * 1px) and (min-width: $threshold-desktop * 1px) {
            @content;
        }
    }
    @else if $point == desktop-only {
        @media screen and (min-width: $threshold-desktop * 1px) and (min-width: $threshold-desktopBig * 1px) {
            @content;
        }
    }
    @else if $point == mobile-landscape {
        @media screen and (orientation: landscape) and (min-width: $threshold-tablet * 1px) {
            @content;
        }
    }
    @else if $point == retina {
        @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 75.6dpcm), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
            @content;
        }
    }
    @else if $point == mobile-not-short {
        @media screen and (min-height: 420px) {
            @content;
        }
    }
}
/*
    Usage : Start mobile first then
    move upwards:

    @include breakpoint(tablet) {
    
    }
*/

//Images
// Source: https://github.com/devatrox/Sunglass/
$imgbase: "../img/" !default;
@function img($filename) {
    @return url(#{$imgbase}#{$filename});
}

//Responsive Image
@mixin responsiveImage() {
  max-width: 100%;
  height: auto;
}

//Z-indexes
//://www.smashingmagazine.com/2014/06/sassy-z-index-management-for-complex-layouts/
@function my-z($list, $element) {
    $z-index: index($list, $element);
    @if $z-index {
        @return $z-index;
    }
    @warn 'There is no item "#{$element}" in this list; choose one of: #{$list}';
    @return null;
}

/* Usage: add element name on vars.scssb on z-index and then use

    z-index:index($elements, module);

*/

// Navigation List
@mixin navigation-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow: hidden;

    > li {
        float:left;

        @include breakpoint(desktop){
            text-align:center;
        }

        &:last-child {
            //margin-right: 0px;
        }

        a {
            display: inline-block;
            font-family: $font-family-default;
            color: $black;

            &:hover, &.active {
               
            }
        }

    }
}

// Remove default styling from a list tag
@mixin list-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}

//Absolute positioning
@mixin absolute-position {
    top: 0;
    left: 0;
    position: absolute;
}

// Fixed positioning
@mixin fixed-position {
    top: 0;
    left: 0;
    position: fixed;
}

// Requires inline-block or block for proper styling
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Clearfix
@mixin clearfix {
    &::after {
        clear: both;
        content: "";
        display: table;
    }
}

// Box sizing
@mixin box-sizing($type: border-box) {
    -webkit-box-sizing: $type;
    -moz-box-sizing: $type;
    -ms-box-sizing: $type;
    box-sizing: $type;
}

/* Usage:
.faded-text {
    @include box-sizing(border-box); */
//}*/

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

/* Usage:
.faded-text {
    @include opacity(0.8);
}*/


// Transitions
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// Transitions
@mixin transform($args...) {
    -webkit-transform: $args;
    -moz-transform: $args;
    -ms-transform: $args;
    -o-transform: $args;
    transform: $args;
}

/* Usage:

a {
  color: gray;
  @include transition(color .3s ease);
  &:hover {
    color: black;
  }
}

*/
// Vertically align content
//Display Table
@mixin displayTable {
    display: table;
    width:100%;
}
//Display Table cell
@mixin displayTableCell {
    display: table-cell;
    vertically-align:middle;
}

//Flexbox centering
@mixin flex-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
       -ms-flex-align: center;
          align-items: center;
}

// Placeholder
@mixin placeholder {
    &.placeholder { @content }
    &:-moz-placeholder { @content }
    &::-moz-placeholder { @content }
    &::-webkit-input-placeholder { @content }
    &:focus::-webkit-input-placeholder { color:transparent; }
    &:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
    &:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
    &:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
}

// Checkbox
@mixin checkbox {
    @include breakpoint(tablet) {
        @include rem(margin, 5px 0 23px);
    }

    &__item {
        display: inline-block;
            
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked,
        [type="checkbox"]:not(:checked) + input,
        [type="checkbox"]:checked + input {
            position: absolute;
            left: -9999px;
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label,
        [type="checkbox"]:not(:checked) + input + label,
        [type="checkbox"]:checked + input + label {
            @include rem(margin-right, 35px);
            @include rem(padding-left, 25px);
            position: relative;
            cursor: pointer;
        }

        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before,
        [type="checkbox"]:not(:checked) + input + label:before,
        [type="checkbox"]:checked + input + label:before {
            position: absolute;
            top: 2px;
            left: 0;
            @extend .icon-checkbox--default:before;
        }

        [type="checkbox"]:not(:checked) + input + label:before,
        [type="checkbox"]:checked + input + label:before {
            top: 4px;
        }

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after,
        [type="checkbox"]:not(:checked) + input + label:after,
        [type="checkbox"]:checked + input + label:after {
            position: absolute;
            top: 2px;
            left: 0;
            transition: all .2s;
            @extend .icon-checkbox--active:before;
        }

        [type="checkbox"]:not(:checked) + input + label:after,
        [type="checkbox"]:checked + input + label:after {
            top: 4px;
        }

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:not(:checked) + input + label:after {
            opacity: 0;
        }
        [type="checkbox"]:checked + label:after,
        [type="checkbox"]:checked + input + label:after {
            opacity: 1;
        }

        &--white {
            [type="checkbox"]:not(:checked) + label:after,
            [type="checkbox"]:checked + label:after,
            [type="checkbox"]:not(:checked) + input + label:after,
            [type="checkbox"]:checked + input + label:after {
                @extend .icon-checkbox--active--white:before;
            } 
            [type="checkbox"]:not(:checked) + label:before,
            [type="checkbox"]:checked + label:before,
            [type="checkbox"]:not(:checked) + input + label:before,
            [type="checkbox"]:checked + input + label:before {
                @extend .icon-checkbox--default--white:before;
            }           
        }
    }
}

// Radio Button 
@mixin radioButton {   
    
    &__item {

        &--horizontal {
            display: inline-block;
        }

        &--vertical {
            display: block;
        }

        [type="radio"] {
            display: none;

            &:checked + label:after {
                background: $white;
                box-shadow:inset 0 0 0 2px $white;
                /*background: $orange;
                box-shadow: inset 0 0 0 2px $orange;*/
                border-radius: 100%;
                content: " ";
                height: 8px;
                width: 8px;
                position: absolute;
                top: 9px;
                left: 5px; 
            }
            &:checked + label:before {
                background-color: $dark-grey;
            }
            &:focus + label:before {
                background-color: $white;
            }
            &+ label {
                position: relative;

                &:before {
                    transition: all .2s;
                    background-color: $white;
                    border-radius:10px;
                    display: inline-block;
                    position: relative;
                    height:16px;
                    width:16px;
                    content: ' ';
                    margin-right: 0.8rem;
                    cursor: pointer;sor:pointer;
                    border: 1px solid $dark-grey;
                    top: 2px;
                }
                &:after {
                    transition: all .2s;
                    background: none repeat scroll 0 0 $white;
                    border-radius: 100%;
                    content: " ";
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    top: 9px;
                    left: 5px;    
                }
            }
        }
    }

}
