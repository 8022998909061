.share-bar {
	@include rem(margin, $default-margin 0);
	&__list {
		@extend .list-reset;
		@extend .list-inline;
	}
	&__item {
		@include rem(margin-right, 8px);
	}
	&__link {
         > span{
            display:none;
        }
         > div{
            display:none;
        }
		&:before {
			@include rem(font-size, 35px);
		}
		&.facebook {
			&:before {
				@extend .icon-facebook--default:before;
				padding-left: 1px;
			}
			&:hover {
				&:before {
					@extend .icon-facebook--hover:before;
				}
			}
		}
		&.instagram {
			&:before {
				@extend .icon-instagram--default:before;
			}
			&:hover {
				&:before {
					@extend .icon-instagram--hover:before;
				}
			}
		}
		&.pinterest {
			&:before {
				@extend .icon-pinterest--default:before;
			}
			&:hover {
				&:before {
					@extend .icon-pinterest--hover:before;
				}
			}
		}
		&.linkedin {
			&:before {
				@extend .icon-linkedin--default:before;
			}
			&:hover {
				&:before {
					@extend .icon-linkedin--hover:before;
				}
			}
		}
		&.youtube {
			&:before {
				@extend .icon-youtube--default:before;
			}
			&:hover {
				&:before {
					@extend .icon-youtube--hover:before;
				}
			}
		}
		&.twitter {
			&:before {
				@extend .icon-twitter--default:before;
			}
			&:hover {
				&:before {
					@extend .icon-twitter--hover:before;
				}
			}
		}
	}

	&--no-margins {
		margin:0;
	}
}
