.tabs {
    display: inline-block;
    width: 100%;

    .tab-links {
        @include rem(padding, $small-padding 0);
        @include rem(margin, 0);
        display: none;
        border-bottom: solid $light-grey $border-thin;
        list-style: none;

        @include breakpoint(tablet) {
            display: block;
        }

        a {
            @include rem(padding, 9px 12px);
            display: inline-block;
            color: $black;
            transition: all linear 0.15s;

            &:hover {
                text-decoration: none;
            }

            &.button--default {
                @include rem(margin, 0);
                float: right;
                color: $white;
            }
        }
        
        li {
            float: left;

            &:first-of-type a {
                padding-left: 0;
            }
        }

        li.active a,
        li.active a:hover {
            background: $white;
            color: $light-grey;
        }

        &:after {
            display: block;
            clear: both;
            content: '';
        }
    }

    .tab-content {
        clear: both;

        > .tab {
            display: none;

            &.active {
                @include rem(padding, $medium-padding 0);
                display: block;
            }
        }

        .gallery {
            @include rem(margin, 20px auto);

            @include breakpoint(tablet) {
                @include rem(margin, 40px auto);
            }
		}
    }

    &--mobile {
        display: block;

        .module__dropdown {
            @include rem(margin-top, 0);
            @include rem(margin-right, 30px);
            width: 55%;
            float: left;

            .fs-dropdown-selected {
                @include rem(margin-bottom, 11px);
                @include rem(padding-bottom, $small-padding);
            }
        }

        .button {
            @include rem(margin-top, 0);
            @include rem(margin-right, 0);
            @include rem(padding, 5px 12px);
            width: 33%;
            float: right;
        }

        + .tab-content {
            border-top: solid $light-grey $border-thin;

            @include breakpoint(tablet) {
                border-top: none;
            }
        }

        @include breakpoint(tablet) {
            display: none;
        }
    }

    &--v2 {
        .section {
            @include rem(margin, 25px auto );

            &--bordered-bottom {
                .container {
                    @include rem(padding-bottom, 30px);
                }
            }
        }
        .tab-links {
            @include rem(padding-bottom, 0);
            border-bottom: none;

            li {
                @include rem(margin-right, 32px);

                &.active {
                    display: block;
                    position: relative;
                }
            }

            li a {
                @include rem(padding, 9px 0);
            }

            .tab--active {
                display: block;
                height: 6px;
                background: $mid-grey;
                position: absolute;
                bottom: -6px;
                width: 100%;
            }
        }

        .tabs--mobile {
            .module__dropdown {
                width: 100%;
            }
        }

        .tab-content {
            @include rem(padding, $small-padding 0 35px);
            border-top: solid $light-grey $border-thin;
            background-color: $megamenu;
        }

        .dimension {
            display: block;
        }

        .product-colours {
            width: 100%;

            @include breakpoint(desktop-big) {
                width: 90%;
            }
            .colour {
                @include rem(margin, 0 5px 5px 0);
                display: inline-block;
                width: 65px;
                height: 65px;
                backface-visibility: hidden;
                transform-style: preserve-3d;
                transform: rotateY(0deg);
                z-index:index($elements, flap-panel);
                @include transition(all .3s ease-out);
            

                &:hover {
                    cursor: pointer;
                    transform: rotateX(5deg);

                    -webkit-box-shadow: 0 4px 4px rgba(151, 151, 151, 1),
                                        0 8px 8px rgba(151, 151, 151, 1),
                                        0 16px 16px rgba(151, 151, 151, 1), 
                                        0 32px 32px rgba(0, 0, 0, .15), 
                                        0 64px 64px rgba(0, 0, 0, .15);

                    -moz-box-shadow:    0 4px 4px rgba(151, 151, 151, 1),
                                        0 8px 8px rgba(151, 151, 151, 1),
                                        0 16px 16px rgba(151, 151, 151, 1), 
                                        0 32px 32px rgba(0, 0, 0, .15), 
                                        0 64px 64px rgba(0, 0, 0, .15);
		                			
		            box-shadow: 	0 4px 4px rgba(151, 151, 151, 1),
		                			0 8px 8px rgba(151, 151, 151, 1),
		                			0 16px 16px rgba(151, 151, 151, 1), 
		                			0 32px 32px rgba(0, 0, 0, .15), 
		                			0 64px 64px rgba(0, 0, 0, .15);
                }
            }
        }

        .category-image {
            position: relative;
            display: none;

            img {
                @include responsiveImage();
                width: 100%;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: {
                    color: $white;
                    size: cover;
                }
                opacity: 0.6;
            }
        }

        .section__content {
            h4 {
                @include rem(margin-bottom, $small-margin);
                color: $dark-blue;
            }

            p.last,
            p:last-of-type {
                @include rem(margin-bottom, 0);
            }
        }
    }
}