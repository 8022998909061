.main-carousel {
	position: relative;
	width: calc(100% - 25px);
	margin: 0 auto;

	@include breakpoint(desktop) {
		width: 100%;
		margin: auto;
	}

	a {
		&:hover {
			.main-carousel__image img {
				@include opacity(.3);
			}
		}
	}
	&.owl-loaded {
		@include breakpoint(desktop) {
			@include rem(padding, 0 14px);
		}
	}
	&__container {
		position: relative;

		.main-carousel__image {
			margin-bottom: 0;
		}
		.main-carousel__search {
			color:$white;
			position:absolute;
			width:100%;
			height:100%;
			top:0;
			left:0;
			@include opacity(0);
		}
		.main-carousel__caption {
			position: relative;
			color: $black;
		}

		&:hover {
			@include breakpoint(tablet){
				.main-carousel__overlay {
					@include transition(opacity, 0.3s);
					@include opacity(0.5);
				}
				.main-carousel__search {
					@include transition(opacity, 0.4s);
					@include opacity(1);
				}
			}
		}
	}
	&__image {
		@include rem(margin-bottom, 7px);
	
	}
	&__caption {
		@include rem(margin-top, 8px);
		@include rem(font-size, 15px);
		color:$black;
	}
	&__overlay {
		top:0;
		left:0;
		width:100%;
		height: 100%;
		background: $black;
		position: absolute;
		@include opacity(0);
        &__short {
            height:90%;
            }
	}

	// Owl
	.owl-nav {
		width:100%;
		position: absolute;
		margin: auto;
  		top: 0; 
  		left: 0; 
  		bottom: 0; 
		right: 0;
		pointer-events: none;
		display: none;
  		@include rem(height, 50px);

		@include breakpoint(tablet) {
			@include rem(height, 75px);
			display: block;
		}	

	}
	.owl-next, .owl-prev {
		position: absolute;
		pointer-events: all;
		&:before {
			color:$white;
			@include fontSize(carouselArrows);
		}
		&:hover {
			&:before {
				@include fontSize(carouselArrows);
			}
		}
	}
	.owl-prev {
		left:1.5%;
		&:before {
			color:$white;
			@extend .icon-link-left--default:before;
		}
		&:hover {
			&:before {
				@extend .icon-link-left--hover:before;
			}
		}
	}
	.owl-next {
		right:2.5%;
		&:before {
			color:$white;
			@extend .icon-link-right--default:before;
		}
		&:hover {
			&:before {
				@extend .icon-link-right--hover:before;
			}
		}
	}
	
}