.section--sign-up {
	@include rem(margin-top, 0);
	@include rem(margin-bottom, 0);
	background: $orange;
	color: $white;
	display: table;
	width:100%;
	@include rem(padding, 24px 0);

	&__message {
		text-align: center;
		@include fontSize(signUpTitle);
		@include rem(margin, 0 0 0);

		@include breakpoint(tablet) {
			@include rem(margin, 0 0 0);
		}
		@include breakpoint(desktop) {
			text-align: left;
			margin: 0;
		}
	}

	.form {
		&__row {
			margin:0;
		}
		&__text-input {
			width: 100%;
			background: transparent;
			border: none;
			border-radius:0;
			border-bottom:$border-thin solid $white;
			line-height: 1.5;
			@include rem(padding, 10px 0);
			@include rem(margin, 25px 0 30px 0);
			@include placeholder {
				color:$white;
			}
			@include breakpoint(tablet) {
				@include rem(height, 48px);
				@include rem(margin, 8px 0 1px 0);
			}
			@include breakpoint(desktop) {
				@include rem(margin-top, 0);
			}

			& + .parsley-errors-list {
				@include rem(margin-top, -25px);

				@include breakpoint(tablet) {
					@include rem(margin-top, 0);
				}
			}
		}
		&__input-wrapper {
			@include rem(margin-bottom, 0);

			@include breakpoint(tablet) {
				@include rem(margin-bottom, $medium-margin);
			}
		}
		&__submit-input {
			color:$white;
			width:100%;
			border-radius:0;
			background: transparent;
			@include rem(padding, 10px 0);
			@include rem(margin, 10px 0);
			border:$border-thin solid $white;
			@include breakpoint(tablet) {
				margin: 10px 0 0 0;
			}
			@include breakpoint(desktop) {
				@include rem(margin-top, 0);
			}
			&:hover {
				color:$orange;
				background: $white;
			}
		}

		.fs-dropdown-selected {
			@include rem(margin-top, 35px);

			@include breakpoint(tablet) {
				@include rem(margin-top, 6px);
			}
		}

		.three--columns:first-of-type {
			.fs-dropdown-selected {
				@include rem(margin-top, 15px);

				@include breakpoint(tablet) {
					@include rem(margin-top, 6px);
				}
			}
		}

		.checkbox {
			@include checkbox;
			@include rem(margin-bottom, 23px);

			@include breakpoint(tablet) {
				@include rem(margin-bottom, 0);
			}

			.parsley-error {
				color: $red!important;
				text-shadow: 0px 0px 0px $red;
				a {
					border-bottom: 1px solid $red;
				}

				label,
				a,
				a:hover,
				[type="checkbox"] + label:before {
					color: $red!important;
				}
			}

			label,
			a,
			a:hover {
				color: $white;
			}

			a {
				border-bottom: 1px solid $white;
			}
		}
	}


	// Grid modifiers 
	&__info {
		width:100%;
	}

	&__form {
		@include rem(margin, 5px 0 0!important);

		@include breakpoint(tablet) {
			@include rem(margin, 25px 0 0!important);
		}

		width: 100%;

		.three--columns {
			@extend .grid__cell;
			@include breakpoint(tablet) {
				@extend .unit-6-12--tablet;
			}
			@include breakpoint(desktop) {
				@extend .unit-3-12--desktop;
			}
		}

		.four--columns {
			@extend .grid__cell;
            @include breakpoint(tablet) {
                @extend .unit-10-12--tablet;
			}
			@include breakpoint(desktop) {
				@extend .unit-4-12--desktop;
			}
		}
	}
}