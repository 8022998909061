.gallery {
  @include rem(margin, $x-large-margin auto);
  overflow: hidden;

  &__title {
    @include rem(margin, 0 0 $small-margin);
  }

  &__error-msg {
    @include fontSize(sectionTitle);
  }

  &__filters {
    .filter {

      @extend .list-inline;
      @extend .list-reset;

      //float:left;

      &__item {
        display: block !important;
        @include rem(margin-left, 75px);
        @include breakpoint(tablet) {
          @include rem(margin-left, $small-margin);
          width: auto;
          float: left;
        }
      }

      &__helper,
      &__category {
        //display:block;
        width: auto;
      }

      &__helper {
        float: left;
        width: 75px;
        vertical-align: top;
        display: block !important;
        @include rem(margin-bottom, $small-margin);

        @include breakpoint(tablet) {
          margin-right: 0;
        }
      }

      @include breakpoint(tablet) {
        float: right;

        &__helper,
        &__category {
          display: inline-block;
          width: auto;
        }

        &__helper {
          float: left;
          margin-bottom: 0;
        }
      }

      .fs-dropdown-selected {
        color: $black;
        border-bottom: 1px solid $orange;
        @include rem(margin, 0 0 $medium-margin);

        &:before {
          color: $orange;
        }
      }

      .fs-dropdown-item {
        color: $orange;
      }

      .section__header {
        @include rem(margin, 0 0 $medium-margin);
      }
    }
  }

  $gallery-width: 1130px;

  .gutter-sizer {
    width: $gutter;
    @include breakpoint(phablet) {
      width: percentage($gutter / $gallery-width);
    }
  }

  &__thumbnail {
    width: 48%;
    @include rem(height, 160px);
    @include rem(margin-bottom, $gutter);

    @include breakpoint(phablet) {
      @include rem(height, 220px);
      width: percentage(648px / $gallery-width);
    }

    @include breakpoint(tablet) {
      @include rem(height, 300px);
    }

    @include breakpoint(desktop) {
      @include rem(height, 367px);
    }

    &--width-2 {
      @include breakpoint(phablet) {
        width: percentage(468px / $gallery-width);
      }
    }

    &--width-3 {
      @include breakpoint(phablet) {
        width: percentage(367px / $gallery-width);
      }
    }

    &__image {
      width: 100%;
      height: 100%;

      img {
        display: none;
      }
    }

    &__overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      position: absolute;
      @include opacity(0);

    }

    &__caption {
      top: 0;

      span {
        position: absolute;
        bottom: $gutter;
        left: $gutter;
        right: $gutter;
      }

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        color: $white;
        width: 100%;
        height: 100%;
        @include rem(font-size, 40px);
        @include rem(height, 40px);
        //@extend .icon-search--default:before;
      }

    }

    &__download,
    &__caption {
      color: $white;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      @include opacity(0);
    }

    &__download {

      z-index: -99;

      .lightbox__close {
        @include rem(top, 20px);
        @include rem(right, 20px);

        &:before {
          color: $white;
        }

        display: block;

        @include breakpoint(tablet) {
          display: none;
        }
      }

      .search {
        @include rem(top, 20px);
        @include rem(right, 20px);

        span {
          &:before {
            color: $white;
          }

          &:hover {
            &:before {
              color: $orange;
            }
          }
        }

        display: none;

        @include breakpoint(tablet) {
          display: block;
        }
      }

      .zoom {
        @include rem(top, 20px);
        @include rem(right, 20px);
        line-height: 0;

        span {
          svg {
            width: 26px;
            height: 26px;

            path {
              fill: $white;
            }
          }

          &:hover {
            svg {
              path {
                fill: $orange;
              }
            }
          }
        }

        display: none;

        @include breakpoint(tablet) {
          display: block;
        }
      }

      .resolution {

        &__option {
          @include rem(margin-bottom, 18px);
          display: inline-block;
          cursor: pointer;

          &.active {
            color: $orange;

            .resolution__low-res,
            .resolution__high-res {
              border: 1px dashed $orange;
            }

            .resolution__high-res {
              background-color: $orange;
            }

            .resolution__low-res:before {
              background-color: $orange;
            }

            .download__icon{
              &:before {
                color: $orange;
              }

            }
          }

          &:hover {
            color: $orange;

            & .resolution__low-res {
              border: 1px dashed $orange;

              &:before {
                background-color: $orange;
              }
            }

            & .resolution__high-res {
              background-color: $orange;
            }

            .download__icon{
              &:before {
                color: $orange;
              }
            }
          }
        }

        &__low-res {
          background-color: transparent;
          border: 1px dashed $white;

          &:before {
            content: "";
            @include rem(width, 14px);
            @include rem(height, 7px);
            background-color: $white;
            position: absolute;
          }
        }

        &__high-res {
          background-color: $white;
        }

        &__low-res,
        &__high-res {
          @include rem(width, 28px);
          @include rem(height, 14px);
          @include rem(margin-right, 15px);
          display: inline-block;
          position: relative;
        }

        &__footer {
          position: absolute;
          @include rem(bottom, 20px);
          @include rem(left, 20px);
          @include rem(right, 20px);
          display: none;
          @include transition(all .3s ease);
          @include breakpoint(tablet) {
            @include rem(bottom, 30px);
            @include rem(left, 30px);
          }

          &--show {
            @include transition(all .3s ease);
            display: block;
          }
        }
      }

      .download__icon{
        vertical-align: middle;
        margin-right: 6px;
        &:before {
          color:$white;
          @extend .icon-download--default:before;
        }
      }

      .checkbox {
        @include checkbox;
        @include rem(margin-bottom, 15px);

        &__item--white {
          [type="checkbox"]:not(:checked) + label,
          [type="checkbox"]:checked + label {
            @include rem(margin-right, 10px);
          }
        }

        label {
          @include rem(font-size, 15px);
        }

        a {
          color: $white;
          text-decoration: underline;
        }

        &.invalid {
          label, a {
            color: $orange;
          }
        }

        @include breakpoint(tablet) {
          @include rem(margin-bottom, 25px);
        }
      }

      .button {
        @include rem(padding, 5px 20px);
        width: 100%;
        margin-top: 0;

        @include breakpoint(tablet) {
          @include rem(padding, 10px 20px);
          width: auto;
        }
      }



      @include rem(padding, 30px 20px 20px 20px);
      top: 0;

      @include breakpoint(tablet) {
        @include rem(padding, 30px);
        z-index: unset;
      }
    }

    &:hover {
      @include breakpoint(tablet) {
        .gallery__thumbnail__overlay {
          @include transition(opacity, 0.3s);
          @include opacity(0.5);
        }
        .gallery__thumbnail__caption,
        .gallery__thumbnail__download {
          @include transition(opacity, 0.4s);
          @include opacity(1);
        }
      }
    }
  }

  li {
    @media(max-width: 767px) {
      .gallery__thumbnail {
        @include rem(height, 88px);
        @include rem(margin-bottom, $small-margin);
      }
      &:nth-of-type(odd) {
        .gallery__thumbnail {
          width: percentage(620px / $gallery-width);
        }
      }
      &:nth-of-type(even) {
        .gallery__thumbnail {
          width: percentage(455px / $gallery-width);
        }
      }
      &:nth-of-type(3) {
        .gallery__thumbnail {
          width: percentage(725px / $gallery-width);
        }
      }
      &:nth-of-type(4) {
        .gallery__thumbnail {
          width: percentage(350px / $gallery-width);
        }
      }
      &:nth-of-type(7) {
        .gallery__thumbnail {
          width: percentage(460px / $gallery-width);
        }
      }
      &:nth-of-type(8) {
        .gallery__thumbnail {
          width: percentage(600px / $gallery-width);
        }
      }
    }
  }

  .masonry {
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    &-sizer,
    &-item {
      width: 100%;

      @include breakpoint(phablet) {
        width: calc((100% - 28px) / 2);
      }

      @include breakpoint(tablet) {
        width: calc((100% - 28px) / 3);
      }
    }

    &-item {
      float: left;
      margin-bottom: 14px;
      overflow: hidden;

      .gallery__thumbnail {
        width: 100%;
        margin-bottom: 0;
        position: relative;
        overflow: hidden;

        @include rem(height, 207px);

        @include breakpoint(phablet) {
          @include rem(height, 277px);
        }

        &--height2 {
          @include rem(height, 372px);
          @include breakpoint(phablet) {
            height: 490px;
          }
        }
      }
    }
  }
}

