.configurator-module {
	margin: 0 auto 2rem;

	ol {
		li {
			font-size: 14px;
			margin-bottom: 20px;

			img {
				margin-bottom: 10px;
				height: 18px;
			}
		}
	}
}

@media screen and (min-width: 600px) {
	.configurator-module {
		ol {
			li {
				width: 18%;
				float: left;
				margin-bottom: auto;
				margin-left: 2.5%;

				&:first-child {
					margin-left: 0;
				}
			}

			img {
				margin: 0 auto 10px auto;
				padding-right: 5%;
			}
		}
	}
}