.text-module {
    @include rem(margin, 50px auto);

    @include breakpoint(desktop) {
        @include rem(margin, 20px auto 0 auto);
    }
}

.text-module + .carousel.owl-carousel {
    .secondary-carousel__container,
    .primary-carousel__container {
        margin-top: 0;
    }
}
