.tiles-module {
    .module {
        &__title, &__grid {
            text-align: center;
        }

        &__grid {
            text-align: center;
            @include rem(padding, 0);
            @include breakpoint(desktop) {
                @include rem(padding, 0 $tiny-padding);
           }
        }
        &__image {
            max-height: 270px;
            overflow: hidden;

            img {
                display: none;
                width: 100%;
                height: 100%;
                @include breakpoint(tablet) {
                    display: block;
                    width: auto;
                }
            }
        }
        &__title {
            &__sub-heading {
                @include rem(margin, $medium-margin 0 $tiny-margin);
                color: $orange;
                &:hover {
                    color:$light-grey;
                }
            }
        }
        &__copy {
            @include rem(padding, 0 $tiny-padding);
            @include breakpoint(tablet) {
                @include rem(padding, 0 $x-large-padding);
            }
        }
    }
}