// Lists
.list-reset {
	@include list-reset;
	@include clearfix();
}
.list-inline {
	@include list-reset;
	li {
		display: inline-block
	}
}
.list--arrow-right {
	@include list-reset;
	li {
		@include rem(margin, 0 0 $small-margin);
		span:before{
			@extend .icon-arrow-down--default;
			display:inline-block;
			transform: rotate(-90deg);
			@include rem(font-size, 13px);
			@include rem(margin-left, $tiny-margin);
			
		}
		a:hover,
		a:hover span:before {
			color: $light-grey;
		}
	}
}
.navigation-list {
	@include navigation-list;
}
// Floats 
.float-left {
	float:left;
}
.float-right {
	float:right;
}

.clear {
	clear: both;
}
//Text-align
.align-left {
	text-align: left!important;
}

.align-center {
	text-align: center!important;
}

.align-right {
	text-align: right!important;
}
//Responsive-image
.responsive-image {
	@include responsiveImage();
}

// Margin
.no-margin {
	margin: 0!important;
}



// Visibility {
	.hide {
		display: none!important;
	}
// .show-mobile-only {
// 	display:block!important;
// 	@include breakpoint(tablet) {
// 		display:none!important;
// 	}
// }
// .show-tablet-only {
// 	display:none!important;
// 	@include breakpoint(tablet) {
// 		display:block!important;
// 	}
// 	@include breakpoint(desktop) {
// 		display:none!important;
// 	}
// }
// .show-desktop-only {
// 	display:none!important;
// 	@include breakpoint(desktop) {
// 		display:block!important;
// 	}
// }
 .hide-mobile {
 	display: none!important;
 	@include breakpoint(tablet) {
		display: inline-block!important;
	}
 }

 .hide-section-mobile {
	display: none!important;
	@include breakpoint(tablet) {
	   display: block!important;
   }
}

.hide-section-desktop {
	display: block!important;
	@include breakpoint(tablet) {
		display: none!important;
   }
}

.hide-section {
	display: none!important;
}

.hide-split-banner{
	display: none!important;
}
// .hide-tablet {
// 	display:block!important;
// 	@include breakpoint(tablet) {
// 		display:none!important;
// 	}
// 	@include breakpoint(desktop) {
// 		display:block!important;
// 	}
// }
// .hide-desktop {
// 	display:inline-block!important;
// 	@include breakpoint(desktop) {
// 		display:none!important;
// 	}
// }


