.order-summary {
    overflow: hidden;
    margin: 0;

    &__totals {
        @include rem(padding, 24px 0 10px 0);

        @include breakpoint(tablet) {
            @include rem(padding, 34px 0 17px 0);
        }

        .cart__remove-warning-icon {
            @include rem(margin, 0 9px 0 0);
    
            @include breakpoint(tablet) {
                @include rem(margin, 0 12px 0 0);
            }
    
            @include breakpoint(desktop) {
                @include rem(margin, 0);
            }
        }
    }

    &__product {
        border-bottom: none;
        @include rem(padding, 20px 0);

        @include breakpoint(tablet) {
            border-bottom: 1px solid $light-grey;
            @include rem(padding, 20px 0 35px 0);
        }

        &:last-of-type {
            border-bottom: 1px solid $light-grey;

            @include rem(padding, 20px 0 35px 0);

            @include breakpoint(tablet) {
                @include rem(padding, 40px 0 35px 0);
            }
        }
    }

    &__image {
        width: 28%;
    }

    &__details {
        @include rem(padding, 0 7px 0 17px);
        width: 72%;

        @include breakpoint(tablet) {
            @include rem(padding, 0 7px);
        }

        @include breakpoint(desktop) {
            @include rem(padding, 0 7px 0 17px);
        }

        h3 {
            @include rem(font-size, 21px);
            @include rem(margin, 0 0 5px 0);

            @include breakpoint(tablet) {
                @include rem(font-size, 20px);
                @include rem(margin, 0 0 15px 0);
            }
        }

        p {
            @include rem(margin, 0);
            @include fontSize(paragraph);

            @include breakpoint(tablet) {
                @include rem(margin, 0 0 2px 0);
            }

            &.order-summary__price {
                display: none;

                @include breakpoint(tablet) {
                    display: block;
                    @include rem(font-size, 18px);
                    @include rem(margin, 14px 0 0 0);
                }
            }
        }
    }

    &__image,
    &__details {
        @include breakpoint(tablet) {
            width: 50%;
        }
    }

    &__dimensions {
        span {
            white-space: pre;
        }
    }
}