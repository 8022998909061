.section--promo-banner {
	background: $blue;
	border-top: 1px solid $white;
	color: $white;
	margin-bottom: 0;
	display: table;
	width:100%;
	@include rem(padding, 30px 40px);

	.ten--columns{
		@extend .unit-6-12--tablet;
		@include breakpoint(desktop-big) {
	        @extend .unit-10-12--desktop-big;
	    }
	}
	.two--columns{
		@extend .unit-6-12--tablet;
		@include breakpoint(desktop-big) {
	        @extend .unit-2-12--desktop-big;
	    }
	}

	@include breakpoint(desktop) {
		@include rem(padding, 30px);
	}

	.container {
		width: 100%;

		@include breakpoint(desktop) {
			width: calc(100% - 28px);
		}
	}

	&__message {
		text-align:center;
		@include fontSize(signUpTitle);
		@include rem(margin, 0 0 0);
		@include breakpoint(tablet) {
			@include rem(margin, 0 0 0);
		}
		@include breakpoint(tablet) {
			text-align:left;
			margin: 0;
		}
    }

    .button {
        width: 100%;
		text-align: center;
		background-color: transparent;
		border-radius: 0;

		&:hover {
			background-color: $white;
			color: $orange;
		}

        @include breakpoint(tablet) {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
            width: 300px;
			margin: 0;
        }
	}
	
	.promo-banner {
		&__close {
			&--taxi,
			&--keyring {
				display: none;
				position: absolute;
				top: 0;
				right: 0;
				font-size: 3.5rem;
				&:before {
					@extend .icon-close--default:before:before;
					color: $white;
				}
				&:hover {
					cursor: pointer;
				}
				@include breakpoint(desktop) {
					display: none;
				}
			}
		}
	}
}