.sectors-section {
	.section__content {
		@include rem(margin-bottom, $small-margin);
		@include breakpoint(tablet){
			@include rem(margin-bottom, $small-margin);
		}
	}
	.section__list {
		display: none;
		li {
			@include rem(margin-bottom, $tiny-margin);
		}
		@include breakpoint(tablet){
			display: block!important;
			li {
				@include rem(margin-bottom, $small-margin);
			}
		}
	}
	.section__title {
		@include rem(margin, 0 $medium-margin $small-margin 0);
		@include breakpoint(tablet){
			margin-top: 2rem;
		}

		&--row {
			@include breakpoint(tablet){
				margin-bottom: 2rem;
			}
		}
	}
	.section__subtitle {
		margin: 1rem 0;
		&.is-on {
			color: $grey;
			&:after {
				transform: rotate(-180deg);
			}
			@include breakpoint(tablet){
				color: $black;
			}
		}
		&:after {
			@extend .icon-arrow-down--default:before;
			@include rem(margin, $tiny-margin 0);
			float: right;
			@include breakpoint(tablet){
				display: none;
			}
		}
		@include breakpoint(tablet){
			margin: 2rem 0;
		}
	}
	.section__cta {
		display: block;
		margin-top: 0;
		padding-top: 15px;
		border-top: solid $light-grey $border-thin;
		@include breakpoint(tablet){
			display: none;
		}
		.button {
			margin-top: 0;
		}

		&--default {
			display: block;

			.button {
				width: 100%;

				@include breakpoint(tablet) {
					width: auto;
				}
			}
		}
	}
}