.files-download {
	@extend .list-reset;

	@include rem(margin, 20px 0 $small-margin);

	@include breakpoint(tablet) {
		@include rem(margin, 5px 0 $small-margin);
	}

	&__item {
		@include rem(margin, 0 0 $small-margin);
	}

	&__link {
		display: block;
		position: relative;
		@include rem(padding-left, 35px);

		&:before {
			position: absolute;
			left:0;
			top:0;
			@include rem(font-size, 24px);
			@extend .icon-download--default:before;
		}

		&:hover {
			&:before {
				@extend .icon-download--hover;
			}
		}

	}
}