.sidebar {
	@include rem(margin, 0);
	&__header {
		@include rem(margin, 0 0 $medium-margin);
	}
	&__link {
		&:hover,
		&:hover:after {
			color: $light-grey;
		}
		&:after {
			@extend .icon-arrow-down--default:before;
			display:inline-block;
			transform: rotate(-90deg);
			@include rem(font-size, 13px);
			@include rem(margin-left, $tiny-margin);
		}
	}
}
