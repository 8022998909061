.page {
  /* &.cookie {
       margin-top: 222px;

       @include breakpoint(tablet) {
           margin-top: 133px;
       }

       @include breakpoint(desktop) {
           margin-top: 109px;
       }

       @media(min-width:2560px) {
           margin-top: 85px;
       }
   }*/

  margin-top: 120px;
  @include breakpoint(tablet) {
    margin-top: 150px;
  }

  @include breakpoint(desktop) {
    margin-top: 0;
  }
}

.container {
  position: relative;
  width: calc(100% - 28px);
  margin: auto;
  @include clearfix();

  @include breakpoint(tablet) {
    @include rem(max-width, 1130px);
  }
  @include breakpoint(desktop-big) {
    @include rem(max-width, 1228px);
  }

  &--full-width {
    width: 100%;

    @include breakpoint(tablet) {
      @include rem(max-width, 1150px);
    }
  }

  &--smaller-width {
    width: 100%;

    @include breakpoint(desktop) {
      @include rem(padding, $large-padding 0 0);
      max-width: calc(100% - 30rem);
    }

    .eight--columns {
      @extend .grid__cell;

      @include breakpoint(desktop) {
        @extend .unit-8-12--desktop;
      }
    }

    .three--columns {
      float: none;

      @include breakpoint(desktop) {
        float: right;
      }
    }

    .container {
      max-width: 100%;

      @include breakpoint(desktop) {
        width: 100%;
      }
    }
  }
}

.main-content {
  @include rem(padding, $medium-padding 0);

  @include breakpoint(tablet) {
    @include rem(padding, $large-padding 0);
  }

  &--small {
    @include rem(padding, $medium-padding 0);
  }
}

.section + .main-content,
.main-content + .main-content {
  padding-top: 0;
  padding-bottom: 0;

  @include breakpoint(desktop) {
    @include rem(padding-bottom, 40px);
  }

  .main-article {
    padding: 0;
    margin: 0;
  }
}

