.delivery-options,
.review-order-options {
    &__card {
        border: 1px solid $light-grey;
        display: block;
        @include rem(margin, 0  0 20px 0);
        @include rem(padding, 30px);

         @include breakpoint(tablet) {
            margin: 0;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__title {
        margin: 0;
    }

    &__price {
        @include fontSize(tinyTitle);
        @include rem(margin, 0 0 8px 0);
    }

    &__description {
        @include fontSize(paragraph);
    }

    .radioButton {
        margin: 0;

        &__item {
            input[type="radio"] + label {
                &:after {
                    @include rem(height, 10px!important);
                    @include rem(left, 7px!important);
                    @include rem(top, 9px!important);
                    @include rem(width, 10px!important);
                    
                }
    
                &:before {
                    border-radius: 50%;
                    @include rem(height, 22px);
                    @include rem(width, 22px);
                }
            }
        }
    }

    .section {
        &__header {
            @include rem(margin-bottom, 25px);
        }
    }

    & + .divider-module + .module {
        @include rem(margin-top, 10px);

        @include breakpoint(desktop) {
            @include rem(margin-top, 20px);
        }
    }
}

.review-order-options {
    @include rem(margin-top, $large-margin);
    @include rem(margin-bottom, $medium-large-margin);

    .section__header {
        @include rem(margin-bottom, $medium-margin);

        @include breakpoint(desktop) {
            @include rem(margin-bottom, $medium-large-margin);
        }
    }

    &__card {
        @include rem(margin-bottom, $small-margin);
        @include rem(padding, $medium-padding $medium-padding 15px);
        font-weight: 500;

        @include breakpoint(desktop) {
            @include rem(margin-bottom, 15px);
            @include rem(padding, 25px $medium-large-padding);
        }
    }

    .radioButton {
        &__item {
            input[type="radio"] + label {
                @include rem(margin-top, $medium-margin);

                @include breakpoint(desktop) {
                    @include rem(margin-top, 15px);
                }
        
                &:before {
                    top: 0;
                    @include breakpoint(desktop) {
                        top: 2px;
                    }
                }
            }
        }
    }
}