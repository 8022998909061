.side-panel-ctas {
	// Grid modifier

	&--shop {
		.eight--columns {
			.module {
				&__content {
					@include breakpoint(tablet) {
						@include rem(padding, 0 125px 0 0);
					}

					@include breakpoint(desktop) {
						@include rem(padding, 0 290px 0 0);
					}
				}
				&__standout {
					@include fontSize(showroomList);
				}
			}
			@include breakpoint(tablet) {
				left: -33.33333%;
			}
		}

		.four--columns {

			.module	 {
				&__sidebar {
					@include rem(margin, 0 0 30px 0);

					@include breakpoint(tablet) {
						@include rem(margin, 0 0 30px 0);
					}
				}
			}

			@include breakpoint(tablet) {
				left: 66.66666%;
			}
		}

		.eight--columns,
		.four--columns {
			@include breakpoint(tablet) {
				position: relative;
				float: left;
			}
		}

		@include breakpoint(tablet) {
			@include rem(margin, 0 auto 0);
		}
	}
	
	.seven--columns {
	    @include breakpoint(tablet) {
	        @extend .unit-9-12--tablet
	    }
	    @include breakpoint(desktop) {
	        @extend .unit-7-12--desktop
	    }
	}	
}