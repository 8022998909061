.hosted-field {
    border-bottom: 1px solid $orange;
    box-sizing: border-box;
    box-shadow: none;
    color: $form-black;
    display: inline-block;
    @include fontSize(inputLabel);
    @include rem(height, 45px);
    line-height: 20px;
    @include rem(padding, 10px 0 7px 0);
    transition: all 0.15s ease-out 0s;
    width: 100%;

    iframe {
        float: none!important;
    }
    
    &.card-payment {
        &__month,
        &__year {
            display: inline-block;
        }

        &__month {
            @include rem(margin-right, 5px);
            width: 35%;
        }

        &__year {
            width: 55%;
        }
    }

    &.has-value {
        @include rem(margin-top, 0);

    }

    &.has-value + label,
    &.has-value + .hosted-field + label,
    &.has-value + ul + label,
    &.has-value + .hosted-field + ul + label {
        @include fontSize(formLabel);
        color: $grey;	
        padding: 0;
    }

    &.has-value + label,
    &.has-value + .hosted-field + label,
    &.has-value + ul + label,
    &.has-value + .hosted-field + ul + label {
        top: 0; 
    }
}

.hosted-fields--label {
    font-family: courier, monospace;
    text-transform: uppercase;
    font-size: 14px;
    display: block;
    margin-bottom: 6px;
}

.braintree-hosted-fields {
    &-focused {
        border-bottom: 1px solid $orange;
        margin-top: 0;

        & + label,
        & + .hosted-field + label,
        & + ul + label,
        & + .hosted-field + ul + label {
			@include fontSize(formLabel);
            color: $grey!important;	 
            padding: 0;
            top: 0!important; 
        }

        &.has-value + ul + label,
        &.has-value + .hosted-field + ul + label,
        &.has-value + label,
        &.has-value + .hosted-field + label {
            top: 0!important;
        }
        
    }

    &-invalid {
    }

    &-valid {
    }
}

#cardForm {
    margin: 0;
    padding: 0;
}