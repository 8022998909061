.slider-video{
   &__container {
       position: relative;
       overflow: hidden;
   }

   &__content {
       &:first-of-type .slider-video__panel {
           max-width: 100%;
       }
        &--resize {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            overflow: hidden;
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden; 
            width: 50%;
        }
    }

    &__handle {
        @include rem(width, 32px);
        @include rem(height, 32px);
        @include rem(margin-top, -16px);
        @include rem(margin-left, -16px);
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer; 
        &:before {
            content: '';
            background: url('../../img/slider-video/separator-control.svg');
            background-size: 100%;
            position: absolute;
            @include rem(width, 46px);
            @include rem(height, 46px);
            @include rem(left, -7px);

            @media screen and (min-width: 600px){
                @include rem(width, 56px);
                @include rem(height, 56px);
                @include rem(left, -12px);
            }            
        }
    }

    &__seperator {
        width: 2px;
        height: 100%;
        position: absolute;
        right: 50%;
        top: 0;
        z-index: 1;
        background: $white;
        cursor: pointer;           
    }

    .range-slider {
        input[type=range] {
            width: 100%;
        }
        .slider-track {
            &:before {
                left: 0;
                width: 100%;
                border-top: $border-thin solid #000000;
            }
        }
    }
}