.language-navigation--mobile {
    z-index: 9999999;
    .main-navigation__submenu__header {
        @include rem(margin, 0);
        display: inline-block;

        img {
            @include rem(margin, 5px 0);
            float: right;
            width: 25px;
        }
    }

    .main-navigation__link {
        width: 100%;

        &.has-children--mobile {
            @include rem(padding-bottom, 20px);
            border-bottom: 1px solid $grey;
            
            &:after {
                @extend .icon-arrow-down--default:before;
                position: absolute;
                top: 5px;
                right: 0;
                color: $black;
            }

            &.is-on {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        &:focus {
            opacity: .5;
        }
    }
}
