.section {
	@include clearfix;
	@include rem(margin, $small-margin auto);

	&__title {
		@include fontSize(sectionTitle);
	}
	&__subtitle {
		@extend .small-title;
	}
	&__cta {
		@include rem(margin, $medium-margin 0);

		.button {
			width: 100%;
		}
	}
	&__link {
		display: block;
		float: right;
		margin: 2.5rem 0;
		font-size: 1.6rem;

		&:after {
			@extend .icon-arrow-down--default:before;
			display:inline-block;
			transform: rotate(-90deg);
			@include rem(font-size, 13px);
			@include rem(margin-left, $tiny-margin);
		}
		&:hover,
		&:hover:after {
			color: lightgray;
		}
	}

    @include breakpoint(tablet){
       	@include rem(margin, $medium-margin auto);
    }
    @include breakpoint(desktop){
       	@include rem(margin, $large-margin auto);
    }

	@include breakpoint(desktop-big) {
		width:100%;
		@include rem(margin, $x-large-margin auto);
	}

	&--bordered-top {
		.container {
			@include rem(padding-top, $large-padding);
			border-top: solid $border-thin $light-grey;
		}
	}

	&--bordered-bottom {
		.container {
			@include rem(padding-bottom, $large-padding);
			border-bottom: solid $border-thin $light-grey;
		}
	}

	&--no-margin {
		@include rem(margin, 0);
	}

	&--mobile-hide {
		display: none;
		@include breakpoint(tablet) {
			display: block;
		}
	}
}