.order-keyring {
    display: none;

    .module {
        &__header {
            width: 100%;
            margin: 0 auto;

            @include breakpoint(tablet) {
                width: calc(100% - 5rem);
            }

            @include breakpoint(desktop) {
                width: calc(100% - 20rem);
            }

            @include breakpoint(desktop-big) {
                width: calc(100% - 50rem);
            }
        }
        &__content img {
            @include rem(margin, $large-margin 0);
            @include responsiveImage();
        }
    }

    .form {
        @include rem(margin-top, $medium-margin);

        .two--columns {
            @extend .grid__cell;

            @include breakpoint(desktop) {
                @extend .unit-3-12--desktop;
            }
            @include breakpoint(desktop-big) {
                @extend .unit-2-12--desktop-big;
            }
        }
        .six--columns {
            @extend .grid__cell;
            @include breakpoint(desktop) {
                @extend .unit-6-12--desktop;
            }
        }
        &__input-wrapper {
            @include rem(margin-bottom, $medium-margin);
        }
        &__postcode-wrapper {
            @include rem(margin-bottom, 20px);
            max-height: 164px;
            border-top:$border-thin solid $light-grey;
            border-bottom:$border-thin solid $light-grey;
        }
        &__submit-input {
            @include rem(margin-top, $medium-margin);
        }
        &__item {
            @include rem(margin-bottom, $tiny-margin);
            display: block;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .checkbox {
        @include checkbox;
        label {
            display: block;
        }
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before,
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
            top: 4px;
        }
    }
}