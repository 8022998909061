.brochure-section {

    label {
        letter-spacing: normal;
        -webkit-tap-highlight-color:transparent;

        &:hover {
            cursor: pointer;
        }
    }

    .three--columns {
        width: 50%;

        @include breakpoint(desktop) {
            width: 25%;
        }

    }

    .checkbox {
        margin-top: 0;
    }

    .brochure {
        &__item {
            @include rem(margin-bottom, $medium-large-margin);

            @include breakpoint(desktop) {
                margin-bottom: 0;
            }
        }
        &__image {
            border:$border-thin solid $light-grey;
            @include rem(margin-bottom, 12px);

            @include breakpoint(desktop) {
                @include rem(margin-bottom, 25px);
            }

            label {
                width: 100%;
                display: inline-block;
            }

            img {
                @include rem(margin, 25px auto);
                pointer-events: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -o-user-select: none;
                user-select: none;
                max-width: 90%;
                @include rem(width, 140px);
                @include breakpoint(tablet) {
                    @include rem(max-width, 180px);
                    width: 100%;
                }
                @include breakpoint(desktop) {
                    @include rem(max-width, 140px);
                }
            }
        }
    }
}