//   1. Common Colours
//   2. Gradients
//   3. Links
//   4. Borders
//   5. Margins
//   6. Padding
//   7. Font Stacks
//   8. Font Weights
//   9. Font Sizes
//  10. Line Heights
//  11. Breakpoints
//  12. Z-Indexes
//  13. Forms

/*************************/
/*************************/

// 1. Common Colours
// -----------------

$black: #000;
$off-black: #080808;
$form-black: #333;
$white: #fff;
$off-white: #B2BCC1;
$megamenu: #dfe5e8;
$light-grey: #B2BCC1;
$mid-grey: #b2bcc1;
$breadcrumbs: #f6f7f9;
$grey: #5c666f;
$dark-grey: #5C666F;
$red: #ad2323;
$error-red: #D0021B;
$orange: #e76525;
$yellow: #f0a808;
$blue: #004A86;
$dark-blue: #001e41;
$deep-blue: #232831;
$green: #0f9929;
$dark-green: #006c13;
$footer-bg: $deep-blue;
$box-shadow: #979797;
$copyright: #5C666F;
$banner-bg: #DBDFE0;

// 2. Gradients
// ------------

$black-gradient-start: transparent;
$black-gradient-end: $black;

// 3. Links
// --------

$color-link-default: #E76525;
$color-link-visited: #990000;
$color-link-hover: #E76525;
$color-link-active: #990000;

// 4. Borders
// ----------

$border-thin: 1px;
$border-medium: 2px;
$border-thick: 3px;

// 5. Margins
// ----------

$gutter: 14px;
$default-margin: $gutter;
$tiny-margin: 5px;
$small-margin: 10px;
$medium-margin: 20px;
$medium-large-margin: 30px;
$large-margin: 40px;
$x-large-margin: 60px;
$mega-margin: 80px;

// 6. Padding
// ----------

$default-padding: $gutter;
$tiny-padding: 5px;
$small-padding: 10px;
$medium-padding: 20px;
$medium-large-padding: 30px;
$large-padding: 40px;
$x-large-padding: 60px;
$mega-padding: 80px;

// 7. Font Stacks
// --------------

$font-family-default: "HelveticaNeue-Light",
"Helvetica Neue Light", "Helvetica Neue",
Helvetica, Arial, "Lucida Grande", sans-serif;

// 8. Font Weights
// ---------------

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 900;

// 9. Font Sizes
// -------------

$fontSizes: (
        baseSize: (
                desktop: 18,
                tablet: 16,
                mobile: 16
        ),
        iconSizes: (
                desktop:35,
                tablet: 30,
                mobile: 30
        ),
        bigTitle: (
                desktop: 56,
                tablet: 40,
                mobile: 30
        ),
        mediumTitle: (
                desktop: 40,
                tablet: 36,
                mobile: 25
        ),
        smallTitle: (
                desktop: 24,
                tablet: 22,
                mobile: 16
        ),
        sectionTitle: (
                desktop: 40,
                tablet: 36,
                mobile: 25
        ),
        sidePanelTitle: (
                desktop: 24,
                tablet: 22,
                mobile: 22
        ),
        signUpTitle: (
                desktop: 32,
                tablet: 28,
                mobile: 22
        ),
        tinyTitle: (
                desktop: 20,
                tablet: 18,
                mobile: 18
        ),
        paragraph: (
                desktop: 16,
                tablet: 16,
                mobile: 16
        ),
        smallNav: (
                desktop: 14,
                tablet: 14,
                mobile: 16
        ),
        mediumNav: (
                desktop: 16,
                tablet: 15,
                mobile: 18
        ),
        showroomList: (
                desktop: 24,
                tablet: 22,
                mobile: 18
        ),
        btn: (
                desktop: 18,
                tablet: 17,
                mobile: 16
        ),
        carouselArrows: (
                desktop:64,
                tablet: 48,
                mobile: 32
        ),
        footerSocial: (
                desktop:35,
                tablet: 25,
                mobile: 35
        ),
        footerContactText: (
                desktop: 20,
                tablet: 18,
                mobile: 24
        ),
        hotLinks: (
                desktop:24,
                tablet: 22,
                mobile: 20
        ),
        listArrows: (
                desktop:35,
                tablet: 25,
                mobile: 35
        ),
        totalPrice: (
                desktop:34,
                tablet: 28,
                mobile: 22
        ),
        subtotalPrice: (
                desktop:20,
                tablet: 20,
                mobile: 20
        ),
        productPrice: (
                desktop:34,
                tablet: 28,
                mobile: 22
        ),
        formTitle: (
                desktop: 32,
                tablet: 28,
                mobile: 22
        ),
        formLabel: (
                desktop: 14,
                tablet: 12,
                mobile: 12
        ),
        inputLabel: (
                desktop: 16,
                tablet: 16,
                mobile: 16
        ),
        shopHeading: (
                desktop:34,
                tablet: 28,
                mobile: 27
        ),
        shopPrice: (
                desktop:24,
                tablet: 22,
                mobile: 20
        ),
        configurationText: (
                desktop: 22,
                tablet: 18,
                mobile: 18
        ),
        productTitle: (
                desktop: 24,
                tablet: 24,
                mobile: 24
        ),
        productVAT: (
                desktop: 16,
                tablet: 16,
                mobile: 13
        ),
        cartRemoveMessage: (
                desktop: 16,
                tablet: 16,
                mobile: 16
        ),
        shopVAT: (
                desktop: 16,
                tablet: 16,
                mobile: 14
        ),
        teaserName: (
                desktop: 22,
                tablet: 28,
                mobile: 28
        ),
        teaserDimensions: (
                desktop: 15,
                tablet: 18,
                mobile: 18
        ),
        teaserPrice: (
                desktop: 18,
                tablet: 22,
                mobile: 22
        ),
        shopBreadcrumbs: (
                desktop: 34,
                tablet: 28,
                mobile: 18
        ),
        formSubTitle: (
                desktop: 20,
                tablet: 20,
                mobile: 22
        )
);

// 10. Line Heights
// ----------------

$default-line-height: 1.5;

// 11. Breakpoints
// ---------------

$threshold-minWidth: 320;
$threshold-mobileBig: 480;
$threshold-phablet: 600;
$threshold-tablet: 768;
$threshold-desktop: 960;
$threshold-desktop-content: 1320;
$threshold-desktopBig: 1400;

// 12. Z-Indexes
// -------------

$elements: article,
article-navigation,
scrollUp,
carousel,
teaser,
teaser-content,
specifier-banner,
infoPanel,
infoPanelNav,
infoPanelContent,
infoPanelClose,
mobileNavigation,
navigation,
logo;

// Usage: z-index:index($elements, flap-panel);

