.main-navigation--mobile,
.language-navigation--mobile,
.main-navigation__submenu--mobile-search {
  display: none;
  background: white;
  position: absolute;
  width: 100%;
  border-bottom: solid $light-grey $border-thin;
  @include rem(top, 60px);
  @include rem(font-size, 17px);
  @include rem(padding, 1.1rem $gutter);
  z-index: index($elements, mobileNavigation);

  @include breakpoint(tablet) {
    @include rem(top, 90px);
  }


  li, .main-navigation__submenu__header {
    @include rem(margin, 0 0 $medium-margin);
    display: block;
    width: 100%;

  }

  li a {
    display: block;

    &.has-children--mobile {
      &:after {
        @extend .icon-arrow-down--default:before;
        float: right;
        line-height: 25px;
        color: $black;
      }

      &.is-on:after {
        transform: rotate(180deg);
      }
    }
  }

  .main-navigation__submenu__header,
  .main-navigation__item {
    @include breakpoint(desktop) {
      text-align: center;
    }
  }

  .main-navigation__submenu__header {
    cursor: pointer;
  }

  @include breakpoint(desktop) {
    // Overriding inline js
    display: none !important;
  }

  .main-navigation__submenu {
    @include rem(padding, 0 0 0 $gutter);

    .main-navigation__item {
      &:last-child {
        span {
          margin-bottom: 0;
        }

        @include breakpoint(desktop) {
          .main-navigation__list {
            @include rem(margin, $medium-margin 0 0);
          }
        }

        .has-children--mobile-2 {
          &.is-engaged {
            @include rem(margin, 0 0 $medium-margin);
          }
        }

        .main-navigation--mobile__submenu--2 {
          padding-bottom: 0;
        }

      }
    }
  }

  .main-navigation__list {
    @include rem(padding, $medium-padding 0 0 0);

    .main-navigation--mobile__submenu--2 {
      @include rem(padding, 0 0 $tiny-margin $gutter);
    }

  }

  .has-children--mobile,
  .has-children--mobile-2 {
    &.is-on, &.is-collapsed {
      color: $mid-grey;
    }
  }

}

.main-navigation--mobile {
  z-index: 999;
  @include rem(padding, 0);

  .main-navigation__list {
    @include rem(padding, 0);
  }

  .site-header__selector--mobile {
    @include rem(margin, 0);

    ul {
      float: right;
    }
  }

  .main-navigation__list .main-navigation__item {
    @include rem(margin-bottom, 0);
    @include rem(padding, $gutter);
    border-top: solid $light-grey $border-thin;
  }

  .main-navigation__submenu .main-navigation__item {
    @include rem(padding, $small-margin $gutter 0);
    border-top: none;

    a + a {
      @include rem(padding-top, $small-margin);
    }

    .main-navigation__submenu__header {
      @include rem(margin, 0);
    }

    &.four--columns {
      & > .grid > .grid__cell {
        &:first-child > .main-navigation__list {
          padding-bottom: 0;
        }
      }
    }

    @include breakpoint(desktop) {
      &:first-of-type {
        @include rem(padding-top, $small-padding);
      }
      .main-navigation__list:first-of-type {
        padding-bottom: 1rem;
      }
    }
  }

  .site-header__selector {
    @include rem(padding, $gutter);
    display: block;
    position: relative;
    float: right;
    left: 0;
  }

  .site-header__selector__item {
    width: auto;
  }
}

.main-navigation__submenu--mobile-search {
  & > .grid {
    margin: 0;
    & > .twelve--columns{
      padding: 0;
    }
  }

  display: block !important;
  @include breakpoint(desktop) {
    display: none !important;
  }

  .form {
    &__text-input {
      margin: 0;
      padding: 6px;
      text-align: left;
      border: none;
      border-radius: 4px;

      @include placeholder {
        color: $black;
      }
    }

    &__input-wrapper {
      position: relative;

      /*&:before {
          display: none;
          @include rem(padding-left, 15px);
          @include rem(font-size, 18px);
          @include rem(top, 3px);
          @extend .icon-search--default:before:before;
          vertical-align: middle;
          right: 0;
          position: absolute;

          @include breakpoint(tablet) {
              display: block;
          }
      } */
    }
  }

  .button--search {
    position: absolute;
    width: auto;
    right: 2.6rem;
    top: 1.5rem;
    border: none;
    color: $black;
    z-index: 1;

    &:after {
      @include rem(padding-left, 15px);
      @include rem(font-size, 18px);
      @include rem(top, 0px);
      @extend .icon-search--default:before:before;
      vertical-align: middle;
      right: 0;
      position: absolute;
    }

    &:hover {
      background-color: transparent;
      color: $light-grey;
    }
  }
}
