.payment {
    @include rem(margin, 0 auto 30px auto!important);

    @include breakpoint(tablet) {
        @include rem(margin, 40px auto 60px auto!important);
    }

    & + .module__cart-error {
        

        @include breakpoint(tablet) {
            @include rem(margin-top, -40px);
        }

        @include breakpoint(desktop) {
            @include rem(margin-top, -50px);
        }
    }

    &__sales-partner {
        @include rem(margin, 0 0 37px 0);

        @include breakpoint(tablet) {
            @include rem(margin, 0 0 27px 0);
        }

        p {
            margin: 0;
        }

        .four--columns {
            @include fontSize(paragraph);
            line-height: 1.3;
            padding-left: 0;
        }

        .eight--columns {
            padding: 0;

            @include breakpoint(tablet) {
                @include rem(padding, 0 7px);
            }
        }
    }

    .module {
        &__ctas {

            @include breakpoint(tablet) {
                @include rem(margin-top, 12px);
            }
            
            .button {
                @include rem(padding, 10px);
                @include rem(margin, 0);

                &:first-of-type {
                    @include rem(margin, 0 0 18px 0);

                    @include breakpoint(desktop) {
                        @include rem(margin, 0);
                    }
                }
            }
        }

        &__form {
            &--subtitle {
                @include fontSize(formSubTitle);
                @include rem(margin-bottom, 5px!important);

                @include breakpoint(tablet) {
                    display: inline-block;
                    @include rem(margin-bottom, 0!important);
                }
            }
        }
        
        &__header {
    
            h4 {
                margin: 0;
            }
            span { 
                color: $grey;
            }

            &:first-of-type {
                @include rem(margin, 0 0 10px 0);
            }

            &:nth-of-type(2) {  
                @include rem(margin, 0);

                @include breakpoint(tablet) {
                    @include rem(margin, 30px 0 18px 0);
                }

                @include breakpoint(desktop) {
                    @include rem(margin, 10px 0 18px 0);
                }
            }
        }

        &__dropdown {

            @include breakpoint(tablet) {
                @include rem(margin-top, 0px);
            }
            .fs-dropdown {
                &-selected {
                    @include rem(padding, 2px 50px 14px 0);
                    @include rem(margin, 0 0 10px);
                }
                &-options {
                    @include rem(margin-top, 10px);

                    @include breakpoint(desktop) {
                        @include rem(margin-top, 0);
                    }
                }
                &-item:last-child {
                    @include rem(margin-bottom, 18px);
                }
                &-open {
                    .fs-dropdown{
                        &-item:last-child {
                            @include rem(margin-bottom, 3px);
                        }
                        &-selected {
                            @include rem(margin, 0 0 5px);
                        }
                    }
                }
            }
        }
    }

    .divider-module {
        .container {
            width: 100%;
        }
    }
}