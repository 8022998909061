.confirmation-table {
    @include rem(padding, 15px 0 0 0);

    @include breakpoint(desktop-big) {
        @include rem(padding, $large-padding $small-padding);
    }

    &__contact-details {
        p {
            @include rem(margin-bottom, $medium-margin);

            @include breakpoint(desktop) {
                @include rem(margin-bottom, $medium-large-margin);
            }

            img {
                display: inline;
                @include rem(max-width, 30px);
                @include rem(max-height, 30px);
            }
            span {
                display: inline-block;
                width: 30px;
                @include rem(margin-right, $small-margin);

                @include breakpoint(tablet) {
                    text-align: center;
                }
            }
        }

        a {
            font-weight: 500;
            color: $black;
        }

        .module__header h4 {
            @include rem(font-size, 25px);
        }

        .divider-module {
            @include rem(padding, $medium-padding 0 25px);
            .container {
                width: calc(100% - 15px);
            }
        }

        .three--columns {
            @extend .unit-3-12--tablet;
        }

        &--dark {
            @include rem(margin, 0 7px);
            @include rem(padding, $medium-large-padding 0 0);
            background-color: $breadcrumbs;
        }

        &__address-shipping {
            @include rem(padding, 0);

            @include breakpoint(desktop) {
                @include rem(padding, 0 $medium-padding);
            }
        }
    }
    &__order-summary {
        .cart__total {
            .right--columns {
                @include rem(padding-right, $small-padding);
            }
        }
    }
}