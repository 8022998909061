.module__product-info {
    @include rem(margin-top, 0);
    @include breakpoint(tablet) {
        @include rem(margin-top, $small-margin);
    }

    .ui-tabs-nav {
        @include rem(margin, 0);
        @extend .list-reset;

        @include breakpoint(tablet) {
            @include rem(margin, 35px 30px);
        }
    }

    .ui-tabs-tab {
        @include rem(padding, 13px 0);
        border-bottom: solid $border-thin $light-grey;

        @include breakpoint(tablet) {
            @include rem(padding-top, 0);
            border-bottom: 0;
        }

        a {
            display: block;
        }

        a:after {
            @include rem(margin, 5px);
            @extend .icon-arrow-down--default:before;
            float: right;
            color: $orange;

            @include breakpoint(tablet) {
                content: '';
            }
        }
    }

    .ui-tabs-active {
        a {
            color: $color-link-active;
        }

        a:after {
            color: $color-link-active;
            transform: rotate(180deg);
        }
    }

    .ui-tabs-panel {
        @include rem(margin-top, 0);

        h4 {
            @include rem(margin-top, 20px);
            @include rem(margin-bottom, 12px);

            @include breakpoint(tablet) {
                @include rem(margin-top, 30px);
            }
        }

        .tab-info {
            @include rem(margin-bottom, 30px);

            p {
                @include rem(margin-bottom, 5px);
            }
        }
    }

    + .divider-module {
        display: none;
        
		@include breakpoint(tablet) {
			display: block;
		}
	}
}