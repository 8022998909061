
.lightbox {
  display: none;
  margin: auto;
  position: relative;
  @include rem(max-width, 935px);

  &__close {
    cursor: pointer;
    display: block;
    position: absolute;
    @include rem(right, -3px);
    @include rem(top, -37px);
    @include rem(width, 30px);
    @include rem(height, 30px);

    &:before {
      @extend .icon-close-2--default;
      color: $orange;
      padding-left: 1px;
      @include rem(font-size, 25px);

    }

    &:hover {
      &:before {
        @extend .icon-close--hover;
      }
    }
  }

  &__title {
    @include rem(margin-bottom, 10px);
  }

  &__info {
    .lightbox__copy,
    .share-bar,
    .files-download {
      display: none;
      @include breakpoint(tablet) {
        display: block
      }
    }

    .content-tags {
      margin-top: 0;
      @include breakpoint(tablet) {
        @include rem(margin-top, 10px);
      }
    }
  }

  &__prev, &__next {
    display: block;
    position: absolute;
    @include rem(top, 59px);
    @include rem(font-size, 25px);

    @include breakpoint(tablet) {
      display: none
    }
  }

  &__prev {
    left: 0;

    &:before {
      @extend .icon-link-left--default:before;
    }
  }

  &__next {
    right: 0;

    &:before {
      @extend .icon-link-right--default:before;
    }
  }

}


// Venobox styles
.vbox-container {
  margin: auto;
  max-width: 90vw;
  overflow-x: initial;
  overflow-y: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  //@include rem(max-width, 935px);
  img{
    max-width: 100%;
    width: auto!important;
    max-height: 90vh!important;
  }
}
.vbox-content{
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0!important;
  padding: 0;
}

.venobox {
  color: $black;

  &:hover {
    color: $black;
  }
}

.vbox-title {
  display: none !important
}

.vbox-close {
  border-radius: 50% !important;
  top: 20px !important;
  right: 20px !important;
  width: 30px !important;
  height: 30px !important;
  padding: 6px 6px 11px !important;
  display: flex !important;
  font-size: 22px !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: transparent !important;
  border: 2px solid #e76525 !important;
  color: #e76525 !important;

  &:hover {
    background-color: #e76525 !important;
    color: #FFFFFF !important;
  }
}

.vbox-prev,
.vbox-next {
  top: 50%;
  @include rem(font-size, 35px);

  span {
    display: none;
  }

  visibility: hidden;
  @include breakpoint(tablet) {
    visibility: visible;
  }

}

.vbox-next {
  &:before {
    @extend .icon-link-right--default:before;
    font-size: 50px;
  }
}

.vbox-prev {
  @include rem(left, 23px);

  &:before {
    @extend .icon-link-left--default:before;
    font-size: 50px;
  }
}
