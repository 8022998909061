.shop-sidebar {
    border-bottom: 1px solid $orange;

    @include breakpoint(tablet) {
        border-bottom: none;
    }

    &__toggle {
        cursor: pointer;
        overflow: hidden;
    }

    &__mobile-total {
        float: right;
        @include rem(padding, 0 40px 0 0);
        position: relative;
        text-align: right;

        &:after {
            @extend .icon-arrow-down--default:before;
            display: inline-block;
            @include rem(font-size, 25px);
            position: absolute;
            right: 0;
            @include rem(top, 16px);

            @include breakpoint(tablet) {
                content: '';
            }
        }

        p {
            &:first-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__status {
        color: $grey;
        @include rem(font-size, 18px);
        float: left;
        @include rem(margin, 13px 0 0 0);
    }

    &__dropdown {
        display: none;

        @include breakpoint(tablet) {
            display: block!important;
        }
    }

    .cart {
        &__total {
            .six--columns:last-of-type p {

                @include breakpoint(desktop) {
                    @include rem(margin, 0 0 0 10px);
                }
            }
        }
    }

    &.open {
        .shop-sidebar {
            &__mobile-total {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .divider-module {
        .container {
            width: 100%;
        }
    }
}