.section--specifiers-banner {
  margin: 0;
  background: #FFFFFF;
  color: $black;
  position: relative;
  //z-index: 9999;

  @include breakpoint(tablet) {
    position: relative;
    //z-index: index($elements, specifier-banner);
  }

  a {
    color: $black;
  }

  .section {
    &__close {
      position: absolute;
      top: 10px;
      right: 10px;

      &:before {
        @extend .icon-close-2--default:before;
        display: inline-block;
        @include rem(font-size, 25px);
        @include rem(margin-left, $tiny-margin);
      }
    }

    &__mobile__content {
      display: block;
      margin: 0;
      width: 100%;
      color: $black;

      @include breakpoint(tablet) {
        display: none;
      }

      .section {
        &__title {
          margin: 0 auto;
          padding: 12px 32px;
          background: black;
          color: white;

          &--bold {
            font-weight: bold;
          }

          span {
            font-weight: bold;
          }
        }
      }
    }

    &__header {
      @include rem(padding, 35px);
      @include breakpoint(tablets) {
        @include rem(padding, 70px);
      }
      @include breakpoint(desktop) {
        @include rem(padding, $x-large-padding $large-padding);
      }
      @include breakpoint(desktop-big) {
        @include rem(padding, $x-large-padding $mega-padding);
      }

      &--home {
        background-color: $yellow;

        .section__title__arrow:after {
          color: inherit !important;
        }
      }

      &--business {
        background-color: $black;
        color: $white;

        .section__title__arrow:after {
          color: inherit !important;
        }
      }

      &--default {
        .section__title__arrow:after {
          color: $black;
        }
      }
    }

    &__title {
      @include rem(margin, 0 0 $tiny-margin);

      &__arrow {
        @include rem(padding-right, 49px);

        &:after {
          @extend .icon-arrow-down--default:before;
          display: inline-block;
          @include rem(margin-right, -49px);
          @include transform(rotate(-90deg));
          vertical-align: middle;
          @include rem(margin-left, $small-margin);
        }
      }
    }

    &__copy {
      @include rem(margin-right, $medium-margin);
    }

    &__image {
      overflow: hidden;

      img {
        display: none;
        width: 100%;

        @include breakpoint(tablet) {

          visibility: hidden;
        }
      }
    }

    &__content {
      display: none;
      @include breakpoint(tablet) {
        display: block;
      }

      @include breakpoint(desktop) {
        .section__title {
          @include rem(font-size, 36px);
        }
      }
    }
  }

  &--shop {
    z-index: 1;

    .section {
      &__mobile__content {
        position: relative;
      }

      &__header {
        &--home {
          background-color: $green;
          color: $white;

          .section__title__arrow:after {
            color: $white;
          }
        }
      }

      &__title {
        @include rem(font-size, 22px);
      }
    }
  }

  .section__flex {
    height: calc(100vh - 183px);
    display: flex;
    flex-direction: column;
    margin: -2px 0;

    .item__flex {
      flex: 0 0 50%;
      padding: 2px 0;
    }
  }

  .specifiers-link {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  .specifiers-mobile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .section__header-mobile {
    position: relative;
    z-index: 1;
  }
}


