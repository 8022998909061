.breadcrumb {
    @include rem(padding, 8px 15px);
    list-style: none;
    background-color: $breadcrumbs;
    margin-bottom: 0;

    @include breakpoint(tablet){
        @include rem(padding, 8px 30px);
    }

    li {
        display: inline;
        font-size: 1.6rem;
        color: $black;
        line-height: 2.2;

        @include breakpoint(tablet){
            color: $light-grey;
            @include rem(font-size, 18px);
        }

        a {
            color: $light-grey;
            text-decoration: none;

            @include breakpoint(tablet){
                color: $black;
            }
        }

        a:hover {
            color: $blue;
        }
    }

    li + li:before {
        @include rem(font-size, 16px);
        @include rem(margin, 0 $small-margin);
        @extend .icon-arrow-down--active:before:before;
        display: inline-block;
        color: $orange;
        vertical-align: middle;
        transform: rotate(-90deg);
    }

    &--shop {
        @include rem(margin, 23px 0 10px 0);
        @include rem(padding, 5px 0); 
        list-style: none;

        @include breakpoint(tablet) {
            @include rem(margin, $medium-margin 0);
        }

        li {
            color: $light-grey;
            display: inline-block;
            @include fontSize(shopBreadcrumbs);
            @include rem(margin, 0 40px 0 0);
            @include rem(padding, 0);
            vertical-align: middle;
            width: auto;

            @include breakpoint(tablet) {
                @include rem(margin, 0 90px 0 0);
            }

            &.active {
                color: $black;
            }

            &.complete {
                color: $orange;
                @include rem(margin, 0 10px 0 0);

                @include breakpoint(tablet) {
                    @include rem(margin, 0 25px 0 0);
                }

                &:after {
                    @include rem(font-size, 27px);
                    @include rem(margin, 0);
                    @extend .icon-tick--default:before:before;
                    color: $orange;
                    vertical-align: top;

                    @include breakpoint(tablet) {
                        @include rem(font-size, 43px);
                    }

                    @include breakpoint(desktop) {
                        @include rem(font-size, 52px);
                    }
                }
            }

            &:last-of-type {
                @include rem(margin, 0);
            }
        }
    }
}