.file-module {
    .module {
        &__container {
            @include rem(padding, $large-margin);
            width: 100%;
            border: 1px solid $light-grey;
        }

        &__content {
            ul {
                @extend .list-reset;
                li {
                    @include rem(margin-bottom, $small-margin);
                    &:hover {
                        cursor: pointer;

                        &:before, a {
                            color: $color-link-active;
                            font-weight: bold;
                        }
                    }
                }

                li:before {
                    @extend .icon-download--default:before;
                    @include rem(margin-right, $small-margin);
                    vertical-align: text-bottom;
                }
            }
        }

        &__dropdown {
            @include rem(margin-top, 6px);

            .fs-dropdown-selected {
                @include rem(margin-bottom, 0);
            }
        }
    }

    .form {
		&__row {
            @include rem(margin, 0);
            @include breakpoint(tablet) {
                @include rem(margin-top, 25px);
            }
			@include breakpoint(desktop) {
				margin: 0;
			}
		}
		&__text-input {
			width: 100%;
			background: transparent;
			border: none;
			border-radius:0;
			border-bottom:$border-thin solid $orange;
			@include rem(padding, 10px 0);
			@include rem(margin, 0 0 30px 0);
			@include placeholder {
				color:$orange;
			}
			@include breakpoint(tablet) {
				@include rem(margin-bottom, 1px);
			}
		}
		&__submit-input {
			color:$orange;
			width:100%;
			border-radius:0;
			background: transparent;
			@include rem(padding, 10px 0);
			@include rem(margin, 10px 0);
			border:$border-thin solid $orange;
			@include breakpoint(tablet) {
				margin:-1px 0 0 0;
			}
			&:hover {
				color:$white;
				background: $orange;
			}
        }
    }
    
    .horizontal-rule {
        @include breakpoint(tablet){
            margin: $medium-margin 0;
        }
    }
    
    .checkbox {
        @include checkbox;
    }
}