.slider-image-container {
    .slider-image {
        position: absolute;
    }

    input {
        position: absolute;
        bottom: 25px;
        left: 29px;
        height: 21px;
        z-index: 3;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
}

.slider-image-container,
.slider-image {
    width: 100%;
    height: 360px;
    overflow: hidden;
    background-color: $white;

    img {
        width: 100%;
    }
    
    @media(min-width: 375px) {
        height: 425px;
    }

    @include breakpoint(tablet) {
        height: 890px;
    }

    @include breakpoint(desktop) {
        width: 530px;
        height: 629px;
    }
}

.slider-track:before {
    position: absolute;
    top: 34px;
    left: 50px;
    width: 140px;
    border-top: 1px solid $white;
    content: '';
    z-index: 2;
}

.imageLight,
.imageDark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    img {
        @include responsiveImage();
    }
}

.imageLight {
    opacity: 1;
}

.imageDark {
    opacity: 0;
}

.slider-ends {
    position: absolute;
    top: 34px;
    left: 30px;
    width: 180px;
    z-index: 2;
}

.slider-ends:before,
.slider-ends:after {
    position: absolute;
    top: -15px;
    width: 30px;
    height: 30px;
    border: 1px solid $white;
    border-radius: 40px;
    content: '';
}

.slider-ends:before {
    left: -12px;
}

.slider-ends:after {
    right: -12px;
}

.range-slider {
    position: absolute;
    bottom: 15px;
    right: 19px;
    width: 240px;
    height: 70px;
}

input[type=range] {
    /*removes default webkit styles*/
    -webkit-appearance: none;
    
    /*fix for FF unable to apply focus style bug */
    //border: 1px solid white;
    
    /*required for proper track sizing in FF*/
    width: 182px;

    background: transparent;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 180px;
    height: 1px;
    background: transparent;
    border: none;
    border-radius: 3px;
}
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: $white;
    margin-top: -4px;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: transparent;
}

input[type=range]::-moz-range-track {
    width: 180px;
    height: 1px;
    background: transparent;
    border: none;
    border-radius: 3px;
}
input[type=range]::-moz-range-thumb {
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: $white;
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
}

input[type=range]::-ms-track {
    width: 180px;
    height: 1px;
    
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: transparent;
    border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
    background: transparent;
    border-radius: 10px;
}
input[type=range]:focus::-ms-fill-lower {
    background: transparent;
}
input[type=range]:focus::-ms-fill-upper {
    background: transparent;
}
input[type=range]::-ms-thumb {
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: $white;
}