.terms-conditions {
    &__title {
        cursor: pointer;
        @include fontSize(hotLinks);
        margin: 0;
        @include rem(padding, 20px 0);

        @include breakpoint(tablet) {
            cursor: default;
        }

        &:before {
            @include rem(font-size, 30px);
            position: absolute;
            right: 0;
            @extend .icon-arrow-down--default;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }
    &__heading {
        position: relative;

        &.is-on {
            .terms-conditions__title:before {
                @include transform(rotate(-180deg));
            }
        }
    }
    &__content {
        border-bottom: 1px solid $orange;
        @include rem(padding, 0);

        @include breakpoint(tablet) {
            border-bottom: none;
            @include rem(padding, 0 50px 0 0);
        }

        @include breakpoint(desktop) {
            @include rem(padding, 0 95px 0 0);
        }

        p {
            &:last-of-type {
                @include rem(margin, 0 0 17px 0);
            }
        }
    }

    &__wrapper {
        display: none;

        &.show {
            display: block;
        }

        @include breakpoint(tablet) {
            display: block!important;
        }
    }

    .module__links {
        @include rem(margin, 0 0 18px 0);

        @include breakpoint(tablet) {
            @include rem(margin, 10px 0);
        }

        a {
            @include rem(margin, 2px 5px 0 0);

            @include breakpoint(tablet) {
                @include rem(margin, 10px 5px 0 0);
            }
        }
    }    

    & + .section--carousel,
    & + .products-grid-section {
        @include rem(margin-top, 40px);

        @include breakpoint(tablet) {
            @include rem(margin-top, 10px);
        }
    }
}