.js-section-contact-us-configurator {
    @include rem(margin-top, 20px);
    .section {
        @include rem(margin-top, 0);
    }
}

.configuration-details {
    &__image {
        @include rem(max-height, 300px);
        max-width: 100%;
    }
    &__name {
        margin-top: 0.6rem;
    }
    &__price {
        font-weight: $font-weight-bold;
    }
    &__link {
        @include rem(margin, 0 10px 50px);
        @include rem(padding-bottom, 20px);
        display: block;
        border-bottom: 1px solid $light-grey;

        &:before {
            @include rem(margin-right, 5px);
            @include rem(font-size, 20px);
            @extend .icon-arrow-down--default:before;
            display:inline-block;
            transform: rotate(90deg);
            vertical-align: middle;
        }
    }

    p {
        @include fontSize(configurationText);
        margin-bottom: 0;
    }
}