.article-hub {
    &__filter {
        text-align: center;
    }

    &__button {
		position: relative;
		outline:none;
		background: $fs-dropdown-background-color;
		border:none;
		color: $orange;
		cursor: pointer;
		@include rem(padding, 0 40px 15px 0);

		&:after {
            @extend .icon-arrow-down--default:before;
            @include rem(margin-left, $small-margin);
            @include rem(font-size, 30px);
            color:$orange;
            vertical-align: middle;
        }
    }
    .article-grid-filter {
        display: none;
        position: relative;
        width: 100%;

        &__buttons {
            @include rem(margin-bottom, 30px);
        }

        &.is-on {
            //display: block;
        }

        .horizontal-rule {
            @include rem(margin-bottom, $small-margin);
        }

        .button {
            margin: 0;
            float: left;
            @include breakpoint(tablet) {
                float: right;
            }
        }
    }

    .colour-picker {
        display: inline-block;

        ul {
            @include list-reset;
            width: 185px;

            li {
                @include rem(margin, 1px);
                cursor: pointer;
                display: block;
                float: left;
                padding: 0;
                width: 35px;
                height: 35px;

                &.selected:before {
                    @extend .icon-tick--default:before;
                    @include rem(padding, 2px);
                    @include rem(font-size, 30px);
                    color: $white;
                }
            }
        }
    }

    .checkbox {
        @include checkbox;

        .checkbox__item {
            display: block;
        }
    }
}