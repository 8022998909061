.blockquote-module {
	@include rem(margin, $small-margin auto);
	&__content {
		border-left: $border-thin solid $blue;
		border-right: $border-thin solid $blue;
	}
	@include breakpoint(tablet){
		@include rem(margin, $medium-margin auto);
	}
	@include breakpoint(desktop){
		@include rem(margin, $large-margin auto);
	}
} 