.grid-filter {
    border-bottom: 1px solid $light-grey;
    text-align: center;

    &.active {
        border-bottom: 1px solid $light-grey;

        @include breakpoint(desktop) {
            border-bottom: none;
        }
    }

    &__list {
        @include navigation-list();
    }

    &__item {
        display: block;
        text-align: left;
        width: 50%;

        @media screen and (min-width: 830px) {
            @include rem(padding-right, 7px);
            float: left!important;
            display: inline;
            width: auto;
        }

        @media screen and (min-width: 880px) {
            @include rem(padding-right, 17px);
        }

        @media screen and (min-width: 768px) and (max-width: 829px) {
            float: left!important;
            width: 33.33%;
        }

        @include breakpoint(desktop) {
            @include rem(padding-right, 16px);
        }

        @media screen and (min-width: 1080px) {
            @include rem(padding-right, 35px);
        }

        @include breakpoint(desktop-big) {
            @include rem(padding-right, 49px);
        }

        &:last-child {
            padding-right: 0;
        }
    }

    /*&__mobile {
        display: block;

        @include breakpoint(tablet) {
            display: none;
        }
    }*/

    &__button {
        position: relative;
        outline:none;
        background: $fs-dropdown-background-color;
        border:none;
        color: $orange;
        cursor: pointer;
        @include rem(padding, 0 0 15px 0);

        @include breakpoint(tablet) {
            @include rem(padding-right, 0);
        }

        &:after {
            @extend .icon-arrow-down--default:before;
            @include rem(margin-left, $small-margin);
            display: inline-block;
            color:$orange;
            vertical-align: middle;
        }

        &.active {
            color: $black;

            @include breakpoint(tablet) {
                @include rem(padding-bottom, 15px);
            }

            &:after {
                transform: rotate(-180deg);
            }

            ~ .grid-filter--mobile {
                border-bottom: 1px solid $light-grey;
            }
        }
    }

    &__buttons {
        float: right;

        a {
            @include rem(margin-right, 0);
        }
    }

    &--mobile {
        display: block;

        @include breakpoint(desktop) {
            display: none;
        }

        .container {
            width: 100%;
        }

        .grid-filters {
            @include rem(margin-bottom, $small-margin);
            @include rem(padding-top, 0);
        }
    }

    .button {
        margin: 0;
        float: left;
        @include breakpoint(tablet) {
            float: right;
        }
    }

    .grid > .one--column {
        @include breakpoint(tablet) {
            width: calc(100% / 7);
        }
    }
}

.grid-filters {
    @include rem(padding, 15px 0);
    border-bottom: 1px solid $light-grey;
    display: none;
    position: relative;
    width: 100%;
    background-color: $white;

    .checkbox {
        @include checkbox;
    }

    .checkbox__item {
        @include rem(margin-top, 14px);
        display: block;
    }

    &--mobile {
        .container {
            width: 100%;
        }

        .grid-filters {
            @include rem(padding, 0 0 $medium-padding);
            border-bottom: none;
        }
    }

    &-button--mobile {
        @include rem(margin-bottom, 15px);
        display: inline-block;
        float: left;

        @include breakpoint(tablet) {
            display: none;
        }
    }
}