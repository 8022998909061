

$fs-dropdown-max-height:							500px;
$fs-dropdown-background-color:						transparent;
$fs-dropdown-color:									$white;
$fs-dropdown-disabled-opacity:						0.5;
$fs-dropdown-item-selected-background-color:		transparent;


.fs-dropdown {
	position: relative;
	z-index: 1;

	display: block;

	max-width: 100%;

	&-element {
		width: 100%;
		height: 100%;

		position: absolute;
		left: 0;
		z-index: -1;

		opacity: 0;

		& {
			outline: none;
			-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
			-webkit-tap-highlight-color: transparent;
		}
	}

	&-selected {
	
		width: 100%;

		position: relative;
		z-index: 2;
		outline:none;
		background: $fs-dropdown-background-color;
		border:none;
		color: $fs-dropdown-color;
		cursor: pointer;
		display: block;
		overflow: hidden;
		text-align: left;
		text-overflow: clip;
		outline:0;
		border-bottom:1px solid $white;
		padding-left: 0;
		border-radius: 0;
		@include rem(margin-top, 6px);
		@include rem(padding, 0 50px $default-padding 0);

		&:before {
            @extend .icon-arrow-down--default:before;
            @include rem(font-size, 30px);
            color:$white;
            position: absolute;
            right:0;
        }
	}

	&-options {
		width: 100%;
		max-height: $fs-dropdown-max-height;
		top: 100%;
		left: 0;
		z-index: 5;

		background: $fs-dropdown-background-color;
		display: none;
		overflow: auto;
		overflow-x: hidden;

		&.fs-scrollbar {
			position: absolute;
		}
	}

	&-item {
		width: 100%;
		background: $fs-dropdown-background-color;
		border-radius: 0;
		color: $fs-dropdown-color;
		cursor: pointer;
		display: block;
		overflow: hidden;
		text-align: left;
		text-decoration: none;
		text-overflow: ellipsis;
		border:none;
		padding-left:0;

		&:last-child{
			border-bottom:1px solid $white;
			@include rem(padding-bottom, $default-padding);
		}
	
		&_placeholder {
			display: none;
		}
	}

	&-open &-options {
		display: block;
	}

	&-multiple &-options {
		width: 100%;

		position: static;

		display: block;
	}
}

.fs-dropdown-open > .fs-dropdown-selected {
	border-bottom:none;
	&:before {
     	transform: rotate(-180deg); 
    }
}
