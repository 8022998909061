.info-panel {
	position: fixed;
	bottom:0;
	width:100%;
	background: $blue;
	z-index:index($elements, infoPanel);
	@include rem(height, 65px);

	@include breakpoint(tablet){
		@include transition(all .5s ease);
		position: fixed;
		@include rem(top,249px);
		@include rem(right, -245px);
		@include rem(width, 310px);
		
	}

	&.is-open {
		@include breakpoint(tablet){
			@include rem(width, 310px);
			right:0;
		}
	}
	
	&__toggle {
		display: none;
		background: $dark-blue;
		@include rem(padding, 21px 4px 14px 20px);
		@include rem(height, 65px);

		@include breakpoint(tablet){
			display: block;
		}

		&:before {
			@extend .icon-expand-2--default;
			@include rem(font-size, 25px);
			
		}
		&:hover {
			@extend .icon-expand-2--hover;	
		}

		&.is-engaged {
			&:before {
				@extend .icon-contract-2--default;
			}
			&:hover {
				@extend .icon-contract-2--hover;
			}
		}
		
	}

	&__close {
		
		display:none;
		position: absolute;
	    z-index:index($elements, infoPanelClose);
		@include rem(top, 25px);
		@include rem(right, 20px);

		&:before {
			@extend .icon-close-2--default;
			@include rem(font-size, 25px);
			
		}
	}
	
	.nav {
		position:relative;
		z-index:index($elements, infoPanelNav);

		&__item {
			width:25%;
			display: inline-block;
			float: left;
			@include rem(height, 65px);
			@include breakpoint(tablet){
				@include rem(width, 65px);
				display:list-item;
				float: none;
			}
		}
	
		&__link {
		
			position:relative;
			width:100%;
			display:block;
			@include rem(height, 65px);

			&:before {
			    position: absolute;
			    height: 20px;
			    left: 0;
			    right: 0;
			    top: 0;
			    bottom: 0;
			    margin: auto;
			    text-align: center;
			}
		

			@include breakpoint(tablet){
				display: block;
				@include rem(width, 65px);
			}

			&.is-on {
				@include breakpoint(tablet){
					@include rem(left, -10px);
					width: 7.7rem;
				}
			}

			&:before {
				@include rem(font-size, 25px);
			}
			
			&--phone {
				background: $blue;
				&:before {
					@extend .icon-phone-2--default
				}
				&:hover, &.is-on {
					@extend .icon-phone-2--hover
				}
			}
			&--mail {
				background: $green;
				&:before {
					@extend .icon-mail-2--default
				}
				&:hover, &.is-on {
					@extend .icon-mail-2--hover
				}
			}
			&--chat {
				background: $orange;
				&:before {
					@extend .icon-chat-2--default
				}
				&:hover, &.is-on{
					@extend .icon-chat-2--hover
				}
					
			}
			&--map {
				background: $grey;
				&:before {
					@extend .icon-location-2--default
				}
				&:hover, &.is-on {
					@extend .icon-location-2--hover
				}
			}
		}

	}

	&__content {
		display:none;
		color:$white;
		position:relative;
		right:0;
		z-index:index($elements, infoPanelContent);
		@include rem(width, 100%);
		@include rem(height, 260px);
		@include rem(padding, 40px 30px 10px);
	
		&.is-on {
			@include rem(margin-top, -320px);
			@include breakpoint(tablet){
				margin:auto;
			}
		}

		@include breakpoint(tablet){
			width: 24.5rem;
			position:absolute;
			height: auto;
			margin:auto;
			@include rem(top, 65px);
			@include rem(padding, 25px 25px 10px 25px);
		}

		&__inner {
			opacity:0;
		}
	
		&__title {
			margin-top: 0;
			margin-bottom: 10px;
			@include fontSize(sidePanelTitle);
		}
		&__phone-number,
		&__email{
			color:$white;
			@include fontSize(sidePanelTitle);
		}
		&__phone-number{
			color:$white;
			display:block;
			@include rem(margin-bottom, 10px);
			@include rem(font-size, 20px);
		}

		&--phone {
			background: $blue
		}
		&--mail {
			&.is-on {
				background: $green;
			}
		}
		&--chat {
			&.is-on {
				background: $orange;
			}
		}
		&--map {
			&.is-on {
				background: $grey;
			}
		}
	}
	@include breakpoint(tablet){
		&.is-scaled {
			-ms-transform: scale(.8);
			-webkit-transform: scale(.8);
			-moz-transform: scale(.8);
			-o-transform: scale(.8);
			transform: scale(.8);
			-webkit-transform-origin: top right;
			-ms-transform: top right;
			transform-origin: top right;
			right: -19.58rem;
			width: 30.2rem;

			&.is-open {
				right: 0;
				-ms-transform: scale(1);
				-webkit-transform: scale(1);
				-moz-transform: scale(1);
				-o-transform: scale(1);
				transform: scale(1);
			}
			.info-panel__toggle {
				padding: 2.1rem 0.4rem 1.4rem 1.6rem;
			}

			.nav {
				&__item {

					@include breakpoint(tablet){
						@include rem(width, 57px);
					}
					
				}
				&__link {

					@include breakpoint(tablet){
						@include rem(width, 57px);
						&.is-on {
							width: 7.7rem;
						}
					}

				}
			}
		}
	}

}