.section--carousel {
  @include rem(margin, $small-margin auto 80px);

  .container--smaller-width {
    @include rem(padding, 0);
  }

  .owl-stage {
    padding-left: 0 !important;
  }

  .container {
    .main-carousel {
      padding: 0;
    }
  }

  &--shop {
    @include rem(margin, $small-margin auto);

    .main-carousel {
      padding: 0;
      margin: 0;
      width: 100%;
    }

    .section {
      &__title {
        @include rem(margin-bottom, 15px !important);

        @include breakpoint(tablet) {
          @include rem(margin-bottom, 22px !important);
        }
      }
    }
  }

  .section {
    &__header {
      position: relative;
    }

    &__title {
      display: block;
      @include rem(margin, 20px 110px 5px 0);

      @include breakpoint(tablet) {
        line-height: 1;
        @include rem(margin, 20px 100px 20px 0);
      }
    }

    &__link {
      @include rem(bottom, 2px);
      position: absolute;
      right: 0;
      @include rem(margin, 0);

      @include breakpoint(tablet) {
        bottom: 0;
      }
    }
  }
}
