.kitchen-sink {

	&__header {
		@include rem(margin-bottom, $mega-margin);
		.container {
			border-bottom: solid $border-thin $light-grey;
			@extend .section;
			@include rem(padding, 0 0 $large-padding);
		}

		li {
			@include rem(margin, $small-margin 0);
		}
		
	}
	&__section {
		&__title {
			border-bottom: solid $border-thin $light-grey;
			@include rem(padding, $medium-padding 0);
			@include rem(margin-bottom, $medium-margin);
			@extend .section;
		}
	}
}