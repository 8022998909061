.section--panels {
    .flap {
        @include rem(margin-bottom, 60px);
        height: 19rem;

        @include breakpoint(tablet) {
            height: 7rem;
        }

        @include breakpoint(desktop) {
            height: 16rem;
        }

        @include breakpoint(desktop-big) {
            width: calc(100% - 10px);
            height: 17.2rem;
            border: solid $light-grey $border-thin;
        }

        &__front-panel {
            p {
                @include rem(margin-top, 8px);
                color: $black;
            }
        }

        &.active {
            transform: rotateX(5deg);

			-webkit-box-shadow: 0 4px 4px rgba(151, 151, 151, 1),
		                		0 8px 8px rgba(151, 151, 151, 1),
		                		0 16px 16px rgba(151, 151, 151, 1), 
		                		0 32px 32px rgba(0, 0, 0, .15), 
		            			0 64px 64px rgba(0, 0, 0, .15);

			-moz-box-shadow:    0 4px 4px rgba(151, 151, 151, 1),
		            			0 8px 8px rgba(151, 151, 151, 1),
	                			0 16px 16px rgba(151, 151, 151, 1), 
		                		0 32px 32px rgba(0, 0, 0, .15), 
		                		0 64px 64px rgba(0, 0, 0, .15);
		                			
		        box-shadow: 	0 4px 4px rgba(151, 151, 151, 1),
		            			0 8px 8px rgba(151, 151, 151, 1),
		            			0 16px 16px rgba(151, 151, 151, 1), 
	                			0 32px 32px rgba(0, 0, 0, .15), 
	                			0 64px 64px rgba(0, 0, 0, .15);
        }
    }

    .teaser {
        height: 19rem !important;
        background-color: $white;

        @include breakpoint(tablet) {
            height: 7rem !important;
        }

        @include breakpoint(desktop) {
            height: 16rem !important;
        }

        @include breakpoint(desktop-big) {
            height: 17rem !important;
        }

        &__image {
            width: 100%;

            &--focused {
                height: 100%;
            }
        }
    }
}