.form {
  -webkit-tap-highlight-color: transparent;

  &__row {
    @include rem(margin, 25px 0 0);
    @include breakpoint(desktop) {
      margin: 0;
    }

    &__contact-us {
      p {
        margin-bottom: 0;
      }


    }

    &__configurator {
      p {
        @include rem(margin-bottom, $large-margin);
      }
    }

    &--contact {
      @include rem(margin-bottom, 25px);
    }
  }

  &__element {
    @include breakpoint(desktop) {
      &:first-child {
        @include rem(padding-right, 14px);
      }
      &:nth-child(2) {
        @include rem(padding-left, 14px);
      }
    }

    .form__dropdown--error--small {
      @include rem(margin-bottom, 50px);
    }
  }

  &__input-wrapper {
    //@include rem(margin-bottom, 20px);
    position: relative;

    @include breakpoint(desktop) {
      @include rem(margin-bottom, 30px);
    }

    &--border {
      border: 1px solid $orange;
      @include rem(padding, 16px 0 16px 50px);

      > * {
        @include rem(margin, 0 !important);
      }
    }

    &--background {
      background: $breadcrumbs;
    }
  }

  &__radio-wrapper {
    @include rem(margin-bottom, 0);
    position: relative;

    @include breakpoint(desktop) {
      @include rem(margin, 30px 0 43px);
    }

    .radioButton input + label {
      margin-right: 0 !important;

      &:before {
        width: 20px !important;
        height: 20px !important;
      }

      img {
        @include rem(padding-top, $small-padding);
        margin: auto;
      }
    }

    .radioButton [type="radio"] + label:after {
      left: 37px !important;
    }

    [type="radio"] + label {
      width: 90px;
    }

    label {
      text-align: center;
    }
  }

  &__dropdown {
    @include fontSize(inputLabel);
    @include rem(margin-top, 0px);
    @include rem(margin-bottom, 15px);
    position: relative;

    @include breakpoint(tablet) {
      @include rem(margin-top, -15px);
    }

    @include breakpoint(desktop) {
      @include rem(margin-bottom, 10px);
    }

    &--error--small {
      @include rem(margin-bottom, 60px);

      @include breakpoint(desktop) {
        @include rem(margin-bottom, 50px);
      }

      .parsley-error + ul {
        @include rem(top, 33px);
      }
    }

    .dropdown {
      @extend .list-inline;
      @extend .list-reset;

      &__label {
        @include fontSize(formLabel);
        color: $grey;
      }

      &__item {
        width: 100%;
      }
    }

    .parsley-error + ul {
      @include rem(left, 0);
      @include rem(top, 34px);
      z-index: 1;
      position: absolute;
      width: 100%;

      @include breakpoint(desktop) {
        @include rem(top, 38px);
      }
    }

    .fs-dropdown {
      &-selected {
        color: $dark-grey;
        border-bottom: 1px solid $dark-grey;
        padding: 0 5rem 0.8rem 0;
        margin-top: 0;
        @include rem(margin-bottom, 10px);

        @include breakpoint(desktop) {
          @include rem(margin, 0 0 $medium-margin);
        }

        &:before {
          color: $dark-grey;
          font-size: 2.5rem;
        }
      }

      &-item {
        color: $dark-grey;
        @include rem(padding-bottom, 22px);

        &:last-child {
          border-bottom: 1px solid $dark-grey;
          @include rem(margin-bottom, 33px);
          @include rem(padding-bottom, 25px);
        }

        @include breakpoint(tablet) {
          @include rem(padding-bottom, 17px);

          &:hover {
            color: $light-grey;
          }
        }
      }

      &-options {
        margin-bottom: 0 !important;
        @include rem(margin-top, 10px);

        @include breakpoint(tablet) {
          margin-top: 0;
        }
      }

      &-open {
        @include rem(margin-bottom, 10px);

        @include breakpoint(desktop) {
          @include rem(margin-bottom, 25px);
        }

        .fs-dropdown {
          &-selected {
            border-bottom: none;
            @include rem(margin-bottom, 0);
          }

          &-item {
            &:hover {
              color: $light-grey;
            }
          }

          &-item:last-child {
            @include rem(margin-bottom, 0);
            @include rem(padding-bottom, 20px);
          }
        }

        .fs-dropdown-options {
          margin-top: 0;
          @include rem(padding-top, 10px);
        }
      }
    }
  }

  &__text-input {
    width: 100%;
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: $border-thin solid $orange;
    @include rem(padding, 10px 0);
    @include rem(margin, 30px 0);
    @include rem(margin, 0 0 1px 0);
    @include placeholder {
      color: $orange;
    }
    @include breakpoint(tablet) {
      @include rem(margin, 0 0 1px 0);
    }

    &--white {
      border-color: $white;
      @include placeholder {
        color: $white;
      }
    }

    &--off-white {
      border-color: $off-white;
      @include placeholder {
        color: $off-white;
      }
    }
  }

  &__submit-input {
    width: 100%;
    border-radius: 0;
    background: transparent;
    @include rem(padding, 10px 0);
    @include rem(margin, 10px 0);
    border: $border-thin solid $orange;
    @include breakpoint(tablet) {
      margin: -1px 0 0 0;
    }

    &:hover {
      color: $white;
      background: $orange;
    }

    &--white {
      border-color: $white;
    }

    &--off-white {
      border-color: $off-white;
      color: $off-white;
    }

    &--white,
    &--off-white {
      &:hover {
        border-color: $white;
        color: $orange;
        background: $white;
      }
    }
  }

  &__input {
    position: relative;

    input, label {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      text-indent: 5px;
      line-height: 30px;
    }
  }

  &__small-print {
    @include fontSize(paragraph);
    @include rem(margin, 9px 0 30px 0);
    width: 100%;

    @include breakpoint(tablet) {
      @include rem(margin, 3px 0 30px 0);
    }

    @include breakpoint(desktop) {
      @include rem(margin, 0 0 30px 0);
      width: calc(100% - 55px);
    }
  }

  &__shipping {
    .form {
      &__postcode {
        input {
          position: absolute;
          right: 0;
          top: -8px;
          width: 33.3%;
        }
      }

      &__dropdown {
        @include rem(margin-bottom, 20px);
      }
    }
  }

  &__contact-us {

    .checkbox {
      label {
        a {
          border-bottom: 1px solid $orange;

          &:hover {
            border-bottom: 1px solid $light-grey;
          }
        }
      }
    }

    .form {
      &__row {
        &__contact-us,
        &__configurator {
          @include rem(margin-bottom, 40px);

          @include breakpoint(tablet) {
            @include rem(margin-bottom, 70px);
          }
        }

        &__contact-personal {
          @include breakpoint(tablet) {
            &:nth-of-type(2) {
              @include rem(margin-bottom, 35px);
            }
          }
        }

        &__contact-method {
          @include rem(margin-bottom, 35px);

          @include breakpoint(desktop) {
            @include rem(margin-bottom, 5px);
          }
        }
      }
    }
  }

  /*.grid {
      .parsley-error {
          &:focus + label,
          &.has-value + label,
          &:focus + ul + label,
          &.has-value + ul + label {
              top: 0;
          }
          ~ .parsley-errors-list + label {
              top: 21%;
          }
      }

      .grid {
          .parsley-error {
              &:focus + label,
              &.has-value + label,
              &:focus + ul + label,
              &.has-value + ul + label {
                  top: 0;
              }
              ~ .parsley-errors-list + label {
                  top: 16%;
              }
          }
      }
  }*/
  .checkbox {
    margin-bottom: 1.2rem;
    @include checkbox;

    label {
      display: block;
      color: $dark-grey;

      a {
        &:hover {
          color: $light-grey;
        }
      }
    }

    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      top: 4px;
    }

    @include breakpoint(tablet) {
      margin-bottom: 1.5rem;
    }

    &--right {
      label {
        padding-left: 0;
        @include rem(padding-right, 34px);
        text-align: right;

        &:before,
        &:after {
          left: auto;
          right: 0;
        }
      }

      .checkbox__item {
        display: block;

        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
          margin-right: 0;
        }
      }
    }

    &--background {
      background: $breadcrumbs;
    }
  }

  .radioButton {
    margin-top: 1.2rem;
    display: inline-block;
    @include radioButton;

    input + label {
      margin-right: 1.5rem;
      display: inline-block;
      color: $dark-grey;
    }

    &__label {
      @include fontSize(formLabel);
      color: $grey;
      display: block;
      position: absolute;
      top: -1rem;
    }

    &__item {
      margin-bottom: 1.5rem;

      [type="radio"] {
        &:checked + label:after,
        & + label:after {
          @include rem(top, 7px);

          @include breakpoint(desktop) {
            @include rem(top, 9px);
          }
        }
      }

      &:last-child {
        margin: 0;
      }
    }

    .parsley-errors-list {
      float: right;
      margin-top: -1.9rem;
      margin-bottom: 0;
    }

    @include breakpoint(desktop) {
      .parsley-errors-list {
        position: absolute;
        margin-top: -1.8rem;
        float: none;
        width: 100%;
      }
    }
  }

  .six--mob-columns {
    @extend .grid__cell;
    width: 50%;
    /* @include breakpoint(desktop) {
        width: 25%;
    } */
  }

  .module {
    &__header {
      @include rem(margin, 0 0 20px 0);

      h4 {
        margin: 0;
      }

      span {
        color: $grey;
      }
    }
  }

  &__dropdown--error {
    @include rem(margin-bottom, 70px);
  }
}

input[type="email"],
input[type="text"],
select:focus,
textarea {
  @include fontSize(baseSize);
}

input:required {
  & ~ label {
    &:after {
      content: "*";
      color: $orange;
    }
    span{
      display: none;
    }
  }

  &.parsley-error {
    & ~ label {
      &:after {
        content: "*";
        color: $orange;
      }
    }
  }
}

label.is-required {
  &:after {
    content: "*";
    color: $orange;
  }
}

.input-group {
  position: relative;
  width: 100%;

  .form__submit-input {
    color: $dark-grey;

    &:hover {
      color: $white;
    }
  }

  input {
    @include rem(padding-bottom, $small-padding);
    @include fontSize(inputLabel);
    padding-bottom: 7px;
    width: 100%;
    background: none;
    border: solid $orange $border-thin;
    color: $form-black;
    margin-top: 0;
    transition: all 0.15s ease-out 0s;

    &:focus + label,
    &.has-value + label,
    &:focus + ul + label,
    &.has-value + ul + label {
      @include fontSize(formLabel);
      color: $grey;
      top: 0;
    }

    &[disabled] + label, &[readonly] {
      color: $light-grey;
      span{
        color: $light-grey;
      }
    }

    &:focus,
    &.has-value {
      margin-top: 0;

      & + label,
      & + ul + label {
        top: 0;
      }
    }
  }

  input:focus, input:active {
    outline: none;

    label {
      color: $form-black;
    }
  }

  input[type="text"], input[type="email"] {
    border: none;
    border-bottom: solid $dark-grey $border-thin;
    border-radius: 0;
  }

  input[disabled] {
    border-bottom: 1px solid $light-grey;
    -webkit-text-fill-color: $light-grey;
    color: $light-grey;
    -webkit-opacity: 1;


  }

  label {
    @include rem(padding-bottom, $small-padding);
    @include fontSize(inputLabel);
    position: absolute;
    top: 50%;
    left: 0px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: $dark-grey;
    pointer-events: none;
    -webkit-transition: all 0.15s ease-out 0s;
    transition: all 0.15s ease-out 0s;
  }

  textarea {
    width: 100%;
    padding: 10px;
    padding-top: 34px;

    & + label {
      top: 40px;
      left: 10px;
    }

    &:focus {
      & + label {
        top: 24px;
        left: 10px;
      }
    }
  }

  span {
    color: $grey;
  }

  &__disabled-icon {
    fill: $light-grey;
    height: 18px;
    position: absolute;
    right: 0;
    @include rem(top, 8px);

    @include breakpoint(tablet) {
      @include rem(top, 11px);
    }

    @include breakpoint(desktop) {
      @include rem(top, 14px);
    }
  }
}

.error-left {
  &.parsley-error {
    @include rem(margin-bottom, 50px);

    @include breakpoint(desktop) {
      @include rem(margin-bottom, $large-margin);
    }
  }

  & ~ .parsley-errors-list {
    position: absolute;
    top: 50%;
    left: 0;

    @include breakpoint(tablet) {
      left: -106%;
    }
  }
}

.parsley-errors-list {
  color: $error-red;
  line-height: 1.6;
  list-style: none;
  padding: 0;
  position: relative;
  white-space: normal;
  @include fontSize(formLabel);

  li {
    @include rem(margin, 15px 33px);
    max-width: 230px;

    @include breakpoint(desktop) {
      max-width: 270px;
    }

    &:before {
      display: inline-block;
      float: left;
      background-image: url(../../usm-img/shop/warning-icon.svg);
      background-repeat: no-repeat;
      content: '';
      @include rem(height, 23px);
      position: absolute;
      @include rem(width, 23px);
      left: 0;
    }
  }

  & + label {
    @include rem(padding-bottom, 0);
    top: 21%;
  }
}

.section--contact-us__message {
  letter-spacing: normal;
}

.lds-ellipsis {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 13px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-ellipsis div {
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ffffff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.submit-wrapper {
  display: inline-flex;
  width: 100%;
  position: relative;
  @include breakpoint(desktop) {
    max-width: 275px;
  }
}

