.content-tags {

	@include rem(margin-bottom, $tiny-margin);

	&__list{
		border-top: solid $border-thin $light-grey;
		border-bottom: solid $border-thin $light-grey;
		@include rem(padding, $medium-padding 0);
		@include rem(margin, $mega-margin 0 0);


	}
	&__item {
		position: relative;
		@include rem(margin, 0 7px 5px 0);

		@include breakpoint(tablet) {
			margin-bottom: 0
		}

		&:after	{
			content: ',';
			color:$orange;
			position: absolute;
			top:0;
			@include rem(right, -5px);
		}
		&:last-child {
			&:after	{
				content: '';
			}
		}
		a:hover {
			font-weight: bold;
		}
	}


	&--no-borders {
		.content-tags__list {
			border:none;
			@include rem(padding, $tiny-padding 0);
			@include rem(margin, 0);
		}
	}
}
