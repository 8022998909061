.confirmation {
    &-summary {
        @include rem(padding, 15px 0 $tiny-padding);

        @include breakpoint(desktop) {
            @include rem(padding, 0);
        }

        .order-summary__product,
        .order-summary__product:last-of-type {
            display: flex;
            border-bottom: none;
        }

        .order-summary__product:first-of-type {
            @include rem(padding-top, $small-padding);
        }

        .order-summary__image {
            flex: 1;
        }

        .order-summary__details {
            flex: 2;
        }

        .cart__total:first-child {
            @include rem(margin-top, $medium-margin);
        }

        .module__header h4 {
            @include rem(font-size, 25px);
        }

        .module__copy--large {
            font-size: 2.5rem !important;
            line-height: 1;
        }

        .divider-module {
            @include rem(padding-bottom, 15px);
            
            @include breakpoint(desktop) {
                @include rem(padding-bottom, 0);
            }

            .container {
                width: 100%;
            }
        }
    }

    &-ctas {
        @include rem(margin, 15px 0 $medium-margin 0);

        @include breakpoint(desktop) {
            @include rem(margin, 25px 0 15px 0);
        }

        .module__links {
            @include rem(margin-top, 0);
        }

        .button:last-of-type {
            @include rem(margin-top, 0);
        }
    }
}