.products-grid-section {
    .section__subtitle {
        @include rem(font-size, 25px);
    }
    .flap {
        margin-bottom: 0;
        &:hover {
            .teaser__title {
                display: block;
            }
        }
        &__link--arrow {
            @include rem(margin, 5px 0 $medium-margin);
            display: block;
            &:after {
                @extend .icon-arrow-down--default:before;
                display:inline-block;
                transform: rotate(-90deg);
                @include rem(font-size, 13px);
                @include rem(margin-left, $tiny-margin);
            }
            &:hover,
            &:hover:after {
                color:$light-grey;
            }
            @include breakpoint (desktop) {
                margin-bottom: 0;
            }
        }
        &__name {
            @include fontSize(teaserName);
            @include rem(margin-bottom, 9px);

            @include breakpoint(desktop) {
                @include rem(margin-bottom, 8px);
            }
        }
        &__dimensions {
            @include fontSize(teaserDimensions);
            @include rem(margin-bottom, 22px);
        }
        &__vat {
            @include fontSize(teaserDimensions);
            @include rem(margin, 8px 0 0 0);

            @include breakpoint(desktop) {
                @include rem(margin, 5px 0 0 0);
            }
        }
        &__details {
            display: block;
            @include rem(margin-top, 30px);

            p {
                line-height: 1;

                &:last-of-type {
                    @include rem(margin-bottom, 20px);
                }
            }
            .button {
                @include rem(margin, 8px 0 20px);
                @include rem(margin-bottom, 45px);

                @include breakpoint(desktop) {
                    @include rem(margin-top, 5px);
                    @include rem(margin-bottom, 0); 
                }
            }

            @media screen and (min-width: 600px) {
                display: inline-block;
            }

            @include breakpoint(desktop) {
                @include rem(margin-top, 0);
            }
        }
        &__price {
            @include rem(margin-bottom, 10px);
            @include fontSize(teaserPrice);

            @include breakpoint(desktop) {
                @include rem(margin-bottom, 0);
            }
        }
        @include breakpoint (desktop) {
            @include rem(margin-bottom, $medium-margin);
        }
        &--original {
            @include breakpoint(tablet) {
                @include rem(height, 300px!important);// Limit js height
            }
            
            @include breakpoint (desktop) {
                @include rem(height, 250px!important);// Limit js height
            }

            @include breakpoint(desktop-big) {
                @include rem(height, 300px!important);// Limit js height
            }
        }
    }
    .teaser {
        border: 1px solid $light-grey;

        @include breakpoint(tablet) {
            @include rem(height, 300px!important);// Limit js height
        }

        @include breakpoint(desktop) {
            @include rem(height, 250px!important);// Limit js height
        }

        @include breakpoint(desktop-big) {
            @include rem(height, 300px!important);// Limit js height
        }

        &--square {
            border: 1px solid $light-grey;
   
            /*&:hover {
                .teaser__image--focused {
                    opacity: 1;
                }
            }*/
       }

       &__title {
            @include breakpoint(desktop-big) {
                display: none;    
            }
        }

        &__image {
            display: block;
            height: auto;
            @include opacity(1);
            position: relative;
            width: 100%;       
    
            &--focused {
                @include opacity(0);
            }
        }
    }
    .js-teaser-product {
        position: relative;

        .button {
            display: block;
            position: relative;

            @media screen and (min-width: 600px) {
                bottom: 0;
                display: none;
                position: absolute;
            }

            &--grid {
                @include breakpoint(desktop) {
                    @include rem(bottom, 60px);
                }
            }
        }
    }
    &--pagination {
        .flap:hover .flap__front-panel {
            box-shadow: none;
            -webkit-box-shadow: none;
        }
        
        .flap__details {
            @include breakpoint(desktop) {
                @include rem(margin-bottom, 60px);
            }
        }

        .teaser__image,
        .teaser__image--focused {
            visibility: hidden;
        }

        .products-grid {
            &__header {
                .grid > div {
                    @include breakpoint(desktop) {
                        display: inline-block;
                    }
                }
            }
        
            &__footer {
                .pagination {
                    @include breakpoint(desktop) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    .owl-carousel {
        .item {
            display: none;
            width: 100%;
        }

        .owl {
            &-dots {
                bottom: 0;
                @include rem(margin-top, 0);
                padding: 0;
                position: relative;
            }
            &-dot {
                @include rem(margin-right, 10px);
                @include rem(width, 25px);

                @media screen and (min-width:0\0) and (min-resolution: +72dpi) {
                    display: inline;
                    @include rem(margin-right, 20px!important);
               }

                &:not(.active):before {
                    content: '\e9b9';
                    color: $light-grey;
                    @include opacity(0.5);
                }
            }
        }
        
    
    }
}