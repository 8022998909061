@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?m5m9bn');
    src: url('../fonts/icomoon.eot?m5m9bn#iefix') format('embedded-opentype'),url('../fonts/icomoon.ttf?m5m9bn') format('truetype'),url('../fonts/icomoon.woff?m5m9bn') format('woff'),url('../fonts/icomoon.svg?m5m9bn#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
%icon {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //@include fontSize(iconSizes);
}
.icon-lock--disabled:before {
    content: "\e98f";
    color: $grey;
    @extend %icon;
}
.icon-link-right--active:before {
    content: "\e900";
    color: #900;
    @extend %icon;
}
.icon-link-right--default:before {
    content: "\e901";
    color: #e76525;
    @extend %icon;
}
.icon-link-right--disabled:before {
    content: "\e902";
    color: #b2bcc1;
    @extend %icon;
}
.icon-link-right--hover:before {
    content: "\e903";
    color: #e76525;
    @extend %icon;
}
.icon-link-right-2--active:before {
    content: "\e904";
    color: #fff;
    @extend %icon;
}
.icon-link-right-2--default:before {
    content: "\e905";
    color: #fff;
    @extend %icon;
}
.icon-link-right-2--disabled:before {
    content: "\e906";
    color: #fff;
    @extend %icon;
}
.icon-link-right-2--hover:before {
    content: "\e907";
    color: #fff;
    @extend %icon;
}
.icon-link-up--active:before {
    content: "\e908";
    color: #900;
    @extend %icon;
}
.icon-link-up--default:before {
    content: "\e909";
    color: #e76525;
    transition: color .25s ease;
    @extend %icon;
}

.icon-link-up--reverse:before {
    content: "\e909";
    color: #fff;
    @extend %icon;
}
.icon-link-up--hover:before {
    content: "\e90a";
    color: #e76525;
    @extend %icon;
}
.icon-link-up--hover-reverse:before {
    content: "\e90a";
    color: #fff;
    @extend %icon;
}
.icon-link-left--active:before {
    content: "\e90b";
    color: #900;
    @extend %icon;
}
.icon-link-left--default:before {
    content: "\e90c";
    color: #e76525;
    @extend %icon;
}
.icon-link-left--disabled:before {
    content: "\e90d";
    color: #b2bcc1;
    @extend %icon;
}
.icon-link-left--hover:before {
    content: "\e90e";
    color: #e76525;
    @extend %icon;
}
.icon-link-left-2--active:before {
    content: "\e90f";
    color: #fff;
    @extend %icon;
}
.icon-link-left-2--default:before {
    content: "\e910";
    color: #fff;
    @extend %icon;
}
.icon-link-left-2--hover:before {
    content: "\e911";
    color: #fff;
    @extend %icon;
}
.icon-link-up--disabled:before {
    content: "\e912";
    color: #b2bcc1;
    @extend %icon;
}
.icon-link-up-2--active:before {
    content: "\e913";
    color: #fff;
    @extend %icon;
}
.icon-link-up-2--default:before {
    content: "\e914";
    color: #fff;
    @extend %icon;
}
.icon-link-up-2--disabled:before {
    content: "\e915";
    color: #fff;
    @extend %icon;
}
.icon-link-up-2--hover:before {
    content: "\e916";
    color: #fff;
    @extend %icon;
}
.icon-link-down--active:before {
    content: "\e917";
    color: #900;
    @extend %icon;
}
.icon-link-down--default:before {
    content: "\e918";
    color: #e76525;
    @extend %icon;
}
.icon-link-down--disabled:before {
    content: "\e919";
    color: #b2bcc1;
    @extend %icon;
}
.icon-link-down--hover:before {
    content: "\e91a";
    color: #e76525;
    @extend %icon;
}
.icon-link-down-2--active:before {
    content: "\e91b";
    color: #fff;
    @extend %icon;
}
.icon-link-down-2--default:before {
    content: "\e91c";
    color: #fff;
    @extend %icon;
}
.icon-link-down-2--disabled:before {
    content: "\e91d";
    color: #fff;
    @extend %icon;
}
.icon-link-down-2--hover:before {
    content: "\e91e";
    color: #fff;
    @extend %icon;
}
.icon-link-left-2--disabled:before {
    content: "\e91f";
    color: #fff;
    @extend %icon;
}
.icon-phone--default:before {
    content: "\e920";
    color: #e76525;
    @extend %icon;
}
.icon-mail--active:before {
    content: "\e921";
    color: #900;
    @extend %icon;
}
.icon-mail--default:before {
    content: "\e922";
    color: #e76525;
    @extend %icon;
}
.icon-mail--disabled:before {
    content: "\e923";
    color: #b2bcc1;
    @extend %icon;
}
.icon-mail--hover:before {
    content: "\e924";
    color: #e76525;
    @extend %icon;
}
.icon-mail-2--default:before {
    content: "\e925";
    color: #fff;
    @extend %icon;
}
.icon-mail-2--hover:before {
    content: "\e926";
    color: #fff;
    @extend %icon;
}
.icon-phone--active:before {
    content: "\e927";
    color: #900;
    @extend %icon;
}
.icon-phone--disabled:before {
    content: "\e928";
    color: #b2bcc1;
    @extend %icon;
}
.icon-phone--hover:before {
    content: "\e929";
    color: #e76525;
    @extend %icon;
}
.icon-phone-2--active:before {
    content: "\e92a";
    color: #fff;
    @extend %icon;
}
.icon-phone-2--default:before {
    content: "\e92b";
    color: #fff;
    @extend %icon;
}
.icon-phone-2--disabled:before {
    content: "\e92c";
    color: #fff;
    @extend %icon;
}
.icon-phone-2--hover:before {
    content: "\e92d";
    color: #fff;
    @extend %icon;
}
.icon-chat--active:before {
    content: "\e92e";
    color: #900;
    @extend %icon;
}
.icon-chat--default:before {
    content: "\e92f";
    color: #e76525;
    @extend %icon;
}
.icon-chat--disabled:before {
    content: "\e930";
    color: #b2bcc1;
    @extend %icon;
}
.icon-chat--hover:before {
    content: "\e931";
    color: #e76525;
    @extend %icon;
}
.icon-chat-2--active:before {
    content: "\e932";
    color: #fff;
    @extend %icon;
}
.icon-chat-2--default:before {
    content: "\e933";
    color: #fff;
    @extend %icon;
}
.icon-chat-2--hover:before {
    content: "\e934";
    color: #fff;
    @extend %icon;
}
.icon-mail-2--active:before {
    content: "\e935";
    color: #fff;
    @extend %icon;
}
.icon-mail-2--disabled:before {
    content: "\e936";
    color: #fff;
    @extend %icon;
}
.icon-chat-2--disabled:before {
    content: "\e937";
    color: #fff;
    @extend %icon;
}
.icon-location--active:before {
    content: "\e938";
    color: #900;
    @extend %icon;
}
.icon-location--default:before {
    content: "\e939";
    color: #e76525;
    @extend %icon;
}
.icon-location--hover:before {
    content: "\e93a";
    color: #e76525;
    @extend %icon;
}
.icon-map-pin--default:before {
    content: "\e93b";
    color: #e76525;
    @extend %icon;
}
.icon-map-pin--hover:before {
    content: "\e93c";
    color: #900;
    @extend %icon;
}
.icon-map-pin-2--default:before {
    content: "\e93d";
    color: #0f9929;
    @extend %icon;
}
.icon-map-pin-2--hover:before {
    content: "\e93e";
    color: #006c14;
    @extend %icon;
}
.icon-map-pin-3--default:before {
    content: "\e93f";
    color: #004a86;
    @extend %icon;
}
.icon-map-pin-3--hover:before {
    content: "\e940";
    @extend %icon;
}
.icon-favourite--active:before {
    content: "\e941";
    color: #900;
    @extend %icon;
}
.icon-favourite--default:before {
    content: "\e942";
    color: #e76525;
    @extend %icon;
}
.icon-favourite--disabled:before {
    content: "\e943";
    color: #b2bcc1;
    @extend %icon;
}
.icon-favourite--hover:before {
    content: "\e944";
    color: #e76525;
    @extend %icon;
}
.icon-favourite-2--default:before {
    content: "\e945";
    color: #fff;
    @extend %icon;
}
.icon-favourite-2--hover:before {
    content: "\e946";
    color: #fff;
    @extend %icon;
}
.icon-location--disabled:before {
    content: "\e947";
    color: #b2bcc1;
    @extend %icon;
}
.icon-location-2--active:before {
    content: "\e948";
    color: #fff;
    @extend %icon;
}
.icon-location-2--default:before {
    content: "\e949";
    color: #fff;
    @extend %icon;
}
.icon-location-2--disabled:before {
    content: "\e94a";
    color: #fff;
    @extend %icon;
}
.icon-location-2--hover:before {
    content: "\e94b";
    color: #fff;
    @extend %icon;
}
.icon-checkbox--active .path1:before {
    content: "\e94c";
    color: rgb(231,101,37);
    @extend %icon;
}
.icon-checkbox--active .path2:before {
    content: "\e94d";
    margin-left: -1em;
    color: rgb(255,255,255);
    @extend %icon;
}
.icon-checkbox--default:before {
    content: "\e94e";
    color: $dark-grey;
    @extend %icon;
}
.icon-checkbox--disabled:before {
    content: "\e94f";
    color: #b2bcc1;
    @extend %icon;
}
.icon-checkbox--hover:before {
    content: "\e950";
    color: #e76525;
    @extend %icon;
}
.icon-checkbox--active:before {
    content: "\e951";
    color: $dark-grey;
    @extend %icon;
}
.icon-checkbox--active--white:before,
.icon-checkbox--default--white:before {
    color: $white;
}
.icon-checkbox-2--active:before {
    content: "\e951";
    color: #fff;
    @extend %icon;
}
.icon-checkbox-2--default:before {
    content: "\e952";
    color: #fff;
    @extend %icon;
}
.icon-checkbox-2--disabled:before {
    content: "\e953";
    color: #fff;
    @extend %icon;
}
.icon-checkbox-2--hover:before {
    content: "\e954";
    color: #fff;
    @extend %icon;
}
.icon-favourite-2--active:before {
    content: "\e955";
    color: #fff;
    @extend %icon;
}
.icon-favourite-2--disabled:before {
    content: "\e956";
    color: #fff;
    @extend %icon;
}
.icon-tick--default:before {
    content: "\e957";
    color: #e76525;
    @extend %icon;
}
.icon-tick-2--default .path1:before {
    content: "\e958";
    color: rgb(0,0,0);
    opacity: 0.1;
    @extend %icon;
}
.icon-tick-2--default .path2:before {
    content: "\e959";
    margin-left: -1em;
    color: rgb(255,255,255);
    @extend %icon;
}
.icon-radio--active:before {
    content: "\e9b6";
    color: #e76525;
    @extend %icon;
}
.icon-radio--default:before {
    content: "\e9b7";
    color: #e76525;
    @extend %icon;
}
.icon-radio--disabled:before {
    content: "\e9b8";
    color: #b2bcc1;
    @extend %icon;
}
.icon-radio--hover:before {
    content: "\e9b9";
    color: #e76525;
    @extend %icon;
}
.icon-radio-2--default:before {
    content: "\e9ba";
    color: #fff;
    @extend %icon;
}
.icon-error--active:before {
    content: "\e9bb";
    color: #900;
    @extend %icon;
}
.icon-error--default:before {
    content: "\e9bc";
    color: #e76525;
    @extend %icon;
}
.icon-error--disabled:before {
    content: "\e9bd";
    color: #b2bcc1;
    @extend %icon;
}
.icon-error--error:before {
    content: "\e9be";
    color: #ce1616;
    @extend %icon;
}
.icon-error--hover:before {
    content: "\e9bf";
    color: #e76525;
    @extend %icon;
}
.icon-error-2--active:before {
    content: "\e9c0";
    color: #fff;
    @extend %icon;
}
.icon-error-2--default:before {
    content: "\e9c1";
    color: #fff;
    @extend %icon;
}
.icon-error-2--disabled:before {
    content: "\e9c2";
    color: #fff;
    @extend %icon;
}
.icon-error-2--hover:before {
    content: "\e9c3";
    color: #fff;
    @extend %icon;
}
.icon-radio-2--active:before {
    content: "\e9c4";
    color: #fff;
    @extend %icon;
}
.icon-radio-2--disabled:before {
    content: "\e9c5";
    color: #fff;
    @extend %icon;
}
.icon-radio-2--hover:before {
    content: "\e9c6";
    color: #fff;
    @extend %icon;
}
.icon-basket--active:before {
    content: "\e9c7";
    color: #900;
    @extend %icon;
}
.icon-basket--default:before {
    content: "\e9c8";
    @extend %icon;
}
.icon-basket--disabled:before {
    content: "\e9c9";
    color: #b2bcc1;
    @extend %icon;
}
.icon-basket--hover:before {
    content: "\e9ca";
    color: #e76525;
    @extend %icon;
}
.icon-basket-2--active:before {
    content: "\e9cb";
    color: #fff;
    @extend %icon;
}
.icon-basket-2--default:before {
    content: "\e9cc";
    color: #fff;
    @extend %icon;
}
.icon-basket-2--disabled:before {
    content: "\e9cd";
    color: #fff;
    @extend %icon;
}
.icon-basket-2--hover:before {
    content: "\e9ce";
    color: #fff;
    @extend %icon;
}
.icon-download--active:before {
    content: "\e9cf";
    color: #900;
    @extend %icon;
}
.icon-download--default:before {
    padding-left: 1px;
    content: "\e9d0";
    color: #e76525;
    @extend %icon;
}
.icon-download--disabled:before {
    content: "\e9d1";
    color: #b2bcc1;
    @extend %icon;
}
.icon-download--hover {
    padding-left: 1px;
    content: "\e9d2";
    color: #e76525;
    @extend %icon;
}
.icon-download-2--active:before {
    content: "\e9d7";
    color: #fff;
    @extend %icon;
}
.icon-download-2--default:before {
    content: "\e9d8";
    color: #fff;
    @extend %icon;
}
.icon-download-2--disabled:before {
    content: "\e9d9";
    color: #fff;
    @extend %icon;
}
.icon-download-2--hover:before {
    content: "\e9da";
    color: #fff;
    @extend %icon;
}
.icon-contract--active:before {
    content: "\e9db";
    color: #900;
    @extend %icon;
}
.icon-contract--default:before {
    content: "\e9dc";
    color: #e76525;
    @extend %icon;
}
.icon-contract--disabled:before {
    content: "\e9dd";
    color: #b2bcc1;
    @extend %icon;
}
.icon-contract--hover:before {
    content: "\e9de";
    color: #e76525;
    @extend %icon;
}
.icon-contract-2--active:before {
    content: "\e9df";
    color: #fff;
    @extend %icon;
}
.icon-contract-2--default:before {
    content: "\e9e0";
    color: #fff;
    @extend %icon;
}
.icon-contract-2--disabled:before {
    content: "\e9e1";
    color: #fff;
    @extend %icon;
}
.icon-contract-2--hover:before {
    content: "\e9e2";
    color: #fff;
    @extend %icon;
}
.icon-expand--active:before {
    content: "\e9e3";
    color: #900;
    @extend %icon;
}
.icon-expand--default:before {
    content: "\e9e4";
    color: #e76525;
    @extend %icon;
}
.icon-expand--hover:before {
    content: "\e9e5";
    color: #e76525;
    @extend %icon;
}
.icon-expand--disabled:before {
    content: "\e9e6";
    color: #b2bcc1;
    @extend %icon;
}
.icon-expand-2--active:before {
    content: "\e9e7";
    color: #fff;
    @extend %icon;
}
.icon-expand-2--default:before {
    content: "\e9e8";
    color: #fff;
    @extend %icon;
}
.icon-expand-2--disabled:before {
    content: "\e9e9";
    color: #fff;
    @extend %icon;
}
.icon-expand-2--hover:before {
    content: "\e9ea";
    color: #fff;
    @extend %icon;
}
// .icon-link--default:before {
//     content: "\e9eb";
//     color: #e76525;
//     @extend %icon;
// }
// .icon-link--hover:before {
//     content: "\e9ec";
//     color: #e76525;
//     @extend %icon;
// }
// .icon-link--active:before {
//     content: "\e9ed";
//     color: #900;
//     @extend %icon;
// }
// .icon-link--disabled:before {
//     content: "\e9ee";
//     color: #b2bcc1;
//     @extend %icon;
// }
// .icon-link-2--active:before {
//     content: "\e9ef";
//     color: #fff;
//     @extend %icon;
// }
// .icon-link-2--default:before {
//     content: "\e9f0";
//     color: #fff;
//     @extend %icon;
// }
// .icon-link-2--disabled:before {
//     content: "\e9f1";
//     color: #fff;
//     @extend %icon;
// }
// .icon-link-2--hover:before {
//     content: "\e9f2";
//     color: #fff;
//     @extend %icon;
// }
.icon-close--default:before {
    content: "\e9f3";
    color: #e76525;
    @extend %icon;
}
.icon-close--active:before {
    content: "\e9f4";
    color: #900;
    @extend %icon;
}
.icon-close--disabled:before {
    content: "\e9f5";
    color: #b2bcc1;
    @extend %icon;
}
.icon-close--hover:before {
    content: "\e9f6";
    color: #e76525;
    @extend %icon;
}
.icon-close-2--active:before {
    content: "\e9f7";
    color: #fff;
    @extend %icon;
}
.icon-close-2--default:before {
    content: "\e9f8";
    color: #fff;
    @extend %icon;
}
.icon-close-2--disabled:before {
    content: "\e9f9";
    color: #fff;
    @extend %icon;
}
.icon-close-2--hover:before {
    content: "\e9fa";
    color: #fff;
    @extend %icon;
}
.icon-play--active:before {
    content: "\e9fb";
    color: #900;
    @extend %icon;
}
.icon-play--default:before {
    content: "\e9fc";
    color: #e76525;
    @extend %icon;
}
.icon-play--disabled:before {
    content: "\e9fd";
    color: #b2bcc1;
    @extend %icon;
}
.icon-play--hover:before {
    content: "\e9fe";
    color: #e76525;
    @extend %icon;
}
.icon-play-2--active:before {
    content: "\e9ff";
    color: #fff;
    @extend %icon;
}
.icon-play-2--default:before {
    content: "\ea00";
    color: #fff;
    @extend %icon;
}
.icon-play-2--disabled:before {
    content: "\ea01";
    color: #fff;
    @extend %icon;
}
.icon-play-2--hover:before {
    content: "\ea02";
    color: #fff;
    @extend %icon;
}
.icon-info--active:before {
    content: "\ea03";
    color: #900;
    @extend %icon;
}
.icon-info--default:before {
    content: "\ea04";
    color: #e76525;
    @extend %icon;
}
.icon-info--disabled:before {
    content: "\ea05";
    color: #b2bcc1;
    @extend %icon;
}
.icon-info--hover:before {
    content: "\ea06";
    color: #e76525;
    @extend %icon;
}
.icon-info-2--default:before {
    content: "\ea07";
    color: #fff;
    @extend %icon;
}
.icon-info-2--hover:before {
    content: "\ea08";
    color: #fff;
    @extend %icon;
}
.icon-info-2--active:before {
    content: "\ea09";
    color: #fff;
    @extend %icon;
}
.icon-info-2--disabled:before {
    content: "\ea0a";
    color: #fff;
    @extend %icon;
}
.icon-menu--active:before {
    content: "\ea0b";
    color: #900;
    @extend %icon;
}
.icon-menu--default:before {
    content: "\ea0c";
    color: #e76525;
    @extend %icon;
}
.icon-menu--disabled:before {
    content: "\ea0d";
    color: #b2bcc1;
    @extend %icon;
}
.icon-menu--hover:before {
    content: "\ea0e";
    color: #e76525;
    @extend %icon;
}
.icon-facebook--active:before {
    content: "\ea0f";
    color: #900;
    @extend %icon;
}
.icon-facebook--default:before {
    content: "\ea10";
    color: #e76525;
    @extend %icon;
}
.icon-facebook--disabled:before {
    content: "\ea11";
    color: #b2bcc1;
    @extend %icon;
}
.icon-facebook--hover:before {
    content: "\ea12";
    color: #e76525;
    @extend %icon;
}
.icon-facebook-2--active:before {
    content: "\ea13";
    color: #fff;
    @extend %icon;
}
.icon-facebook-2--default:before {
    content: "\ea14";
    color: #fff;
    @extend %icon;
}
.icon-facebook-2--disabled:before {
    content: "\ea15";
    color: #fff;
    @extend %icon;
}
.icon-facebook-2--hover:before {
    content: "\ea16";
    color: #fff;
    @extend %icon;
}
.icon-instagram--default:before {
    content: "\ea1a";
    color: #e76525;
    @extend %icon;
}
.icon-instagram--hover:before {
    content: "\ea1b";
    color: #e76525;
    @extend %icon;
}
.icon-linkedin--active:before {
    content: "\ea17";
    color: #900;
    @extend %icon;
}
.icon-linkedin--default:before {
    content: "\ea18";
    color: #e76525;
    @extend %icon;
}
.icon-linkedin--hover:before {
    content: "\ea19";
    color: #e76525;
    @extend %icon;
}
.icon-linkedin--disabled:before {
    content: "\ea1c";
    color: #b2bcc1;
    @extend %icon;
}
.icon-linkedin-2--active:before {
    content: "\ea1d";
    color: #fff;
    @extend %icon;
}
.icon-linkedin-2--default:before {
    content: "\ea1e";
    color: #fff;
    @extend %icon;
}
.icon-linkedin-2--disabled:before {
    content: "\ea1f";
    color: #fff;
    @extend %icon;
}
.icon-linkedin-2--hover:before {
    content: "\ea20";
    color: #fff;
    @extend %icon;
}
.icon-instagram--active:before {
    content: "\ea21";
    color: #900;
    @extend %icon;
}
.icon-instagram--disabled:before {
    content: "\ea22";
    color: #b2bcc1;
    @extend %icon;
}
.icon-instagram-2--active:before {
    content: "\ea23";
    color: #fff;
    @extend %icon;
}
.icon-instagram-2--default:before {
    content: "\ea24";
    color: #fff;
    @extend %icon;
}
.icon-instagram-2--disabled:before {
    content: "\ea25";
    color: #fff;
    @extend %icon;
}
.icon-instagram-2--hover:before {
    content: "\ea26";
    color: #fff;
    @extend %icon;
}
.icon-twitter--active:before {
    content: "\ea27";
    color: #900;
    @extend %icon;
}
.icon-twitter--default:before {
    content: "\ea28";
    color: #e76525;
    @extend %icon;
}
.icon-twitter--disabled:before {
    content: "\ea29";
    color: #b2bcc1;
    @extend %icon;
}
.icon-twitter--hover:before {
    content: "\ea2a";
    color: #e76525;
    @extend %icon;
}
.icon-twitter-2--active:before {
    content: "\ea2b";
    color: #fff;
    @extend %icon;
}
.icon-twitter-2--default:before {
    content: "\ea2c";
    color: #fff;
    @extend %icon;
}
.icon-twitter-2--hover:before {
    content: "\ea2d";
    color: #fff;
    @extend %icon;
}
.icon-pinterest--active:before {
    content: "\ea2e";
    color: #900;
    @extend %icon;
}
.icon-pinterest--default:before {
    content: "\ea2f";
    color: #e76525;
    @extend %icon;
}
.icon-pinterest--disabled:before {
    content: "\ea30";
    color: #b2bcc1;
    @extend %icon;
}
.icon-pinterest--hover:before {
    content: "\ea31";
    color: #e76525;
    @extend %icon;
}
.icon-pinterest-2--default:before {
    content: "\ea32";
    color: #fff;
    @extend %icon;
}
.icon-pinterest-2--hover:before {
    content: "\ea33";
    color: #fff;
    @extend %icon;
}
.icon-twitter-2--disabled:before {
    content: "\ea34";
    color: #fff;
    @extend %icon;
}
.icon-pinterest-2--active:before {
    content: "\ea35";
    color: #fff;
    @extend %icon;
}
.icon-pinterest-2--disabled:before {
    content: "\ea36";
    color: #fff;
    @extend %icon;
}
.icon-youtube--active:before {
    content: "\ea37";
    color: #900;
    @extend %icon;
}
.icon-youtube--default:before {
    content: "\ea38";
    color: #e76525;
    @extend %icon;
}
.icon-youtube--disabled:before {
    content: "\ea39";
    color: #b2bcc1;
    @extend %icon;
}
.icon-youtube--hover:before {
    content: "\ea3a";
    color: #e76525;
    @extend %icon;
}
.icon-youtube-2--default:before {
    content: "\ea3b";
    color: #fff;
    @extend %icon;
}
.icon-youtube-2--active:before {
    content: "\ea3c";
    color: #fff;
    @extend %icon;
}
.icon-youtube-2--disabled:before {
    content: "\ea3d";
    color: #fff;
    @extend %icon;
}
.icon-youtube-2--hover:before {
    content: "\ea3e";
    color: #fff;
    @extend %icon;
}
.icon-arrow-down--active:before {
    content: "\ea3f";
    color: #900;
    @extend %icon;
}
.icon-arrow-down--default:before {
    content: "\ea40";
    color: #e76525;
    @extend %icon;
}
.icon-arrow-down--disabled:before {
    content: "\ea41";
    color: #b2bcc1;
    @extend %icon;
}
.icon-arrow-down--hover:before {
    content: "\ea42";
    color: #e76525;
    @extend %icon;
}
.icon-search--default:before {
    content: "\ea43";
    @extend %icon;
}
.icon-arrow-down--error:before {
    content: "\ea44";
    color: #cf1616;
    @extend %icon;
}
.icon-arrow-down-2--active:before {
    content: "\ea45";
    color: #fff;
    @extend %icon;
}
.icon-arrow-down-2--default:before {
    content: "\ea46";
    color: #fff;
    @extend %icon;
}
.icon-arrow-down-2--disabled:before {
    content: "\ea47";
    color: #fff;
    @extend %icon;
}
.icon-arrow-down-2--hover:before {
    content: "\ea48";
    color: #fff;
    @extend %icon;
}
.icon-search--active:before {
    content: "\ea49";
    color: #b2bcc1;
    @extend %icon;
}
.icon-search--disabled:before {
    content: "\ea4a";
    color: #b2bcc1;
    @extend %icon;
}
.icon-search--hover:before {
    content: "\ea4b";
    //color: #e76525;
    color: #b2bcc1;
    @extend %icon;
}
