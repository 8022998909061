.copy-pagination {
	position: relative;
	@include rem(padding, $medium-padding 0);

	&__navigation {
		display: none;
		margin: 0;
		// width: calc(100% - 28px);
		left:0;
		right:0;
		position:absolute;
		z-index:index($elements, article-navigation);
		@include rem(top, $large-margin);
		@include breakpoint(desktop-big) {
			@include rem(top, 40px);
		}
		
	    @include breakpoint(tablet){
			@include rem(max-width, 1498px);
			display: block;
			margin: auto;
	    }
	    @include breakpoint(desktop){
	    	@include rem(max-width, 1598px);
		}
			
		&--mobile {
			@include rem(margin-top, 25px);
			display: block;

			@include breakpoint(tablet){
				display: none;
			}
		}
    }
    &__header {
		&__content {
			@include breakpoint(desktop) {
				@include rem(padding-top, 15px);
			}
		}

		@include breakpoint(tablet) {
			@include rem(margin, 0 100px $medium-margin 100px);
		}

		@include breakpoint(desktop-big) {
			@include rem(margin, 0 $x-large-margin $medium-margin $x-large-margin);
		}

		@media(min-width:2560px) {
			@include rem(margin, 0 0 $medium-margin);
		}
    }

    &__intro {
		color: $orange;
		
		&--black {
			color: $black;
		}
    }

	&__title {
        @include fontSize(mediumTitle);
        @include rem (margin, 0);
	}

	&__copy {
		@include fontSize(smallTitle);
		@include rem (margin, 15px 0 0);
	}

	&--v2 {
		.copy-pagination__header {
			margin: 0;

			@include breakpoint(tablet) {
				@include rem(margin, 0 100px $medium-margin 100px);
			}

			@include breakpoint(desktop-big) {
				@include rem(margin, 0 $x-large-margin $medium-margin $x-large-margin);
			}

			@media(min-width:2560px) {
				@include rem(margin, 0 0 $medium-margin);
			}
		}

		.copy-pagination__copy {
			@include fontSize(baseSize);

			@include breakpoint(tablet){
				@include fontSize(smallTitle);
			}
		}
	}
}