.card-payment {
    @include rem(margin, 5px auto 0 auto!important);

    @include breakpoint(tablet) {
        @include rem(margin, 10px auto!important);
    }

    &__required {
        display: block;
        @include rem(margin, 0 0 28px 0);

        @include breakpoint(tablet) {
            display: none;
        }
    }

    &__accepted-cards {
        @include fontSize(paragraph);
        @include rem(margin-bottom, 25px);

        @include breakpoint(tablet) {
            @include rem(margin-bottom, 20px);
        }

        p {
            @include rem(margin-right, 10px);
            @include rem(margin-bottom, 5px);

            @include breakpoint(tablet) {
                @include rem(margin-bottom, 9px);
            }

            @include breakpoint(desktop) {
                @include rem(margin-bottom, 14px);
            }
        }

        img {
            @include rem(margin-right, 4px);
        }

        p,
        img {
            display: inline-block;
        }
    }

    &__row {
        .grid__cell {
            .form__input-wrapper {
                @include rem(margin-bottom, 0);
            }

            &:first-of-type {
                width: 58.33333%;

                @include breakpoint(desktop) {
                    width: 41.66667%;
                }
            }

            &:last-of-type {
                width: 16.66667%;
            }
        }

        & + .parsley-errors-list li {
            @include rem(margin-top, 0);
        }
    }

    &__expiration {
        @include fontSize(formLabel);
        @include rem(margin-right, 80px);
        @include rem(margin-bottom, 0 !important);

        .parsley-errors-list {
            @include rem(margin-bottom, 0);
            min-width: 400px;

            li {
                @include rem(margin-bottom, 0);
            }
        }

        &:after {
            color: $grey;
            content: 'mm/yy';
            position: absolute;
            @include rem(right, -41px);
            @include rem(top, 15px);
            

            @include breakpoint(tablet) {
                @include rem(right, -41px);
                @include rem(top, 16px);
            }

            @include breakpoint(desktop) {
                @include rem(right, -48px);
                @include rem(top, 12px);
            }
        }
    }

    &__question-icon {
        position: absolute;
        @include rem(right, -40px);
        @include rem(top, 8px);

        @include breakpoint(tablet) {
            @include rem(right, -38px);
            @include rem(top, 15px);
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__tooltip {
        display: none;
        @include rem(margin, 10px 0 30px 0);

        @include breakpoint(tablet) {
            @include rem(margin, 10px 0 30px 0);
        }

        @include breakpoint(desktop) {
            @include rem(margin, 20px 0 30px 0);
        }

        p {
            @include rem(margin, 0 0 16px 0);
        }
    }

    .checkbox {

        &__toggle {
            @include rem(margin, 7px 0 18px 0!important);

            @include breakpoint(tablet) {
                @include rem(margin, 7px 0 38px 0!important);
            }
        }

        &__item {
            label {
                color: $black!important;
                margin-right: 0!important;
                @include rem(padding-left, 30px!important);
            }
        }
    }

    .card-payment__row ~ .checkbox__toggle {
        @include rem(margin-top, 35px !important);
    }

    .card-payment__row ~ .parsley-errors-list {
        @include rem(margin-top, 15px);

        & ~ .checkbox__toggle {
            @include rem(margin-top, 7px !important);
        }
    }

    &__small-print {
        &:last-child {
            @include rem(margin, 30px 0 0 0);

            @include breakpoint(desktop) {
                @include rem(margin, 0);
            }
        }
    }
    .form {
        &__additional-fields {
            display: none;
            @include rem(margin-top, $medium-margin);
        }

        &__row {
            margin: 0;
        }
    }

    .radioButton {
        &__item {
            &--horizontal {
                margin-bottom: 0!important;
            }
        }
    }
}
