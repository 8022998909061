
#scrollUp {
    bottom: 10%;
    right: 1.5%;
    position: relative;
    visibility:hidden;
    @extend .go-up;
    //Overriding js!
    z-index: 3!important;

    span {
    	text-align: center;
    	left:0;
        @include rem(font-size, 35px);
    }
    @include breakpoint(desktop){
         visibility:visible;
         opacity: 0;
    }
}


