.module__hotlink-banner {
  background: $green;
  @include rem(padding, 30px 0);
  text-align: center;

  .hotlink-banner__title {
    @include fontSize(signUpTitle);
    @include rem(margin-bottom, 12px);
    color: $white;
  }
}