
.main-navigation,
.language-navigation {
  display: none;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  margin: auto;
  position: absolute;
  z-index: index($elements, navigation);
  @include rem(padding-right, 30px);
  @include rem(height, 23px);
  @include clearfix();
  @include fontSize(mediumNav);

  @include breakpoint(desktop) {
    display: block;
  }

  @media screen and (min-width: 960px) and (max-width: 1249px) {
    @include fontSize(smallNav);
    line-height: 1.8;
  }

  > .main-navigation__list,
  > .language-navigation__list {

    float: right;

    > .main-navigation__item,
    > .language-navigation__item {
      > a {
        display: block;
        position: relative;
        @include rem(height, 61px);

        &:hover, &.is-on {
          color: $light-grey;

          .main-navigation__hover-line {
            @include transition(height .3s ease-out);
            @include rem(height, 6px);
          }

          &.search {
            .main-navigation__hover-line {
              bottom: 0.5rem;
            }
          }
        }
      }

    }
  }

  &__item {
    border-right: none;

    &.two--columns {
      a {
        display: block;
        @include rem(margin-bottom, 5px);
      }
    }

    @include breakpoint(tablet) {
      @include rem(margin-left, 15px);
    }
    @include breakpoint(desktop-big) {
      @include rem(margin-left, 30px);
    }
  }

  &__link {
    position: relative;

    &--arrow {
      display: block;

      &:after {
        @extend .icon-arrow-down--default:before;
        display: inline-block;
        transform: rotate(-90deg);
        color: $black;
        @include rem(font-size, 13px);
        @include rem(margin-left, $tiny-margin);
      }

      @include breakpoint(desktop) {
        margin-bottom: 0;
      }
    }

    &.search {
      @include rem(margin-top, $tiny-margin);
    }
  }

  &__hover-line {
    display: block;
    height: 0;
    background: $mid-grey;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &__submenu {
    display: none;
    width: 100%;
    left: 0;
    color: $off-black;
    background: $megamenu;
    @include transition(all .3s ease-out);
    @include rem(padding, $medium-padding $gutter $large-padding);
    @include rem(top, 61px);

    @include breakpoint(desktop) {
      border-top: solid $light-grey $border-thin;
      position: absolute;
      @include rem(padding-left, 30px);
    }

    &__header {
      @include breakpoint(desktop) {
        @include rem(font-size, 22px);
      }
    }

    > ul > li {
      @include breakpoint(desktop) {
        padding-bottom: 99999px;
        margin-bottom: -99999px;
        border-left: 1px solid $mid-grey;
        @include rem(padding-left, $medium-padding);
        &:first-child {
          border-left: none;
          @include rem(padding-left, $gutter);
        }

        .main-navigation__item {
          @include rem(margin-bottom, $tiny-margin);

          &::last-child {
            margin: 0;
          }
        }
      }
    }

    .main-navigation__list,
    .language-navigation__list {
      list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
      @include rem(padding, $medium-padding 0 0);
    }
    .main-navigation__list{
      &.is-on{
        flex-wrap: wrap;
      }
    }

    .main-navigation__item,
    .language-navigation__item {
      text-align: left;
      margin-left: 0;
      //@include rem(margin-bottom, $tiny-margin);
      &:last-child {
        //margin: 0;
      }

      .main-navigation__link,
      .language-navigation__link {
        color: $off-black;

        &:hover {
          color: $grey;
        }
      }
    }

    &--search {
      @include rem(padding, 30px 31px);

      &.is-on {
        display: none;
      }

      .form {
        &__container {
          white-space: nowrap;
        }

        &__input-wrapper {
          display: table-cell;
          @include rem(padding-right, 18px);
          width: 100%;
        }

        &__text-input {
          border-bottom: $border-thin solid $black;
          text-align: left;
          height: 4.5rem;

          @include placeholder {
            color: $black;
            font-size: 1.8rem;
          }
        }
      }

      span {
        white-space: nowrap;
      }

      .button {
        border: $border-thin solid $black;

        &--search {
          width: auto;
          display: table-cell;
          margin: 0;
          font-size: 1.6rem;

          &:after {
            font-size: 1.5rem;
            @include rem(padding-left, 10px);
            vertical-align: initial;
          }

          &:hover {
            border: $border-thin solid $black;
            background-color: $black;
          }
        }
      }

    }

  }
}

.language-navigation {
  @include breakpoint(desktop) {
    display: none;
  }
}

// Search
.main-navigation__item {
  [data-js-item="search-icon"] {
    cursor: pointer;

    &.is-open {
      & + .main-navigation__submenu--search {
        display: block;
      }
    }
  }
}



