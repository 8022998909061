.review--order-table {
    @include rem(margin-top, 15px);
    
    .module__header {
        @include rem(margin-top, 0);
        @include rem(margin-bottom, $medium-margin);

        @include breakpoint(desktop) {
            @include rem(margin-top, 25px);
            @include rem(margin-bottom, $medium-large-margin);
        }
    }

    .module__form--title {
        @include rem(font-size, 18px !important);
        line-height: 1.4; 

        @include breakpoint(desktop) {
            @include rem(font-size, 25px !important);
            line-height: initial;
        }
    }

    .order-summary {
        @include rem(padding, 15px 0 $large-padding);
        float: right;
        width: 100%;
        max-width: 461px;

        @include breakpoint(desktop) {
            @include rem(padding, $medium-padding 0 $medium-padding);
        }

        &__product {
            @include rem(padding, $medium-padding 0);

            @include breakpoint(desktop) {
                @include rem(padding-top, 38px);
                @include rem(padding-bottom, 0);
            }

            &:last-of-type {
                @include rem(padding-bottom, 35px);

                @include breakpoint(desktop) {
                    @include rem(padding-bottom, 25px);
                }
            }
        }

        &__details {
            p {
                @include rem(margin-bottom, 0);

                @include breakpoint(desktop) {
                    @include rem(margin-bottom, 3px);
                }
            }

            .order-summary__price {
                @include rem(margin-top, 16px);
            }
        }

        &:first-of-type {
            @include rem(padding, 0);
        }

        &:last-of-type {
            border-bottom: 0;

            @include breakpoint(desktop) {
                border-bottom: 1px solid $light-grey;
            }
        }
    }

    .order-summary__details {
        @include rem(padding, 0 7px);
    }

    .confirmation-table__contact-details {
        &--dark {
            @include rem(margin, 0 0 $small-margin);
            @include rem(padding, $medium-padding);

            @include breakpoint(desktop) {
                @include rem(padding-top, 33px);
            }
    
            p {
                @include rem(margin-bottom, $tiny-margin);
                @include rem(font-size, 18px);

                @include breakpoint(desktop) {
                    @include rem(margin-bottom, $medium-margin);
                }
            }

            .six--columns:first-of-type {
                p {
                    @include rem(margin-bottom, 15px);
                    font-weight: 500;

                    @include breakpoint(desktop) {
                        @include rem(margin-bottom, 0);
                    }
                }
            }
        }

        &__address-shipping {
            @include rem(padding, 0);
        }
    }

    .module__copy--large {
        @include rem(font-size, 22px !important);
        line-height: initial;

        @include breakpoint(desktop) {
            @include rem(font-size, 25px !important);
            line-height: 1;
        }
    }

    .divider-module .container {
        width: 100%;
    }

    .confirmation-ctas {
        @include rem(margin-top, 0);
        @include rem(margin-bottom, 15px);

        @include breakpoint(desktop) {
            @include rem(margin-bottom, $medium-margin);
        }

        .container {
            width: 100%;
        }
    
        .button {
            @include rem(margin-top, 5px);
            @include rem(margin-right, 15px);
            color: $orange;
            &--default,
            &:hover {
                color: $white;
            }
        }

        .button + .button {
            @include rem(margin-top, $medium-margin);

            @include breakpoint(desktop-big) {
                @include rem(margin-top, 0);
            }
        }
    }
}
