.module {
  @include rem(margin, $small-margin auto);

  &__hide {
    display: none !important;
  }

  &__title, h3 {
    @extend .medium-title;
    margin-top: 0;
    line-height: 1;
  }

  &__subtitle, h4 {
    @extend .small-title;
  }

  &__form {
    &--title {
      @include fontSize(formTitle);
      margin-top: 0;
    }

    &--subtitle {
      @include fontSize(formSubTitle);
    }
  }

  &__standout {
    @extend .lead;

    &--emphasise {
      @include rem(margin-top, 25px);

      p {
        @include rem(margin-bottom, 0);
        font-weight: bold;
      }
    }
  }

  &__copy {
    &--small {
      @include fontSize(paragraph);
      width: 100%;
      float: right;

      @include breakpoint(desktop) {
        width: calc(100% - 2rem);
      }
    }

    &--large {
      margin-bottom: 0;
      @include fontSize(sectionTitle);
      @include breakpoint(tablet) {
        @include rem(margin-bottom, 14px);
      }
    }

    &--full-width {
      width: 100%;
    }

    &--right {
      text-align: right;
    }
  }

  &__price {
    @include rem(margin-bottom, 15px);
    @include fontSize(productPrice);

    &--subtotal {
      @include fontSize(subtotalPrice);
    }

    &--total {
      @include fontSize(totalPrice);
    }
  }

  &__list {
    &__item {
      @include rem(margin, 0 0 $small-margin);

      a {
        color: $orange;
        padding-left: 36px;
        position: relative;
        @include breakpoint(desktop) {
          position: initial;
          padding-left: 0;
        }

        &:hover:before {
          @extend .icon-link-down--default:before;
        }

        &:focus:before {
          @extend .icon-link-down--hover:before;
        }

        @include breakpoint(desktop) {
          &:hover:before {
            content: "\e91a";
          }
        }
      }
    }
  }

  &__header {
    &--bordered-bottom {
      @include rem(margin-bottom, $medium-large-margin);
      border-bottom: solid $border-thin $light-grey;
    }

    span {
      @include rem(font-size, 16px);
      float: none;

      @include breakpoint(tablet) {
        @include rem(font-size, 14px);
        float: right;
        line-height: 2.3;
      }
    }
  }

  &__links {
    @include rem(margin, $small-margin 0 0 0);

    .button {
      width: 100%;

      @include breakpoint(tablet) {
        width: auto;
      }
    }

    &--right {
      text-align: left;

      @include breakpoint(tablet) {
        text-align: right;
      }
    }
  }

  &__dropdown {
    @include fontSize(inputLabel);
    @include rem(margin-top, 15px);

    .dropdown {
      @extend .list-inline;
      @extend .list-reset;

      &__item {
        width: 100%;
      }
    }

    .fs-dropdown {
      &-selected {
        color: $orange;
        border-bottom: 1px solid $orange;
        @include rem(margin, 0 0 45px);

        &:before {
          color: $orange;
        }
      }

      &-item {
        color: $orange;
        @include rem(padding-bottom, 22px);

        &:last-child {
          border-bottom: 1px solid $orange;
          @include rem(margin-bottom, 33px);
          @include rem(padding-bottom, 25px);
        }

        @include breakpoint(tablet) {
          @include rem(padding-bottom, 17px);
          &:hover {
            color: $light-grey !important;
          }
        }
      }

      &-open {
        .fs-dropdown {
          &-selected {
            border-bottom: none;
            @include rem(margin-bottom, 10px);
          }
        }
      }
    }
  }

  &__list {
    @include list-reset;

    &__link {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: right;
      -ms-flex-align: right;
      align-items: right;
      @include fontSize(hotLinks);

      &:before {
        font-size: 2.5rem;
        @extend .icon-link-down--default:before;
        @include rem(margin-right, $small-margin);
        transform: rotate(-90deg);
        margin-top: 0.1rem;
        position: absolute;
        left: 0px;
        top: 2px;
        @include breakpoint(desktop) {
          position: initial;
          left: initial;
          top: initial;
        }

        &:hover {
          @extend .icon-link-down--hover:before;
        }

        @include breakpoint(tablet) {
          @include fontSize(listArrows);
          transform: rotate(360deg);
        }
      }
    }
  }

  &__sidebar {

    @include rem(margin, $medium-margin 0);

    &__title {
      line-height: 1;
      @include rem(margin, 0 0 $medium-margin);
    }

    @include breakpoint(desktop) {
      margin-top: 0
    }

    &__img {
      @include responsiveImage();
    }

    &--bordered-left {
      @include breakpoint(desktop) {
        @include rem(padding-left, $medium-padding);
        border-left: $border-thin $light-grey solid;
      }
    }

    .video-module,
    iframe {
      max-height: 25rem;
    }
  }

  &__banner {
    &--confirmation {
      @include rem(padding, $tiny-padding 0 0);
      background-color: $white;
      color: $black;

      @include breakpoint(desktop) {
        @include rem(padding, $large-padding 0 0);
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }

      .module__title {
        @include rem(padding-left, 60px);

        @include breakpoint(desktop) {
          @include rem(padding-left, 120px);
        }
      }

      .module__header {
        &__wrapper {
          display: inline-block;
          vertical-align: top;

          h2, a, span {
            display: block;

            @include breakpoint(desktop) {
              display: inline-block;
            }
          }

          h2 {
            @include rem(margin-bottom, $tiny-margin);

            @include breakpoint(desktop) {
              @include rem(margin-bottom, $medium-margin);
            }

            @include breakpoint(desktop-big) {
              @include rem(margin-bottom, 25px);
            }
          }

          span {
            float: none;
            line-height: initial;
            @include rem(margin, 10px 0);
            @include rem(margin-left, 0px);
            @include rem(font-size, 25px);

            @include breakpoint(desktop) {
              display: block;
              @include rem(margin-top, 30px);
              @include rem(font-size, 40px);
            }

            @include breakpoint(desktop-big) {
              display: inline-block;
              margin-top: 0;
              @include rem(margin-left, 15px);
              line-height: 1.5;
            }
          }

          &:before {
            @extend .icon-tick--default:before;
            @include rem(font-size, 78px);
            display: inline-block;
            position: absolute;
            left: -21px;
            top: -27px;
            color: $orange;

            @include breakpoint(desktop) {
              @include rem(font-size, 148px);
              left: -38px;
              top: -45px;
            }
          }
        }
      }

      @include breakpoint(large-desktop) {
        @include rem(padding, $large-padding);
      }

      &-copy {
        @include rem(padding, 15px 0 $tiny-padding);

        @include breakpoint(desktop) {
          @include rem(padding, $large-padding 0 $medium-large-padding);
        }
      }
    }

    &--review-order {
      .module__title {
        @include rem(margin-bottom, 0);
        @include rem(padding, 0);
        border-bottom: 0;

        @include breakpoint(desktop) {
          @include rem(margin-bottom, 0);
          @include rem(padding, 0 0 $large-padding);
          border-bottom: 1px solid $light-grey;
        }
      }

    }
  }

  &__cart {
    @include rem(margin-top, $medium-margin !important);

    @include breakpoint(tablet) {
      @include rem(margin-top, $large-margin !important);
    }

    &__product {
      @include rem(margin-bottom, $large-margin);

      &:last-of-type {
        @include breakpoint(desktop) {
          @include rem(margin-bottom, 0);
        }
      }
    }
  }

  &__iframe {
    @include rem(margin-top, $small-margin);

    &--usm-configurator {
      background-color: $light-grey;
      overflow: scroll;

      iframe {
        background-color: #fff;
        border: 0;
        margin-bottom: -7px;
        width: 100%;
        max-width: 100%;
      }

    }

  }

  &__ctas {
    @include rem(margin-top, 15px);

    @include breakpoint(tablet) {
      @include rem(margin-top, $small-margin);
    }

    .button:last-of-type {
      @include rem(margin-top, 15px);

      @include breakpoint(tablet) {
        @include rem(margin-top, 0);
      }
    }

    .button {
      width: 100%;
      -webkit-appearance: none;
      -webkit-border-radius: 0;
      border-radius: 0;

      @include breakpoint(desktop) {
        max-width: 275px;
      }

      &--large {
        @include breakpoint(desktop) {
          max-width: 300px;
        }
      }
    }
  }

  @include breakpoint(desktop) {
    width: 100%;
    @include rem(margin, 0 auto $medium-margin);
  }

  .container {
    height: auto;
  }

  &__overlay {
    @include breakpoint(tablet) {
      padding: 5.5rem 0 5rem;
    }
    color: $white;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .40);
  }

  &__container {
    position: relative;
    width: calc(100% - 28px);
    margin: auto;
    @include clearfix();
    @include breakpoint(mobile) {
      width: 100%;
    }
  }

  &__content {
    .right--columns {
      text-align: right;

      @include breakpoint(desktop) {
        display: inline-block;
        vertical-align: middle;
        text-align: right;
      }
    }

    &--bordered {
      @include rem(padding, 40px 30px);
      border: $border-thin solid $light-grey;
    }

    &--right {
      position: relative;

      @include breakpoint(tablet) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .two--columns {
        @extend .grid__cell;

        @include breakpoint(tablet) {
          @extend .unit-2-12--tablet;
        }
      }

      .three--columns {
        float: none;
        text-align: left;

        @include breakpoint(tablet) {
          @extend .unit-6-12--tablet;
          float: right;
          text-align: right;
        }
        @include breakpoint(desktop) {
          @extend .unit-3-12--desktop;
        }
      }

      .nine--columns {
        text-align: left;
        @include breakpoint(tablet) {
          @extend .unit-6-12--tablet;
          text-align: right;
        }
        @include breakpoint(desktop) {
          @extend .unit-9-12--desktop;
        }
      }

      p {
        line-height: 25px;
      }
    }

    &--list {
      @include rem(padding, $small-padding 0);

      @include breakpoint(desktop) {
        @include rem(padding, $small-padding 0);
      }

      p {
        @include rem(margin-bottom, 0);
      }
    }

    &--last {
      .module__standout {
        @include rem(margin-bottom, 0);
      }
    }
  }

  &__total {
    @include rem(margin-top, $small-margin);

    p {
      margin-bottom: 0;
    }

    .module {
      &--copy {
        line-height: 38px;
      }
    }
  }

  &--center {
    .module__header,
    .module__content {
      text-align: center;
    }
  }

  &--bordered-top {
    .container {
      @include rem(padding-top, $large-padding);
      border-top: solid $border-thin $light-grey;
    }
  }

  &--bordered-bottom {
    .container {
      @include rem(padding-bottom, $large-padding);
      border-bottom: solid $border-thin $light-grey;
    }
  }

  &--no-margin {
    @include rem(margin, 0);

    .module__title {
      @include rem(margin-bottom, $tiny-margin);
    }
  }

  &--images-grid-module {
    .grid-flex-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -7px;
      @include breakpoint(mobile-big) {
        flex-wrap: nowrap;
      }
    }

    .flexed-item {
      padding: 0 7px;
      padding-bottom: 14px;
      flex: 0 0 100%;
      max-width: 100%;

      &:last-child {
        padding-bottom: 0;
      }

      @include breakpoint(mobile-big) {
        flex: 1;
        padding-bottom: 0;
      }

      .lightbox__thumb__image {
        width: 100%;
        height: auto;
      }
    }

    &.two-items {
      .flexed-item {
        &:first-child {
          @include breakpoint(mobile-big) {
            flex: 1;
          }
        }

        &:last-child {
          @include breakpoint(mobile-big) {
            flex: 0 0 33.333333333%;
            max-width: 33.333333333%;
          }
        }

        .lightbox__thumb__image {
          @include breakpoint(mobile-big) {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .js-module--text-background img {
    display: none;
  }


  &.side-panel-ctas--shop {
    &.inverted {
      .grid-flex {
        display: flex;
        flex-wrap: wrap;
        @include breakpoint(tablet) {
          margin: 0 -24px;
          flex-wrap: nowrap;
        }

        .media__wrapper {
          flex: 0 0 100%;
          max-width: 100%;
          line-height: 0;
          margin-bottom: 3rem;

          @include breakpoint(tablet) {
            margin-bottom: 0;
            box-sizing: border-box;
            padding: 0 24px;
            flex: 0 0 50%;
            max-width: 50%;
          }
        }

        .content__wrapper {
          flex: 0 0 100%;
          max-width: 100%;
          @include breakpoint(tablet) {
            box-sizing: border-box;
            padding: 0 24px;
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
      }
    }
  }

}
