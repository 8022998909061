.colour-picker-container {
    @include rem(margin, 21px 0 25px);
    width: 100%;

    @include breakpoint(tablet) {
        @include rem(margin, 12px 0 20px 0);
        overflow: visible;
    }
}

.colour-picker {
    $dir: '../../usm-img/colour-picker';

    $colours: ( 
        "CC24": '#{$dir}/pure-white.jpg', 
        "CC29": '#{$dir}/light-grey.jpg', 
        "CC35": '#{$dir}/usm-matte-silver.jpg', 
        "CC32": '#{$dir}/medium-grey.jpg', 
        "CC33": '#{$dir}/anthracite-grey.jpg', 
        "CC30": '#{$dir}/graphite-black.jpg', 
        "CC34": '#{$dir}/steel-blue.jpg', 
        "CC27": '#{$dir}/gentian-blue.jpg', 
        "CC28": '#{$dir}/usm-green.jpg', 
        "CC25": '#{$dir}/golden-yellow.jpg', 
        "CC26": '#{$dir}/pure-orange.jpg', 
        "CC23": '#{$dir}/usm-ruby-red.jpg', 
        "CC22": '#{$dir}/usm-brown.jpg', 
        "CC31": '#{$dir}/usm-beige.jpg', 
        "TT02": '#{$dir}/pearl-gray-laminate.jpg', 
        "TT41": '#{$dir}/black-linoleum.jpg', 
        "TT24": '#{$dir}/pure-white-powder-coated-mdf.jpg', 
        "TT06": '#{$dir}/black-lacquered-oak-veneer.jpg', 
        "TT07": '#{$dir}/natural-lacquered-oak-veneer.jpg',
        "CC70": '#{$dir}/anthracite.png',
        "CC71": '#{$dir}/lightgrey.png' );

    @include rem(margin, 0);
    width: 100%;

    @include breakpoint(tablet) {
        @include rem(max-width, 290px);
    }

    &__option {
        position: relative;
        display: inline-block;
    }

    input[type="radio"] {
        display: none;

        &:checked + label {
            span {
                &:after {
                    @include rem(font-size, 42px);
                    @include rem(margin, 0 $small-margin);
                    @extend .icon-tick--default:before:before;
                    position: absolute;
                    top: 0;
                    left: -10px;
                    color: $black;

                    @include breakpoint(tablet) {
                        @include rem(font-size, 36px);
                        top: -1px;
                        left: -11px;
                    }
                }
            }

            @each $name, $value in $colours {
                @if ($name == "CC24" or $name == "CC29" or $name == "CC35" or $name == "CC71" or $name == "TT24" or $name == "TT02") {
                    .#{$name} {
                        &:after {
                            color: $black;
                        }
                    }
                }
                @else {
                    .#{$name} {
                        &:after {
                            color: $white;
                        }
                    }
                }
            }

            &.light {
                span {
                    &:after {
                        color: $black;
                    }
                }
            }
        }
        // !&:checked + label
    }

    label {
        cursor: pointer;
        display: inline-block;
        @include rem(height, 42px);
        @include rem(margin-bottom, 17px);
        @include rem(margin-right, 23px);
        @include rem(width, 42px);

        @include breakpoint(tablet) {
            @include rem(height, 35px);
            @include rem(margin-right, 0);
            @include rem(margin-bottom, 0);
            @include rem(width, 35px);
        }

        span {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: {
                width: 1px;
                style: solid;
                color: $white;
            }

            @each $name, $value in $colours {
                &.#{$name} {

                    @if ($name == "CC24" or $name == "TT24") {
                        border: {
                            width: 1px;
                            style: solid;
                            color: $light-grey;
                        }
                    }
                    background-image: url($value);
                    background-size: contain;
                }
            }
        }
    }

    p {
        position: absolute;
        top: 38px;
        white-space: nowrap;
        margin-bottom: 0;
    }
}


