

.article-grid {

	//Grid modifier
	.four--columns {
		@include breakpoint(tablet) {
        	@extend .unit-6-12--tablet;
        }
        @include breakpoint(desktop) {
        	@extend .unit-4-12--desktop;
        }
    }

    @extend .section;

	&__header {
		@extend .section__header;
	}


	&__filters {

		.filter {
			@extend .list-inline;
			@extend .list-reset;

			&__category, &__helper, &__chrono {
				float: none;
			}

			&__category {
				width:100%;
			}
			
			&__helper {
				text-align:left;
				float: left;
				width: 75px;
				padding-right: 0;
				display: block
			}

			&__chrono {
				display: block!important;
				@include rem(margin-left, 75px);
				width: auto;
				.fs-dropdown {
					z-index: 0;
				}
			}

			@include breakpoint(mobile-big){
				&__category, &__helper {
					float: left;
				}
				&__helper {
					//width:20%;
					padding-top: 1px;
				}
			}

			@include breakpoint(desktop){
				&__category, &__helper, &__chrono {
					float: left;
					display: inline-block;
				}
				&__category, &__chrono {
					width:25%;
				}
				&__helper {
					width: 75%;
					text-align:right;
					padding-right: 14px;
					padding-top: 1px;
				}
				&__chrono {
					margin-left: 0;
				}
			}
		}
		.fs-dropdown-selected {
			color:$black;
			border-bottom: 1px solid $orange;
			@include rem(margin, 1px 0 $medium-margin);
			&:before {
				color:$orange;
			}
		}
		.fs-dropdown-item{
			color:$orange;
		}
		.section__header {
			@include rem(margin, 0 0 $medium-margin);
		}
	}

	&__link {
		color:$black;
		&:hover  {
			color:$black;
		}

		&:hover .article-grid__title {
			color: $light-grey;
		}
	}
	&__item {
		@include rem(margin, 0 0 $medium-large-margin);
	}
	&__image{
		@extend .responsive-image;
	}
	&__title {
		@include rem(margin-bottom, $small-margin);
		color:$orange;

		@include breakpoint(desktop) {
			margin-bottom: 0;
		}
	}
	&__summary {
		display: none;
		@include breakpoint(tablet) {
			@include rem(margin, $small-margin 0);
			display: block;
		}
	}
}