//   1. General
//   2. Headings
//   3. Links
//   4. Actions + CTAs
//   5. Search
//   6. Lists
//   7. Horizontal rule
//   8. Images
//   9. Blockquotes
//  10. Tables
//  11. Forms


// 1. General
// ---------

html,
body,
button,
input,
select,
textarea {
  font-family: $font-family-default;
  line-height: $default-line-height;
}

p, ul, ol, dl {
  @include rem(margin, 0 0 $default-margin);
}

p {
  &.lead {
    @include fontSize(smallTitle);
  }

  line-height: 1.5;
}

// 2. Headings
// ---------

h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  font-weight: $font-weight-normal;
}

.big-title {
  @include fontSize(bigTitle);
}

.medium-title {
  @include fontSize(mediumTitle);
}

.small-title {
  @include fontSize(smallTitle);
}

.tiny-title {
  @include fontSize(tinyTitle);
}

h1 {
  @extend .big-title;
}

h2 {
  @extend .medium-title;
}

h3 {
  @extend .small-title;
  @include rem(margin, $medium-margin 0);
}

h4 {
  @extend .tiny-title;
  @include rem(margin, $medium-margin 0);
}

// 3. Links
// ---------

a {
  text-decoration: none;
  word-wrap: break-word;
  color: $color-link-default;

  &:visited {
    //color: $color-link-visited;
  }

  &:hover, &:active {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
    color: $color-link-hover;
  }

  &:active {
    color: $color-link-active;
  }

  a:focus {
  }
}

// 4. Actions + CTAs
// Go Back/ Forward/ Up
// ---------

a {
  &.go-back,
  &.go-forward,
  &.go-up {
    display: block;
    position: relative;
    @include rem(max-width, 150px);
    @include rem(padding, $large-padding 0 0);

    span {
      display: block;
      position: absolute;
      @include rem(top, -$tiny-margin);
      @include rem(font-size, 35px);
      @include breakpoint(tablet) {
        @include rem(top, -$small-margin);
      }
    }
  }

  &.go-back {
    span {
      right: 0;

      &:before {
        @extend .icon-link-left--default:before;
      }
    }

    &:hover {
      span {
        &:before {
          @extend .icon-link-left--hover:before;
        }
      }
    }
  }

  &.go-forward {
    text-align: left;

    span {
      left: 0;

      &:before {
        @extend .icon-link-right--default:before;
      }
    }

    &:hover {
      span {
        &:before {
          @extend .icon-link-right--hover:before;
        }
      }
    }
  }

  &.go-up {
    text-align: right;

    span {
      &:before {
        @extend .icon-link-up--default:before;
      }

      &.icon-link-up--reverse {
        &:before {
          @extend .icon-link-up--reverse:before;
        }
      }
    }

    &:hover {
      span {
        &:before {
          @extend .icon-link-up--hover:before;
        }

        &.icon-link-up--reverse {
          &:before {
            @extend .icon-link-up--hover-reverse:before;
          }
        }
      }
    }

  }
}

// 5. Search
// ---------

.search {
  span {
    &:before {
      @extend .icon-search--default:before;
      @include rem(font-size, 21px !important);
    }
  }

  &:hover {
    span {
      &:before {
        @extend .icon-search--hover:before;

      }
    }
  }
}

// 6. Lists
// ---------

ul, ol, dl {
}

ul ul, ol ol, dl dl {
}

ul li, ol li {
}

ul li {
}

ol li {
}

ul li ul {
}

dl dt {
}

dl dd {
}

// 7. Horizontal rule
hr, .horizontal-rule {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $light-grey;
  margin: $medium-margin 0;
  padding: 0;

  @include breakpoint(tablet) {
    margin: $large-margin 0;
  }

  &--white {
    border-color: $white;
  }

}

// 8. Images
// ---------

img {
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
  display: block;
}

/* Correct overflow displayed oddly in IE9 */
svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

// 9. Blockquotes
// ---------

q {
  quotes: none;

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q:before, q:after {
}

blockquote,
.blockquote {
  color: $blue;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  @include fontSize(smallTitle);
}

// 10. Tables
// ---------

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}


fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

// 11. Forms
// ---------

textarea {
  resize: vertical;
}
