@keyframes sonar-effect {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 0.5;
  }
  40% {
    transform: scale(1.5);
    opacity: 0;
  }
  41% {
    transform: scale(1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

[data-css="map-image"] {
  .map-image__image-container {
    position: relative;
    line-height: 0;
  }

  .map-image__points-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .map-image__point-wrapper {
    position: absolute;

    @for $i from 0 through 20 {
      &:nth-child(#{$i}n) {
        .map-image__point {
          &:after {
            animation-delay: #{$i * 1.65}s;
          }
        }
      }
    }

    &:hover {
    }
  }

  .map-image__point {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 48px;
      height: 48px;
      border-radius: 50%;
      //border: 1px solid #FFFFFF;
      z-index: 0;
      opacity: 0;
    }

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      top: 0;
      left: 0;
      pointer-events: auto;
      animation: sonar-effect 5s ease-in-out infinite normal;
      opacity: 0;
      box-shadow: 0 0 0 5px #fff, 0 0 0 10px #fff, 0 0 0 10px #fff;

    }

    &:hover {
      .point__touch {
        &:before {
          transform: translate(-50%, -50%) scale(1.3);
          opacity: 1;
        }
      }
    }
  }

  .point__touch {
    width: 34px;
    height: 34px;
    background-color: $orange;
    border: 4px solid #FFFFFF;
    border-radius: 50%;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 34px;
      height: 34px;
      border-radius: 50%;
      z-index: 0;
      transition: opacity 0s, transform 0.4s ease;
      border: 1px solid #FFFFFF;
    }


  }

  .map-image__popover {
    display: none;
    font-size: 1.6rem;
    font-weight: 400;
    position: absolute;
    line-height: initial;
    max-width: 380px;
    padding: 20px 24px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000000;
    text-align: center;
    transform: translate(calc(-50% + 17px), 48px);
    pointer-events: none;
    width: fit-content;
    z-index: 1;
    @media screen and (min-width: 600px) {
      font-size: 2rem;
    }

    &.has-arrow-top{
      &:before{
        content: "";
        display: block;
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 8px solid rgba(255,255,255,.8);
      }
    }

  }

  .map-image__image {
    width: 100%;
    height: auto;
  }

  .map-title {
    margin-bottom: 0;
    font-size: 24px;

    & + .map-content {
      margin-top: 16px;
      font-size: 18px;
    }
  }

  .map-content {
    margin-bottom: 0;
  }
}
