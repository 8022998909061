.pagination {
	text-align:center;
	border-top: 1px solid $light-grey;
	border-bottom: 1px solid $light-grey;
	@include rem(margin, $medium-margin 0);
	@include rem(padding, $medium-padding 0);

	&__next, &__prev, a {
		display: inline-block;
	}

	a {
		@include rem(margin, 0 $small-margin 0 0);

		&.current, &:hover {
			color: $light-grey;
		}
	}

	&__next {
		@include rem(margin-left, $small-margin);
	}

	&__prev {
		@include rem(margin-right, $small-margin);
	}

	&__see-more {
		&:before {
			@extend .icon-arrow-down--default;
			@include rem(font-size, 24px);
			@include rem(left, 135px);
			position: relative;
			@include rem(top, 4px);
		}
		&:hover {
			&:before {
				color: $light-grey;
			}
		}
	}

	&--lazy-load {
		border: none;
		@include rem(margin, 0 0 5px 0);
		padding: 0;

		@include breakpoint(desktop) {
			@include rem(margin, 0 0 15px 0);
		}
	}
}