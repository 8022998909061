
.utilities-navigation {
	@include fontSize(smallNav);
	@include rem(margin-left, $small-margin);

	> .utilities-navigation__list {
		float:right;
		&.navigation-list {
			overflow: visible;
		}

		> .utilities-navigation__item {
			> a {
    			&:hover, &.is-on {
    				color:$light-grey;
				}
			}
			.js-has-children {
				&:after {
					display:inline-block;
					vertical-align: middle;
					@include rem(font-size, 16px);
					@include rem(margin, 0 $tiny-margin 0 $medium-margin);
					@extend .icon-arrow-down--active:before:before;
				}
				&.is-on:after {
					transform: rotate(-180deg);
				}
			}
			.utilities-navigation__flag {
				display: none;

				@include breakpoint(desktop) {
					display: block;
					@include rem(min-width, 145px);
					text-align: left;
					position: relative;

					> img {
						@include rem(margin-left, 45px);
					}

					&:after {
						position: absolute;
						margin: 0;
						@include rem(top, 2px);
						@include rem(right, 30px);
					}
				}
				
				img {
					width: 25px;
					float: right;
				}
			}
			&--specifiers {
				visibility: hidden;
		
				@include breakpoint(desktop){
					visibility: visible;
				}
			}
		}
	}

	&__item {
		position: relative;

		&--mobile {
			display: none;

			@include breakpoint(desktop) {
				display: block;
			}
		}
		+ .utilities-navigation__item--mobile {
			border-right: none;

			@include breakpoint(desktop) {
				border-right: 1px solid $mid-grey;
			}
		}
	}

	&__link{
		&.cart {
			@include rem(margin-top, 2px);
			position: relative;

			span {
				display: inline-block;
				position: relative;
				top: -2px;
				vertical-align: top;
			}

			&:before {
				@extend .icon-basket--default:before;
				@include rem(margin-right, 9px);
				@include rem(font-size, 18px);
			}
			&:hover {
				
				&:before {
					@extend .icon-basket--hover:before;
					color:$light-grey;
				}
			}
		}
		&:hover {
			color:$light-grey
		}
	}

	&__submenu {
		display:none;
		width:100%;
		left:0;
		color:$off-black;
		background:$megamenu;
		z-index: 999;
		@include transition(all .3s ease-out);
		@include rem(top, 33px);

		@include breakpoint(desktop){
			position:absolute;
			z-index: 9999;
		}

		&__header {
			@include breakpoint(desktop){
				@include rem(font-size, 22px);
			}
		}

		> ul > li {
			@include breakpoint(desktop){
				border-left: 1px solid $mid-grey;
				@include rem(padding-left, $medium-padding);
				&:first-child {
					border-left:none;
					@include rem(padding-left, $gutter);
				}
			}
		}

		.utilities-navigation__list {
			float: left;
			@include rem(padding, $small-padding 0);
		}

		.utilities-navigation__item {
			width: 100%;
			border: none;
			text-align: left;
			margin-left:0;
			float: left;
			@include rem(margin-bottom, 3px);
			@include rem(padding, 0 13px);
			&:last-child{
				margin: 0;
			}
			.utilities-navigation__link {
				color: $off-black;
				display: block;
				//float: left;
				@include rem(padding-right, 30px);
				width: 100%;
				&:hover {
					color:$grey;
				}

				span {
					vertical-align: bottom;
				}
			}

			img {
				@include rem(margin, 5px 0 0 0!important);
			}
		}
	}

	float:right;
	margin-right: $small-margin;
	@include breakpoint(desktop){
		@include rem(margin-right, 0);
	}
	
	&__item {
		border-right: $light-grey $border-thin solid;
		@include rem(padding, 0 $medium-padding);
		&:last-child {
			position: relative;
			border-right:none;
		}
	}
}
