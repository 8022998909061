.site-header {

	&__top {
		border-bottom: solid $light-grey $border-thin;
		@include rem(padding, 9px 0);
		@include displayTable;
		@include clearfix();
		display:none;

		.site-header__selector {
			display: none;
			float: left;
			@include fontSize(smallNav);
			@include rem(left, 20px);
			position: relative;

			@media screen and (min-width: 960px) and (max-width: 1249px) {
				display: block;
			}
		}

		@include breakpoint(desktop){
			display:table;
			@include rem(padding-right, 10px);
		}
	}

	&__bottom {
		border-bottom: solid $light-grey $border-thin;

		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		background: white;
		z-index: 999;

		@include rem(height, 60px);

		@include breakpoint(tablet) {
			@include rem(height, 90px);
		}
		@include breakpoint(desktop) {
			position: relative;
			left: inherit;
			right: inherit;
			top: inherit;

			border-bottom: none;
			@include rem(height, 100px);
		}

		.grid {
			width: 100%;
		}
	}

	&__selector {
		display:none;
		position:absolute;
		margin:auto;
		top:0;
		bottom:0;
		z-index:index($elements, logo);
		@include rem(height, 24px);
		@include rem(left, 100px);

		@include breakpoint(desktop){
			@include rem(left, 150px);
		}

		@media screen and (min-width: 1250px) {
			display: block;
			@include fontSize(mediumNav);
		}
		
		@include breakpoint(desktop-big){
			@include rem(left, 150px);
		}

		&__item {
			float: left;
			border-right: $light-grey $border-thin solid;
			
			@include rem(padding, 0 $tiny-padding);
			
			@include breakpoint(desktop){
				@include rem(padding, 0 $small-padding);
			}
			@include breakpoint(desktop-big){
				@include rem(padding, 0 $medium-padding);
			}

			&:last-child {
				border-right:none;
				padding-right:0;
			}
		}
		&__link {
			color:$black;
			&.is-selected {
				color:$light-grey;
			}
		}
		&--mobile {
			@include rem(padding, $medium-padding $gutter);
			display: block;
			float: right;

			@include breakpoint(desktop) {
				display: none;
			}

			@media(max-width: 320px) {
				@include rem(padding, $medium-padding 0);
			}

			.site-header__selector__item {
				@include rem(margin, 0);
				@include rem(padding, 0 18px);

				@media(max-width: 320px) {
					@include rem(padding, 0 13px);
				}

				&:last-of-type {
					@include rem(padding-right, 0);

					@media(max-width: 320px) {
						@include rem(padding-right, 13px);
					}
				}
			}
		}
	}
	
	&__logo-container {
		position: absolute;
		margin:auto;
		bottom:0;
		@include rem(height, 25px);
		@include rem(width, 93px);
		@include rem(top, -22px);
		@include rem(left, $gutter);

		@media screen and (max-width: 360px) {
			@include rem(top, -16px);
			@include rem(width, 75px);
		}

		@include breakpoint(tablet){
			position:static;
			height:auto;
			@include rem(width, 113px);
			@include rem(margin, 18px 0 18px 30px);
		}
		
		@include breakpoint(desktop){
			@include rem(width, 113px);
		}
		@include breakpoint(desktop-big){
			@include rem(width, 123px);
		}
	}
	&__logo {
		img {
			position: relative;
			@include breakpoint(tablet){
				z-index:index($elements, logo);
			}
		}
	}
}
