.contact-module {
    .module__header,
    .module__content {
        text-align: center;
    }

    .module__image {
        @include rem (margin, $medium-margin 0);
        position: relative;
        height: 66px;
        img {
            margin: 0 auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .module__copy {
        @include rem(margin, 0 0 $small-margin);
        &__first {
            @include rem(margin, 25px 0 0);
        }
    }

    .module__standout {
        @include rem(margin, 0 0 $small-margin);
        @include fontSize(smallTitle);
        line-height:1.5;
    }

    a {
        &.module__standout {
            display: block;
        }
        &:hover {
            color:$light-grey;
        }
    }
}