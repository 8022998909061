/* ------ venobox.css --------*/
.vbox-overlay *, .vbox-overlay *:before, .vbox-overlay *:after {
  -webkit-backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

}

.vbox-overlay {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1040;
  -webkit-transform: translateZ(1000px);
  transform: translateZ(1000px);
  transform-style: preserve-3d;
}

/* ----- navigation ----- */
.vbox-title {
  width: 100%;
  height: 40px;
  float: left;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
  padding: 6px 40px;
  overflow: hidden;
  position: fixed;
  display: none;
  left: 0;
  z-index: 1050;
}

.vbox-close {
  cursor: pointer;
  position: fixed;
  top: -1px;
  right: 0;
  width: 50px;
  height: 40px;
  padding: 6px;
  display: block;
  background-position: 10px center;
  overflow: hidden;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  z-index: 1050;
}

.vbox-num {
  cursor: pointer;
  position: fixed;
  left: 0;
  height: 40px;
  display: block;
  overflow: hidden;
  line-height: 28px;
  font-size: 12px;
  padding: 6px 10px;
  display: none;
  z-index: 1050;
}

/* ----- navigation ARROWS ----- */
.vbox-next, .vbox-prev {
  position: fixed;
  top: 50%;
  margin-top: -15px;
  overflow: hidden;
  cursor: pointer;
  display: block;
  width: 54px;
  height: 54px;
  z-index: 1050;
}

.vbox-next span, .vbox-prev span {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-top-color: #B6B6B6;
  border-right-color: #B6B6B6;
  text-indent: -100px;
  position: absolute;
  top: 8px;
  display: block;
}

.vbox-prev {
  left: 15px;
}

.vbox-next {
  right: 15px;
}

.vbox-prev span {
  left: 10px;
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.vbox-next span {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 10px;
}

/* ------- inline window ------ */
.vbox-inline {
  width: 420px;
  height: 315px;
  height: 70vh;
  padding: 10px;
  background: #fff;
  margin: 0 auto;
  overflow: auto;
  text-align: left;
}

/* ------- Video & iFrames window ------ */
.venoframe {
  max-width: 100%;
  width: 100%;
  border: none;
  width: 100%;
  height: 260px;
  height: 70vh;
}

.venoframe.vbvid {
  height: 260px;
}

@media (min-width: 768px) {
  .venoframe, .vbox-inline {
    width: 90%;
    height: 360px;
    height: 70vh;
  }
  .venoframe.vbvid {
    width: 640px;
    height: 360px;
  }
}

@media (min-width: 992px) {
  .venoframe, .vbox-inline {
    max-width: 1200px;
    width: 80%;
    height: 540px;
    height: 70vh;
  }
  .venoframe.vbvid {
    width: 960px;
    height: 540px;
  }
}

/*
Please do NOT edit this part!
or at least read this note: http://i.imgur.com/7C0ws9e.gif
*/
.vbox-open {
  overflow: hidden;
}

.vbox-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  z-index: 20;
  max-height: 100%;


  //Hide Scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.vbox-content {
  text-align: center;
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px 10px;
}

.vbox-container img {
  max-width: 100%;
  height: auto;
}

.figlio {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  max-width: 100%;
  text-align: initial;
}

img.figlio {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding: 0!important;
  margin: 0 auto;
}

.vbox-content.swipe-left {
  margin-left: -200px !important;
}

.vbox-content.swipe-right {
  margin-left: 200px !important;
}

.animated {
  webkit-transition: margin 300ms ease-out;
  transition: margin 300ms ease-out;
}

.animate-in {
  opacity: 1;
}

.animate-out {
  opacity: 0;
}

/* ---------- preloader ----------
 * SPINKIT
 * http://tobiasahlin.com/spinkit/
-------------------------------- */
.sk-double-bounce, .sk-rotating-plane {
  width: 40px;
  height: 40px;
  margin: 40px auto
}

.sk-rotating-plane {
  background-color: #333;
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation: sk-rotatePlane 1.2s infinite ease-in-out;
  animation: sk-rotatePlane 1.2s infinite ease-in-out
}

@-webkit-keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0)
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0)
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
  }
}

@keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0)
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0)
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
  }
}

.sk-double-bounce {
  position: relative
}

.sk-double-bounce .sk-child {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
  animation: sk-doubleBounce 2s infinite ease-in-out
}

.sk-double-bounce .sk-double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s
}

@-webkit-keyframes sk-doubleBounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes sk-doubleBounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.sk-wave {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  margin: 40px auto
}

.sk-wave .sk-rect {
  background-color: #333;
  height: 100%;
  width: 4px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
  animation: sk-waveStretchDelay 1.2s infinite ease-in-out
}

.sk-wave .sk-rect1 {
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s
}

.sk-wave .sk-rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s
}

.sk-wave .sk-rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s
}

.sk-wave .sk-rect4 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s
}

.sk-wave .sk-rect5 {
  -webkit-animation-delay: -.8s;
  animation-delay: -.8s
}

@-webkit-keyframes sk-waveStretchDelay {
  0%, 100%, 40% {
    -webkit-transform: scaleY(.4);
    transform: scaleY(.4)
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1)
  }
}

@keyframes sk-waveStretchDelay {
  0%, 100%, 40% {
    -webkit-transform: scaleY(.4);
    transform: scaleY(.4)
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1)
  }
}

.sk-three-bounce {
  margin: 40px auto;
  width: 100px;
  text-align: center
}

.sk-three-bounce .sk-child {
  width: 16px;
  height: 16px;
  background-color: #333;
  border-radius: 100%;
  margin: 4px;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both
}

.sk-cube-grid, .sk-spinner-pulse {
  width: 40px;
  height: 40px;
  margin: 40px auto
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s
}

@-webkit-keyframes sk-three-bounce {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes sk-three-bounce {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.sk-spinner-pulse {
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-pulseScaleOut 1s infinite ease-in-out;
  animation: sk-pulseScaleOut 1s infinite ease-in-out
}

@-webkit-keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0
  }
}

@keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0
  }
}

.sk-cube-grid .sk-cube {
  width: 33.33%;
  height: 33.33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: .2s;
  animation-delay: .2s
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: .3s;
  animation-delay: .3s
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: .4s;
  animation-delay: .4s
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: .1s;
  animation-delay: .1s
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: .2s;
  animation-delay: .2s
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: .3s;
  animation-delay: .3s
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: .1s;
  animation-delay: .1s
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: .2s;
  animation-delay: .2s
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 100%, 70% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1)
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1)
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 100%, 70% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1)
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1)
  }
}

.sk-wandering-cubes {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative
}

.sk-wandering-cubes .sk-cube {
  background-color: #333;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
  animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both
}

.sk-wandering-cubes .sk-cube2 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s
}

@-webkit-keyframes sk-wanderingCube {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  25% {
    -webkit-transform: translateX(30px) rotate(-90deg) scale(.5);
    transform: translateX(30px) rotate(-90deg) scale(.5)
  }
  50% {
    -webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
    transform: translateX(30px) translateY(30px) rotate(-179deg)
  }
  50.1% {
    -webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
    transform: translateX(30px) translateY(30px) rotate(-180deg)
  }
  75% {
    -webkit-transform: translateX(0) translateY(30px) rotate(-270deg) scale(.5);
    transform: translateX(0) translateY(30px) rotate(-270deg) scale(.5)
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg)
  }
}

@keyframes sk-wanderingCube {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  25% {
    -webkit-transform: translateX(30px) rotate(-90deg) scale(.5);
    transform: translateX(30px) rotate(-90deg) scale(.5)
  }
  50% {
    -webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
    transform: translateX(30px) translateY(30px) rotate(-179deg)
  }
  50.1% {
    -webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
    transform: translateX(30px) translateY(30px) rotate(-180deg)
  }
  75% {
    -webkit-transform: translateX(0) translateY(30px) rotate(-270deg) scale(.5);
    transform: translateX(0) translateY(30px) rotate(-270deg) scale(.5)
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg)
  }
}
