.featured-articles-module {
    @include breakpoint(tablet) {
        &.section {
            margin-bottom: 3.5rem;
        }
    }

    @include breakpoint(desktop) {
        &.section {
            margin-bottom: 6rem;
        }
    }

    .module {
        &__header {
            position: relative;
        }

        &__link {
            position: absolute;
            right: 0;
            bottom: 0;

            &:after {
                @extend .icon-arrow-down--default:before;
                display: inline-block;
                transform: rotate(-90deg);
                vertical-align: middle;
                @include rem(margin-left, $small-margin);
            }

            &:hover,
            &:hover:after {
                color: $light-grey;
            }
        }

        &__grid {
            @include rem(padding, 0);

            @include breakpoint(desktop) {
                @include rem(padding, 0 $tiny-padding);
            }
        }

        &__image {
            max-height: 270px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;

                @include breakpoint(tablet) {
                    width: auto;
                }
            }
        }

        &__title {
            @include rem(margin-right, 100px);

            &__sub-heading {
                @include rem(margin, $medium-margin 0);
                color: $orange;

                &:hover {
                    color: $light-grey;
                }

                @include breakpoint(tablet) {
                    @include rem(margin, $medium-margin 0 $tiny-margin);
                }
            }

            &__sub-heading.black {
                color: $black;
            }
        }

        &__content {
            display: none;

            @include breakpoint(tablet) {
                display: block;
            }
        }
    }
}

.featured-articles-module.solutions {
    .module {
        &__content {
            display: block;
        }

        &__image {
            img {
                width: auto;
                margin: 0 auto;

                @include breakpoint(tablet) {
                    width: 100%;
                }
            }
        }
    }

    .button {
        width: 100%;
    }
}
